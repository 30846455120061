import axios, { AxiosResponse } from 'axios'
import { SET_ALERTS, SET_ALERT, SET_ERRORMSG } from '../mutation_types'
import {
    CLEAR_ALERTS_ISLOADING,
    DELETE_ALERT,
    EDIT_ALERT,
    GET_ALERTS,
    ADD_ALERT,
    GET_ALERT,
} from '../action_types'
import { StateObject } from '@/dtos/state.dto'
import Alert from '@/services/alert'
import { Module } from 'vuex'
import { EditAlert, GetAlert, AddAlert } from '@/dtos/alert.dto'

const alert: Module<any, {}> = {
    state: {
        alert: {
            data: null,
            isLoading: false
        },
        alerts: {
            data: null,
            isLoading: false
        }
    },
    getters: {
        alert: (state: { alert: any }) => state.alert,
        alerts: (state: { alerts: any }) => state.alerts
    },
    mutations: {
        [SET_ALERT]: (state: { alert: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.alert.data = payload.data : null, state.alert.isLoading = payload.isLoading },
        [SET_ALERTS]: (state: { alerts: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.alerts.data = payload.data : null, state.alerts.isLoading = payload.isLoading }
    },
    actions: {
        [ADD_ALERT]: async({ commit }: any, payload: any) => {
            commit(SET_ALERT, { data: null, isLoading: true })

            let result: AxiosResponse = await Alert.registerAlert(payload as AddAlert)
          
            if(result) {
                if (result.data.status) {
                    commit(SET_ALERT, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_ALERT, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_ALERT, { isLoading: false })
                return false;
            }
        },
        [GET_ALERT]: async({ commit }: any, payload: any) => {
            commit(SET_ALERT, { data: null, isLoading: true })

            let result: AxiosResponse = await Alert.getAlerts(payload as GetAlert)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_ALERT, { data: result.data.data[0], isLoading: false })
                    return true;
                }
                commit(SET_ALERT, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_ALERT, { isLoading: false })
                return false;
            }
        },
        [GET_ALERTS]: async({ commit }: any, payload: any) => {
            commit(SET_ALERTS, { data: null, isLoading: true })

            let result: AxiosResponse = await Alert.getAlerts(payload as GetAlert)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_ALERTS, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_ALERTS, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_ALERTS, { isLoading: false })
                return false;
            }
        },
        [EDIT_ALERT]: async({ commit }: any, payload: any) => {
            commit(SET_ALERT, { data: null, isLoading: true })

            let result: AxiosResponse = await Alert.editAlert(payload as EditAlert)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_ALERT, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_ALERT, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_ALERT, { isLoading: false })
                return false;
            }
        },
        [DELETE_ALERT]: async({ commit }: any, payload: string) => {
            commit(SET_ALERT, { data: null, isLoading: true })

            let result: AxiosResponse = await Alert.deleteAlert(payload)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_ALERT, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_ALERT, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_ALERT, { isLoading: false })
                return false;
            }
        },
        [CLEAR_ALERTS_ISLOADING]: ({ commit }) => {
            commit(SET_ALERT, { isLoading: false })
            commit(SET_ALERTS, { isLoading: false })
        }
    }
}

export default alert