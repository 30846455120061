<template>
    <div>
        <vue-final-modal :drag="false" drag-selector="" v-model="showModal" content-class="modal-content"
            classes="modal-container" focus-trap>
            <button class="modal__close btn btn-light" @click="$emit('closeBulkMembersSelectedModal')">
                <i class="pi pi-times"></i>
            </button>
            <span class="modal__title">Select Bulk Member</span>
            <br>
            <div class="modal__content">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <p v-if="errorMessage" class="text-center text-danger auth-status-msg">{{ errorMessage }}</p>
                                <table class="table">
                                    <tbody>
                                        <template v-bind:key="index" v-for="(member, index) in filteredMembers">
                                            <tr v-on:click="selectMember(member)"
                                                :class="[isOdd(index + 1) ? 'striped' : null, 'member-row']">
                                                <td>{{ member.fname }} {{ member.lname }}</td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                                <br>
                                <div class="row justify-content-end modal__actions">
                                    <button class="col-12 btn btn-save">Select</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </vue-final-modal>
    </div>
</template>
<script>
import { GET_FILTERED_MEMBERS } from '@/store/action_types'
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'

import { Field, Form, ErrorMessage } from 'vee-validate';
import { defineRule } from 'vee-validate';
import { required, email, min } from '@vee-validate/rules';

defineRule('required', required);
defineRule('email', email);
defineRule('min', min);

export default {
    props: {
        type: String,
        organizationId: String
    },
    components: {
        VueFinalModal,
        ModalsContainer,
        Form,
        Field,
        ErrorMessage
    },
    data() {
        return {
            showModal: true,
            selectedMember: null
        }
    },
    computed: {
        errorMessage() {
            return this.$store.getters.errorMsg
        },
        filteredMembers() {
            return this.$store.getters.filteredMembers.data
        },
        filteredMembersIsLoading() {
            return this.$store.getters.filteredMembers.isLoading
        }
    },
    methods: {
        isOdd(number) {
            return number % 2 !== 0;
        },
        closeModal() {
            this.$emit('closeBulkMembersSelectedModal')
        },
        selectMember(member) {
            this.selectedMember = member;
            this.$emit('selectMember', this.selectedMember)
            this.closeModal()
        },
        getMembers() {
            this.$store.dispatch(GET_FILTERED_MEMBERS, { type: this.type, org: this.organizationId })
        },
    },
    mounted() {
        this.getMembers()
    }
}
</script>
<style scoped>
::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    position: relative;
    left: 30%;
    margin-top: 60px;
}

::v-deep .modal-content {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.2rem;
    background: #fff;
}

.btn-save {
    background-color: #14243D;
}

.member-row:hover {
    cursor: pointer;
    color: #007bff;
}
</style>