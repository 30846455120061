<template>
    <div>
        <vue-final-modal 
            :drag="false"
            drag-selector=""
            v-model="showModal"
            content-class="modal-content"
            classes="modal-container"
            focus-trap>
            <button class="modal__close btn btn-light" @click="$emit('closeEditTemplateModal')">
                <i class="pi pi-times"></i>
            </button>
            <span class="modal__title">Add Template</span>
            <br>
            <div class="modal__content">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-12">
                          <p v-if="errorMessage" class="text-center text-danger auth-status-msg">{{ errorMessage }}</p>
                          <!-- <Form @submit="editTemplate"> -->
                              <div class="form-group">
                                  <label for="namesd">Name</label>
                                  <Field v-model="editTemplateObj.name" rules="required" type="text" name="namesd" class="form-control" id="namesd" placeholder="Name"/>
                                  <ErrorMessage name="namesd" class="form-text text-danger"/>
                              </div> 
                              <div class="row content-row">
                                  <div class="col-1">
                                      <label v-on:click="getIt()" for="title">Content</label>
                                  </div>
                                  <div class="col-2 offset-8">
                                      <div class="dropdown">
                                        <button class="btn btn-add button-insert-var dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                          Insert Variable
                                        </button>
                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <button class="dropdown-item" @click="insertVariable('member')">Insert 'member'</button>
                                            <button class="dropdown-item" @click="insertVariable('amount')">Insert 'amount'</button>
                                            <button class="dropdown-item" @click="insertVariable('purpose')">Insert 'purpose'</button>
                                            <button class="dropdown-item" @click="insertVariable('donationDate')">Insert 'donationDate'</button>
                                            <button class="dropdown-item" @click="insertVariable('donationDisplayStartDate')">Insert 'donationDisplayStartDate'</button>
                                            <button class="dropdown-item" @click="insertVariable('donationDisplayEndDate')">Insert 'donationDisplayEndDate'</button>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <QuillEditor ref="myQuillEditor" :options="editorOption" style="height: 280px;"></QuillEditor>
                              <hr>
                                <div class="row justify-content-end modal__actions">
                                  <button v-on:click="editTemplate" class="col-12 btn btn-save" :disabled="templateIsLoading ? true : false">
                                      <span v-if="templateIsLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                      <span v-if="!templateIsLoading">Save</span>
                                  </button>
                              </div>
                          <!-- </Form> -->
                      </div>
                    </div>
                  </div>
                </div>
            </div>
        </vue-final-modal>
    </div>
</template>
<script>
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import { ADD_TEMPLATE, EDIT_TEMPLATE, EDIT_USER, UPDATE_ACCOUNT } from '@/store/action_types'
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'

import { Field, Form, ErrorMessage } from 'vee-validate';
import { defineRule } from 'vee-validate';
import { required, email, min } from '@vee-validate/rules';
import { DONTATION_TYPE, EDIT_ACCOUNT_TYPE_SELF, USER_ROLES, USER_ROLE_USER } from '@/utils/const';

defineRule('required', required);
defineRule('email', email);
defineRule('min', min);

import Quill from 'quill';


export default {
    props: {
        selectedTemplate: Object
    },
    components: {
        VueFinalModal,
        ModalsContainer,
        Form,
        Field,
        ErrorMessage,
        QuillEditor
    },
    data () {
        return {
            DONTATION_TYPE,
            EDIT_ACCOUNT_TYPE_SELF,
            USER_ROLES,
            USER_ROLE_USER,
            showModal: true,
            editTemplateObj: {
                name: null,
                content: ''
            },
            editorOption: {
                modules: {
                    toolbar: [
                        [{ 'font': ['TzlilimPM', 'Pyutim'] }],
                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                        [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
                        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                        [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
                        [{ 'direction': 'rtl' }],                         // text direction
                        [{ 'align': [] }],

                        ['link', 'image', 'video', 'formula'],            // add's image support
                        ['clean']    
                    ]
                }
            }
        }
    },
    computed: {
        errorMessage() {
            return this.$store.getters.errorMsg
        },
        template() {
              return this.$store.getters.template.data
        },
        templateIsLoading() {
            return this.$store.getters.template.isLoading
        }
    },
    methods: {
      registerFonts() {
          const Font = Quill.import('formats/font');
          Font.whitelist = ['TzlilimPM', 'Pyutim']; // add custom fonts to the whitelist
          Quill.register(Font, true);
        },
        insertVariable(variable) {
          this.$nextTick(() => {
            if (this.$refs.myQuillEditor && this.$refs.myQuillEditor.getQuill) {
              const quill = this.$refs.myQuillEditor.getQuill();
              const range = quill.getSelection();
              if (range) {
                quill.insertText(range.index, `{${variable}}`);
              }
            } else {
              console.error("Quill editor is not yet initialized");
            }
          });
        },
        setValues() {
            this.editTemplateObj.id = this.selectedTemplate.id
            this.editTemplateObj.name = this.selectedTemplate.name
            this.editTemplateObj.content =this.$refs.myQuillEditor.setHTML(this.selectedTemplate.content)
        },
        // editTemplate() {
        //     this.$store.dispatch(EDIT_TEMPLATE, this.editTemplateObj).then((result) => {
        //         if(result) {
        //             this.$emit('closeEditTemplateModal')
        //             this.$emit('refreshTemplates')
        //         }
        //     })           
        // },
        editTemplate() {   
          this.editTemplateObj.content = this.$refs.myQuillEditor.getHTML()

          console.log(this.editTemplateObj)
          this.$store.dispatch(EDIT_TEMPLATE, this.editTemplateObj).then((result) => {
              if(result) {
                  this.$emit('closeEditTemplateModal')
                  this.$emit('refreshTemplates')
              }
          })           
        },
    },
    mounted() {
    this.setValues()
      this.$nextTick(() => {
        if (this.$refs.myQuillEditor && this.$refs.myQuillEditor.getQuill) {
          console.log("Quill instance:", this.$refs.myQuillEditor.getQuill());
        } else {
          console.error("Quill editor is not available.");
        }
      });
      this.registerFonts()
    }
}
</script>
<style scoped>
::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    position: relative;
    left: 30%;
    margin-top: 60px;
}

::v-deep .modal-content {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.2rem;
    background: #fff;
}

.btn-save {
  margin-top: 10px;
  background-color: #14243D;
}

.button-insert-var {
  font-size: 14px;
  background-color: #F9F9FC;
  color: #858D9D;
  margin-bottom: 10px;
  border: 1px solid #E0E2E7
}
</style>