<template>
    <div class="dashboard-body">
        <div class="row">
            <sidenav class="col-2"/>
            <div style="padding-left: 0px!important" class="col-10 content-section dashboard-content">
                <navbar/>
                <addUserModal v-if="showAddUserModal" @close-add-user-modal="showAddUserModal = false" @refresh-users="getUsers()"/>
                <editUserModal v-if="showEditUserModal" :selectedUser="selectedUser" @close-edit-user-modal="showEditUserModal = false" @refresh-users="getUsers()"/>
                <div class="content">
                    <br>
                    <br>
                    <div class="row">
                        <div class="col-lg-2 offset-lg-9">
                            <button v-on:click="showAddUserModal = true" class="btn btn-add">Add Account</button>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-lg-10 offset-lg-1">
                            <div class="card">
                                <div class="card-body">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">First Name</th>
                                                <th scope="col">Last Name</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Role</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Created By</th>
                                                <th scope="col">Created On</th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-bind:key="index" v-for="(user, index) in users">
                                                <tr :class="[ isOdd(index + 1) ? 'striped' : null ]">
                                                    <th scope="row">{{ index + 1 }}</th>
                                                    <td>{{ user.fname }}</td>
                                                    <td>{{ user.lname }}</td>
                                                    <td>{{ user.email }}</td>
                                                    <td>{{ user.role }}</td>
                                                    <td>
                                                        <label v-if="user.id != account.id" v-on:click="toggleUserIsActive(user.id)" class="form-switch">
                                                            <input v-if="!(actingUser != null&& actingUserIsLoading && actingUser.id == user.id)" type="checkbox" :checked="user.isActive">
                                                            <span v-else class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                            <i></i>
                                                        </label>
                                                        <h2 v-else class="text-secondary"> - </h2>
                                                    </td>
                                                    <!-- <td>{{ user.isActive ? 'active' : 'in-active' }}</td> -->
                                                    <td>{{ user.user.fname }}</td>
                                                    <td>{{ $filters.getDateOnly(user.createdAt) }}</td>
                                                    <td><i v-on:click="selectUser(user)" class="pi pi-pencil text-primary"></i></td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import sidenav from '@/components/Sidenav.vue'
import navbar from '@/components/Navbar.vue'
import addUserModal from '@/components/modals/User/Add_User_Modal.vue'
import editUserModal from '@/components/modals/User/Edit_User_Modal.vue'
import { GET_USERS, TOGGLE_USER_IS_ACTIVE } from '@/store/action_types'

export default {
    title: 'Accounts',
    components: {
        sidenav,
        navbar,
        addUserModal,
        editUserModal
    },
    data() {
        return {
            showAddUserModal: false,
            showEditUserModal: false,
            selectedUser: null
        }
    },
    computed: {
        actingUser() {
            return this.$store.getters.user.data
        },
        actingUserIsLoading() {
            return this.$store.getters.user.isLoading
        },
        users() {
            return this.$store.getters.users.data
        },
        usersIsLoading() {
            return this.$store.getters.users.isLoading
        },
        account() {
            return this.$store.getters.account.data
        }
    },
    methods: {
        isOdd(number) {
            return number % 2 !== 0;
        },
        selectUser(user) {
            this.showEditUserModal = true
            this.selectedUser = user
        },
        toggleUserIsActive(id) {
            this.$store.dispatch(TOGGLE_USER_IS_ACTIVE, { id } )
        },
        getUsers() {
            this.$store.dispatch(GET_USERS)
        }
    },
    mounted() {
        this.getUsers()
    }
}
</script>
<style scoped>
.content .card,
.content .card-body {
    padding: 0px;
}

.content .card table thead {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: #F4ECE2;
}

.content .card table thead th {
    color: #5A5A57;
}

.content .card table .striped {
    background-color: #FAF7F4!important;
}

.content .card table tbody tr:hover {
    background-color: #FAF7F4!important;
}

.content .card table .pi-pencil {
    cursor: pointer;
}

.form-switch {
    display: inline-block;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}
.form-switch i {
    position: relative;
    display: inline-block;
    margin-right: .5rem;
    width: 46px;
    height: 26px;
    background-color: #e6e6e6;
    border-radius: 23px;
    vertical-align: text-bottom;
    transition: all 0.3s linear;
}
.form-switch i::before {
    content: "";
    position: absolute;
    left: 0;
    width: 42px;
    height: 22px;
    background-color: #fff;
    border-radius: 11px;
    transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
    transition: all 0.25s linear;
}
.form-switch i::after {
    content: "";
    position: absolute;
    left: 0;
    width: 22px;
    height: 22px;
    background-color: #fff;
    border-radius: 11px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
    transform: translate3d(2px, 2px, 0);
    transition: all 0.2s ease-in-out;
}
.form-switch:active i::after {
    width: 28px;
    transform: translate3d(2px, 2px, 0);
}
.form-switch:active input:checked + i::after { transform: translate3d(16px, 2px, 0); }
.form-switch input { display: none; }
.form-switch input:checked + i { background-color: #2ecc71; }
.form-switch input:checked + i::before { transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0); }
.form-switch input:checked + i::after { transform: translate3d(22px, 2px, 0); }
</style>