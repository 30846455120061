import axios, { AxiosResponse } from 'axios'
import { SET_ORGANIZATIONS, SET_ORGANIZATION, SET_ERRORMSG } from '../mutation_types'
import {
    CLEAR_ORGANIZATION_ISLOADING,
    DELETE_ORGANIZATION,
    EDIT_ORGANIZATION,
    GET_ORGANIZATIONS,
    REGISTER_ORGANIZATION,
    GET_ORGANIZATION,
    TOGGLE_ORGANIZATION_IS_ACTIVE,
    UPLOAD_HEADER_IMAGE,
} from '../action_types'
import { StateObject } from '@/dtos/state.dto'
import Organization from '@/services/organization'
import { Module } from 'vuex'
import { EditOrganization, GetOrganization, RegisterOrganization } from '@/dtos/organization.dto'

const organization: Module<any, {}> = {
    state: {
        organization: {
            data: null,
            isLoading: false
        },
        organizations: {
            data: null,
            isLoading: false
        }
    },
    getters: {
        organization: (state: { organization: any }) => state.organization,
        organizations: (state: { organizations: any }) => state.organizations
    },
    mutations: {
        [SET_ORGANIZATION]: (state: { organization: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.organization.data = payload.data : null, state.organization.isLoading = payload.isLoading },
        [SET_ORGANIZATIONS]: (state: { organizations: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.organizations.data = payload.data : null, state.organizations.isLoading = payload.isLoading }
    },
    actions: {
        [REGISTER_ORGANIZATION]: async({ commit }: any, payload: FormData) => {
            commit(SET_ORGANIZATION, { data: null, isLoading: true })

            let result: AxiosResponse = await Organization.registerOrganization(payload)
          
            if(result) {
                if (result.data.status) {
                    commit(SET_ORGANIZATION, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_ORGANIZATION, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_ORGANIZATION, { isLoading: false })
                return false;
            }
        },
        [GET_ORGANIZATIONS]: async({ commit }: any, payload: any) => {
            commit(SET_ORGANIZATIONS, { data: null, isLoading: true })

            let result: AxiosResponse = await Organization.getOrganizations(null)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_ORGANIZATIONS, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_ORGANIZATIONS, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_ORGANIZATIONS, { isLoading: false })
                return false;
            }
        },
        [GET_ORGANIZATION]: async({ commit }: any, payload: any) => {
            commit(SET_ORGANIZATION, { data: null, isLoading: true })

            let result: AxiosResponse = await Organization.getOrganizations(payload as GetOrganization)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_ORGANIZATION, { data: result.data.data[0], isLoading: false })
                    return true;
                }
                commit(SET_ORGANIZATION, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_ORGANIZATION, { isLoading: false })
                return false;
            }
        },
        [EDIT_ORGANIZATION]: async({ commit }: any, payload: FormData) => {
            commit(SET_ORGANIZATION, { data: null, isLoading: true })

            let result: AxiosResponse = await Organization.editOrganization(payload)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_ORGANIZATION, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_ORGANIZATION, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_ORGANIZATION, { isLoading: false })
                return false;
            }
        },
        [UPLOAD_HEADER_IMAGE]: async({ commit }: any, payload: FormData) => {
            commit(SET_ORGANIZATION, { data: null, isLoading: true })

            let result: AxiosResponse = await Organization.uploadHeaderImage(payload)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_ORGANIZATION, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_ORGANIZATION, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_ORGANIZATION, { isLoading: false })
                return false;
            }
        },
        [TOGGLE_ORGANIZATION_IS_ACTIVE]: async({ commit, state }: any, payload: EditOrganization) => {
            commit(SET_ORGANIZATION, { data: { id: payload.id }, isLoading: true })

            let result: AxiosResponse = await Organization.toggleOrganizationIsActive(payload as EditOrganization)
          
            if(result) {
                if (result.data.status) {
                    let index
                    state.organizations.data.forEach((e: any, i: number) => {
                        if (e._id == result.data.data._id) {
                            index = i
                        }
                    })
                    if(index != null) state.organizations.data[index].isActive = result.data.data.isActive

                    commit(SET_ORGANIZATION, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_ORGANIZATION, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_ORGANIZATION, { isLoading: false })
                return false;
            }
        },
        [DELETE_ORGANIZATION]: async({ commit }: any, payload: string) => {
            commit(SET_ORGANIZATION, { data: null, isLoading: true })

            let result: AxiosResponse = await Organization.deleteOrganization(payload)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_ORGANIZATION, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_ORGANIZATION, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_ORGANIZATION, { isLoading: false })
                return false;
            }
        },
        [CLEAR_ORGANIZATION_ISLOADING]: ({ commit }) => {
            commit(SET_ORGANIZATION, { isLoading: false })
            commit(SET_ORGANIZATIONS, { isLoading: false })
        }
    }
}

export default organization