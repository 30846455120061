<template>
    <div>
        <vue-final-modal :drag="false" drag-selector="" v-model="showModal" content-class="modal-content"
            classes="modal-container" focus-trap>
            <button class="modal__close btn btn-light" @click="$emit('closeShowMemberDetailsModal')">
                <i class="pi pi-times"></i>
            </button>
            <span class="modal__title">Member Details</span>
            <br>
            <div class="modal__content">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <div class="row justify-content-between">
                                    <div>
                                        <h6 class="title"><img src="../../../assets/icons/person-icon.svg" alt="">Name</h6>
                                    </div>
                                    <div class="label">
                                        <h6 class="value">{{ selectedMember.title }} {{ selectedMember.fname }} {{ selectedMember.lname }}</h6>
                                    </div>
                                </div>
                                <div class="row justify-content-between">
                                    <div>
                                        <h6 class="title"><img src="../../../assets/icons/email-icon.svg" alt="">Email</h6>
                                    </div>
                                    <div class="label">
                                        <h6 class="value">{{ selectedMember.email }}</h6>
                                    </div>
                                </div>
                                <div class="row justify-content-between">
                                    <div>
                                        <h6 class="title"><img src="../../../assets/icons/phone-icon.svg" alt="">Phone</h6>
                                    </div>
                                    <div class="label">
                                        <h6 class="value">{{ selectedMember.phoneNumber }}</h6>
                                    </div>
                                </div>
                                <div class="row justify-content-between">
                                    <div>
                                        <h6 class="title"><img src="../../../assets/icons/location-pin-icon.svg" alt="">Address</h6>
                                    </div>
                                    <div class="label">
                                        <h6 class="value">{{ `${selectedMember.address ?? ''}, ${selectedMember.city ?? ''} ${selectedMember.state ?? ''} ${selectedMember.postCode ?? ''}` }}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </vue-final-modal>
    </div>
</template>
<script>
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'

import { Field, Form, ErrorMessage } from 'vee-validate';
import { defineRule } from 'vee-validate';
import { required, email, min } from '@vee-validate/rules';

defineRule('required', required);
defineRule('email', email);
defineRule('min', min);

export default {
    props: {
        selectedMember: Object
    },
    components: {
        VueFinalModal,
        ModalsContainer,
        Form,
        Field,
        ErrorMessage
    },
    data() {
        return {
            showModal: true
        }
    },
    computed: {
        errorMessage() {
            return this.$store.getters.errorMsg
        },
        room() {
            return this.$store.getters.room.data
        },
        roomIsLoading() {
            return this.$store.getters.room.isLoading
        }
    }
}
</script>
<style scoped>
::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    position: relative;
    left: 30%;
    margin-top: 60px;
}

::v-deep .modal-content {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.2rem;
    background: #fff;
}

.modal__content .title img {
    margin-right: 10px;
    height: 30px;
}

.modal__content .card {
    padding-left: 10px;
    padding-right: 13px;
}

.btn-save {
    background-color: #14243D;
}
</style>