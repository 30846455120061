<template>
    <div>
        <vue-final-modal :drag="false" drag-selector="" v-model="showModal" content-class="modal-content"
            classes="modal-container" focus-trap>
            <button class="modal__close btn btn-light" @click="closeModal()">
                <i class="pi pi-times"></i>
            </button>
            <span class="modal__title">View Ticket</span>
            <br>
            <div class="modal__content">
                <closeTicketRemarkModal v-if="showCloseTicketRemarkModal" :selectedTicketId="selectedTicketToView.id"
                    @close-close-ticket-remark-modal="showCloseTicketRemarkModal = false"
                    @refresh-ticket-comments="getTicketComments(); closeTicketStateUpdate()" />
                <reOpenTicketRemarkModal v-if="showReOpenTicketRemarkModal" :selectedTicketId="selectedTicketToView.id"
                    @close-reopen-ticket-remark-modal="showReOpenTicketRemarkModal = false"
                    @refresh-ticket-comments="getTicketComments(); reOpenTicketStateUpdate()" />
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">
                                <a v-if="selectedTicketToView.status == TICKET_STATUS_OPEN"
                                    v-on:click="showCloseTicketRemarkModal = true"
                                    class="btn btn-danger btn-close-ticket">
                                    Close
                                </a>
                                <a v-else v-on:click="showReOpenTicketRemarkModal = true"
                                    class="btn btn-success btn-close-ticket">
                                    Re-Open
                                </a>
                                <!-- <a v-if="selectedTicketToView.status == TICKET_STATUS_OPEN" v-on:click="closeTicket()"
                                    class="btn btn-danger btn-close-ticket">
                                    <span v-if="ticketIsLoading" class="spinner-border spinner-border-sm" role="status"
                                        aria-hidden="true"></span>
                                    <span v-if="!ticketIsLoading">Close</span>
                                </a> -->
                                <!-- <span v-else class="badge badge-danger">Closed</span> -->
                                <br>
                                <font-awesome-icon icon="fa-solid fa-user" class="fa" /> {{
                                selectedTicketToView.user.fname }}
                                <p class="subject"><strong>Subject: </strong>{{ selectedTicketToView.subject }}</p>
                                <p class="detail"><strong>Details: </strong>{{ selectedTicketToView.details }}</p>
                                <hr>
                                <div v-if="ticketCommentsIsLoading && ticketComments.length == 0"
                                    class="comment-loading-container">
                                    <span class="spinner-border spinner-border-sm comment-loading-spinner" role="status"
                                        aria-hidden="true"></span>
                                    <h6 class="text">Loading Comments...</h6>
                                    <br>
                                    <br>
                                </div>
                                <div v-else>
                                    <template v-bind:key="index" v-for="(comment, index) in ticketComments">
                                        <div :class="['row comment-card']">
                                            <div
                                                :class="['col', comment.user.role == USER_ROLE_USER ? 'align-left' : 'align-right']">
                                                <p v-if="comment.user.role == USER_ROLE_USER" class="user">
                                                    <font-awesome-icon icon="fa-solid fa-user" class="fa" />
                                                    {{ comment.user.fname }}
                                                </p>
                                                <p v-else class="user">
                                                    <font-awesome-icon icon="fa-solid fa-building" class="fa" />
                                                    <span>PERSIM SYSTEMS</span>
                                                </p>
                                                <p class="message">
                                                    <span v-if="comment.type == TICKET_COMMENT_TYPES[0]"
                                                        class="badge badge-inactive text-center">Ticket Closed</span>
                                                    <span v-if="comment.type == TICKET_COMMENT_TYPES[1]"
                                                        class="badge badge-active text-center">Ticket Re-Opened</span>
                                                </p>
                                                <span>{{ comment.comment }}</span>
                                                <p class="text-secondary date-time">{{
                                                    $filters.getDateAndTime(comment.createdAt) }}</p>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                                <div v-if="showCommentTextArea == true">
                                    <Form @submit="addComment">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="comment">Comment</label>
                                                    <Field v-slot="{ field, errors }"
                                                        v-model="newTicketCommentObj.comment" rules="required"
                                                        name="details" class="form-control">
                                                        <textarea v-bind="field" name="comment" class="form-control"
                                                            rows="10" placeholder="Enter your comment...." />
                                                        <ErrorMessage name="comment" class="form-text text-danger" />
                                                    </Field>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <button type="submit" class="col-2 btn btn-save">
                                                <span v-if="ticketCommentIsLoading"
                                                    class="spinner-border spinner-border-sm" role="status"
                                                    aria-hidden="true"></span>
                                                <span v-if="!ticketCommentIsLoading">Submit</span>
                                            </button>
                                            <a v-on:click="showCommentTextArea = false"
                                                class="col-2 btn btn-add-comment btn-cancel">Cancel</a>
                                        </div>
                                    </Form>
                                </div>
                                <hr v-if="ticketComments != null && ticketComments.length > 0">
                                <a v-if="showCommentTextArea == false" v-on:click="showCommentTextArea = true"
                                    class="btn btn-add-comment">Add Comment</a>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
            </div>
        </vue-final-modal>
    </div>
</template>
<script>
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'

import { Field, Form, ErrorMessage } from 'vee-validate';
import { defineRule } from 'vee-validate';
import { required, email, min } from '@vee-validate/rules';
import { ADD_TICKET_COMMENT, CLEAR_TICKET_COMMENTS, CLOSE_TICKET, GET_TICKET_COMMENTS } from '@/store/action_types';
import { TICKET_STATUS_CLOSED, TICKET_STATUS_OPEN, USER_ROLE_USER, USER_ROLE_ADMIN, TICKET_COMMENT_TYPES } from '@/utils/const';

import closeTicketRemarkModal from '@/components/modals/Ticket/Close_Ticket_Remark_Modal.vue'
import reOpenTicketRemarkModal from '@/components/modals/Ticket/Reopen_Ticket_Remark_Model.vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faUser, faBuilding } from '@fortawesome/free-solid-svg-icons'
import ticketComment from '@/store/modules/ticket_comment';
library.add(faUser, faBuilding)

defineRule('required', required);
defineRule('email', email);
defineRule('min', min);

export default {
    props: {
        selectedTicketToView: Object,
    },
    components: {
        closeTicketRemarkModal,
        reOpenTicketRemarkModal,
        VueFinalModal,
        ModalsContainer,
        Form,
        Field,
        ErrorMessage
    },
    data() {
        return {
            TICKET_STATUS_OPEN,
            TICKET_STATUS_CLOSED,
            TICKET_COMMENT_TYPES,
            USER_ROLE_USER,
            USER_ROLE_ADMIN,
            showModal: true,
            showCommentTextArea: false,
            showCloseTicketRemarkModal: false,
            showReOpenTicketRemarkModal: false,
            newTicketCommentObj: {
                comment: null,
                type: null,
                tikt: null
            },
            closeTicketCommentObj: {
                comment: null,
                type: null,
                tikt: null
            },
            reopenTicketCommentObj: {
                comment: null,
                type: null,
                tikt: null
            }
        }
    },
    watch: {
        'editDonationObj.type': function (newValue, oldValue) {
            this.updateDonationDisplayEndDate();
        },
        'editDonationObj.donationDisplayStartDate': function (newValue, oldValue) {
            this.updateDonationDisplayEndDate();
        }
    },
    computed: {
        errorMessage() {
            return this.$store.getters.errorMsg
        },
        ticket() {
            return this.$store.getters.ticket.data
        },
        ticketIsLoading() {
            return this.$store.getters.ticket.isLoading
        },
        ticketComment() {
            return this.$store.getters.ticketComment.data
        },
        ticketCommentIsLoading() {
            return this.$store.getters.ticketComment.isLoading
        },
        ticketComments() {
            return this.$store.getters.ticketComments.data
        },
        ticketCommentsIsLoading() {
            return this.$store.getters.ticketComments.isLoading
        },
        donation() {
            return this.$store.getters.donation.data
        },
        donationIsLoading() {
            return this.$store.getters.donation.isLoading
        }
    },
    methods: {
        setValues() {
            this.newTicketCommentObj.tikt = this.selectedTicketToView.id
        },
        closeModal() {
            this.$emit('closeViewTicketModal')
            this.clearTicketComments()
        },
        clearTicketComments() {
            this.$store.dispatch(CLEAR_TICKET_COMMENTS)
        },
        closeTicketStateUpdate() {
            this.selectedTicketToView.status = 'closed'
        },
        reOpenTicketStateUpdate() {
            this.selectedTicketToView.status = 'open'
        },
        getTicketComments() {
            this.$store.dispatch(GET_TICKET_COMMENTS, { tikt: this.selectedTicketToView.id })
        },
        closeTicket() {
            this.$store.dispatch(CLOSE_TICKET, { id: this.selectedTicketToView.id }).then((res) => {
                if (res) {
                    this.$emit('closeViewTicketModal')
                    this.$emit('refreshTickets')
                }
            })
        },
        addComment() {
            this.newTicketCommentObj.type = TICKET_COMMENT_TYPES[2]
            this.$store.dispatch(ADD_TICKET_COMMENT, this.newTicketCommentObj).then((res) => {
                if (res) {
                    this.showCommentTextArea = false
                    this.newTicketCommentObj.comment = null
                    this.getTicketComments()
                }
            })
        }
    },
    created() {
        this.setValues()
        this.getTicketComments()
    }
}
</script>
<style scoped>
::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    position: relative;
    left: 25%;
    margin-top: 50px;
}

::v-deep .modal-content {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.2rem;
    background: #fff;
}

.card .card-body .fa {
    font-size: 14px;
    margin-right: 8px;
}

.btn-close-ticket {
    font-size: 13px;
    border-radius: 8px;
    float: right;
    margin-bottom: 0px;
    color: #FFFFFF;
    padding: 5px 7px 5px 7px;
}

.btn-close-ticket:hover {
    color: #EEE7E7;
}

.subject, 
.detail {
    margin-bottom: 2px;
    font-size: 14px;
}

.badge-danger {
    float: right;
}

.btn-add-comment {
    border-radius: 8px;
    font-size: 13px;
    color: #14243D !important;
    background-color: transparent;
    border: 1px solid #14243D;
}

.btn-save {
    color: #FFFFFF;
    background-color: #14243D;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 15px;
}

.btn-cancel {
    margin-bottom: 15px;
}

.comment-card .user {
    font-size: 15px;
    margin-bottom: 3px;
    color: #7999CC;
}

.comment-card .user .fa {
    font-size: 14px;
    margin-right: 8px;
}

.comment-card .message {
    font-size: 14px;
    margin-bottom: 5px;
}

.comment-card .date-time {
    font-size: 12px;
}

.comment-card .badge {
}

.right-align-card {
    float: right;
}

.align-left {
    text-align: left;
}

.align-right {
    text-align: right;
}

.comment-loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-bottom: 15px;
}

.comment-loading-container .text {
    margin-top: 8px;
    margin-left: 10px;
    font-size: 14px;
    font-weight: 600;
    color: #9DB4D9;
}

.comment-loading-spinner {
    height: 25px;
    width: 25px;
    font-size: 16px;
    color: #9DB4D9;
}
</style>