<template>
    <div>
        <vue-final-modal :drag="false" drag-selector="" v-model="showModal" content-class="modal-content"
            classes="modal-container" focus-trap>
            <button class="modal__close btn btn-light" @click="$emit('closeAddSeatPriceModal')">
                <i class="pi pi-times"></i>
            </button>
            <span class="modal__title">Seat Prices</span>
            <br>
            <div class="modal__content">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <p v-if="errorMessage" class="text-center text-danger auth-status-msg">{{ errorMessage
                                    }}</p>
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-6">
                                                <h6>Seat Prices</h6>
                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">#</th>
                                                            <th scope="col">Price</th>
                                                            <th scope="col">Color</th>
                                                            <th scope="col">Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <template v-bind:key="index"
                                                            v-for="(priceCategory, index) in synagogueSeatPriceCategories">
                                                            <tr :class="[isOdd(index + 1) ? 'striped' : null]">
                                                                <th scope="row">{{ index + 1 }}</th>
                                                                <td>{{ priceCategory.price }}</td>
                                                                <td>
                                                                    <div class="col-1">
                                                                        <div class="circle">
                                                                            <div class="circle-child"
                                                                                :style="`background-color:${priceCategory.colorHex}`">

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <i v-on:click="toggleEditSeatPriceCategory(priceCategory)"
                                                                        class="pi pi-pencil text-primary"></i>
                                                                    <i v-on:click="toggleDeleteSeatPriceCategory(priceCategory)"
                                                                        class="pi pi-trash text-danger"></i>
                                                                </td>
                                                            </tr>
                                                        </template>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="col-6">
                                                <div v-if="currTab == SYNAGOGUE_SEAT_PRICE_CATEGORY_ADD_NEW_TAB">
                                                    <h6>Add New Seat Price Category</h6>
                                                    <Form @submit="addSynagogeSeatPriceCategory">
                                                        <div class="form-group">
                                                            <label for="name">Price</label>
                                                            <Field v-model="newSynagogueSeatPriceCategoryObj.price"
                                                                rules="required" type="number" name="price"
                                                                class="form-control" id="price" placeholder="0.00" />
                                                            <ErrorMessage name="price" class="form-text text-danger" />
                                                        </div>
                                                        <div class="form-group">
                                                            <label for="name">Color</label>
                                                            <div class="row">
                                                                <template v-bind:key="index"
                                                                    v-for="(color, index) in SEAT_COLOR_OPTION_HEX_VALUES">
                                                                    <div class="col-1">
                                                                        <div v-on:click="selectColor(color)"
                                                                            :class="[newSynagogueSeatPriceCategoryObj.colorHex == color ? 'selected-circle' : null, 'circle']">
                                                                            <div class="circle-child"
                                                                                :style="`background-color:${color}`">

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </template>
                                                            </div>
                                                        </div>
                                                        <br>
                                                        <div class="row justify-content-end modal__actions">
                                                            <button class="col-12 btn btn-save"
                                                                :disabled="synagogueSeatPriceCategoryIsLoading ? true : false">
                                                                <span v-if="synagogueSeatPriceCategoryIsLoading"
                                                                    class="spinner-border spinner-border-sm"
                                                                    role="status" aria-hidden="true"></span>
                                                                <span v-if="!synagogueSeatPriceCategoryIsLoading">
                                                                    Save</span>
                                                            </button>
                                                        </div>
                                                    </Form>
                                                </div>
                                                <div v-if="currTab == SYNAGOGUE_SEAT_PRICE_CATEGORY_EDIT_TAB">
                                                    <h6>Edit Seat Price Category</h6>
                                                    <Form @submit="addSynagogeSeatPriceCategory">
                                                        <div class="form-group">
                                                            <label for="name">Price</label>
                                                            <Field v-model="editSynagogueSeatPriceCategoryObj.price"
                                                                rules="required" type="number" name="price"
                                                                class="form-control" id="price" placeholder="0.00" />
                                                            <ErrorMessage name="price" class="form-text text-danger" />
                                                        </div>
                                                        <div class="form-group">
                                                            <label for="name">Color</label>
                                                            <div class="row">
                                                                <template v-bind:key="index"
                                                                    v-for="(color, index) in SEAT_COLOR_OPTION_HEX_VALUES">
                                                                    <div class="col-1">
                                                                        <div v-on:click="selectColor(color)"
                                                                            :class="[editSynagogueSeatPriceCategoryObj.colorHex == color ? 'selected-circle' : null, 'circle']">
                                                                            <div class="circle-child"
                                                                                :style="`background-color:${color}`">

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </template>
                                                            </div>
                                                        </div>
                                                        <br>
                                                        <div class="row justify-content-between modal__actions">
                                                            <button class="col-6 btn btn-save"
                                                                :disabled="synagogueSeatPriceCategoryIsLoading ? true : false">
                                                                <span v-if="synagogueSeatPriceCategoryIsLoading"
                                                                    class="spinner-border spinner-border-sm"
                                                                    role="status" aria-hidden="true"></span>
                                                                <span
                                                                    v-if="!synagogueSeatPriceCategoryIsLoading">Save</span>
                                                            </button>
                                                            <button
                                                                v-on:click="currTab = SYNAGOGUE_SEAT_PRICE_CATEGORY_ADD_NEW_TAB"
                                                                class="col-6 btn">
                                                                Cancel
                                                            </button>
                                                        </div>
                                                    </Form>
                                                </div>
                                                <div v-if="currTab == SYNAGOGUE_SEAT_PRICE_CATEGORY_DELETE_TAB">
                                                    <h6>Delete Seat Price Category</h6>
                                                    <br>
                                                    <div class="row">
                                                        <div class="col-8 offset-2">
                                                            <p class="delete-message">Are you sure you want to delete this price category?</p>
                                                        </div>
                                                    </div>
                                                    <br>
                                                    <div class="row">
                                                        <div class="col-lg-2 offset-lg-3">
                                                            <button v-on:click="deleteSynagogeSeatPriceCategory()"
                                                                class="btn text-danger"
                                                                :disabled="synagogueSeatPriceCategoryIsLoading ? true : false">
                                                                <span v-if="synagogueSeatPriceCategoryIsLoading"
                                                                    class="spinner-border spinner-border-sm"
                                                                    role="status" aria-hidden="true"></span>
                                                                <span
                                                                    v-if="!synagogueSeatPriceCategoryIsLoading">Confirm</span>
                                                            </button>
                                                        </div>
                                                        <div class="col-lg-2 offset-lg-1">
                                                            <button
                                                                v-on:click="currTab = SYNAGOGUE_SEAT_PRICE_CATEGORY_ADD_NEW_TAB"
                                                                class="btn">
                                                                Cancel
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </vue-final-modal>
    </div>
</template>
<script>
import Quill from 'quill';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import { ADD_SYNAGOGUE_SEAT_PRICE_CATEGORY, DELETE_SYNAGOGUE_SEAT_PRICE_CATEGORY, EDIT_SYNAGOGUE_SEAT_PRICE_CATEGORY, REGISTER_ROOM } from '@/store/action_types'
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'

import { Field, Form, ErrorMessage } from 'vee-validate';
import { defineRule } from 'vee-validate';
import { required, email, min } from '@vee-validate/rules';

import { GET_SYNAGOGUE_SEAT_PRICE_CATEGORIES } from '@/store/action_types';
import { SEAT_COLOR_OPTION_HEX_VALUES, SYNAGOGUE_SEAT_PRICE_CATEGORY_ADD_NEW_TAB, SYNAGOGUE_SEAT_PRICE_CATEGORY_DELETE_TAB, SYNAGOGUE_SEAT_PRICE_CATEGORY_EDIT_TAB } from '@/utils/const';

defineRule('required', required);
defineRule('email', email);
defineRule('min', min);

export default {
    props: {
        organizationId: String
    },
    components: {
        VueFinalModal,
        ModalsContainer,
        Form,
        Field,
        ErrorMessage,
        QuillEditor
    },
    data() {
        return {
            SEAT_COLOR_OPTION_HEX_VALUES,
            SYNAGOGUE_SEAT_PRICE_CATEGORY_ADD_NEW_TAB,
            SYNAGOGUE_SEAT_PRICE_CATEGORY_EDIT_TAB,
            SYNAGOGUE_SEAT_PRICE_CATEGORY_DELETE_TAB,
            showModal: true,
            currTab: SYNAGOGUE_SEAT_PRICE_CATEGORY_ADD_NEW_TAB,
            newSynagogueSeatPriceCategoryObj: {
                price: null,
                colorHex: null,
                org: null
            },
            editSynagogueSeatPriceCategoryObj: {
                id: null,
                price: null,
                colorHex: null,
                org: null
            },
            selectedSeatPriceCategoryId: null
        }
    },
    computed: {
        errorMessage() {
            return this.$store.getters.errorMsg
        },
        synagogueSeatPriceCategory() {
            return this.$store.getters.synagogueSeatPriceCategory.data
        },
        synagogueSeatPriceCategoryIsLoading() {
            return this.$store.getters.synagogueSeatPriceCategory.isLoading
        },
        synagogueSeatPriceCategories() {
            return this.$store.getters.synagogueSeatPriceCategories.data
        },
        synagogueSeatPriceCategoriesIsLoading() {
            return this.$store.getters.synagogueSeatPriceCategories.isLoading
        }
    },
    methods: {
        isOdd(number) {
            return number % 2 !== 0;
        },
        setValues() {
            this.newSynagogueSeatPriceCategoryObj.org = this.organizationId
        },
        toggleEditSeatPriceCategory(priceCategory) {
            this.editSynagogueSeatPriceCategoryObj.id = priceCategory.id
            this.editSynagogueSeatPriceCategoryObj.price = priceCategory.price
            this.editSynagogueSeatPriceCategoryObj.colorHex = priceCategory.colorHex
            this.currTab = SYNAGOGUE_SEAT_PRICE_CATEGORY_EDIT_TAB
        },
        toggleDeleteSeatPriceCategory(priceCategory) {
            this.selectedSeatPriceCategoryId = priceCategory.id
            this.currTab = SYNAGOGUE_SEAT_PRICE_CATEGORY_DELETE_TAB
        },
        selectColor(color) {
            this.newSynagogueSeatPriceCategoryObj.colorHex = color
        },
        getSynagogueSeatPriceCategory() {
            this.$store.dispatch(GET_SYNAGOGUE_SEAT_PRICE_CATEGORIES, { org: this.organizationId })
        },
        addSynagogeSeatPriceCategory() {
            this.$store.dispatch(ADD_SYNAGOGUE_SEAT_PRICE_CATEGORY, this.newSynagogueSeatPriceCategoryObj).then((result) => {
                if (result) {
                    this.getSynagogueSeatPriceCategory()
                }
            })
        },
        editSynagogeSeatPriceCategory() {
            this.$store.dispatch(EDIT_SYNAGOGUE_SEAT_PRICE_CATEGORY, this.editSynagogueSeatPriceCategoryObj).then((result) => {
                if (result) {
                    this.currTab = SYNAGOGUE_SEAT_PRICE_CATEGORY_ADD_NEW_TAB
                    this.getSynagogueSeatPriceCategory()
                }
            })
        },
        deleteSynagogeSeatPriceCategory() {
            this.$store.dispatch(DELETE_SYNAGOGUE_SEAT_PRICE_CATEGORY, this.selectedSeatPriceCategoryId).then((result) => {
                if (result) {
                    this.getSynagogueSeatPriceCategory()
                    this.currTab = SYNAGOGUE_SEAT_PRICE_CATEGORY_ADD_NEW_TAB
                }
            })
        },
    },
    mounted() {
        this.setValues()
        this.getSynagogueSeatPriceCategory()
    }
}
</script>
<style scoped>
::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    position: relative;
    left: 20%;
    margin-top: 60px;
}

::v-deep .modal-content {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.2rem;
    background: #fff;
    max-height: 80vh;
    overflow-y: auto;
}

::v-deep .modal__content {
    max-height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.circle {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    border: 2px solid #B3B6BD;
    padding: 1.9px;
}

.circle:hover {
    cursor: pointer;
    border: 1.9px solid #007bff;
}

.selected-circle {
    border: 1.9px solid #007bff;
}

.circle-child {
    width: 28px;
    height: 28px;
    border-radius: 50%;
}

table .circle {
    width: 28px;
    height: 28px;
    position: relative;
    bottom: 2px
}

table .circle-child {
    width: 21.5px;
    height: 21.5px;
}

.delete-message {
    font-weight: 300;
}

.btn-save {
    background-color: #14243D;
}
</style>