import axios, { AxiosResponse } from 'axios'
import { SET_DONATION_REPORT_STATS, SET_DONATION_REPORT, SET_DONATION_REPORT_FILE, SET_DONATION_REPORT_FILES, SET_ERRORMSG } from '../../mutation_types'
import {
    GET_DONATION_REPORT_STATS,
    GET_DONATION_REPORT,
    GET_DONATION_REPORT_FILES,
    GENERATE_DONATION_REPORT_FILE,
    CLEAR_DONATION_ISLOADING
} from '../../action_types'
import { StateObject } from '@/dtos/state.dto'
import Report from '@/services/report/donation_report'
import ReportFile from '@/services/report/report_files'
import { Module } from 'vuex'
import { DonationReportStats } from '@/dtos/report/donation_report.dto'
import { GetReportFiles } from '@/dtos/report/report_file'

const report: Module<any, {}> = {
    state: {
        donationReport: {
            data: null,
            isLoading: false
        },
        donationReportStats: {
            data: null,
            isLoading: false
        },
        donationReportFile: {
            data: null,
            isLoading: false
        },
        donationReportFiles: {
            data: null,
            isLoading: false
        }
    },
    getters: {
        donationReport: (state: { donationReport: any }) => state.donationReport,
        donationReportStats: (state: { donationReportStats: any }) => state.donationReportStats,
        donationReportFile: (state: { donationReportFile: any }) => state.donationReportFile,
        donationReportFiles: (state: { donationReportFiles: any }) => state.donationReportFiles
    },
    mutations: {
        [SET_DONATION_REPORT]: (state: { donationReport: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.donationReport.data = payload.data : null, state.donationReport.isLoading = payload.isLoading },
        [SET_DONATION_REPORT_STATS]: (state: { donationReportStats: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.donationReportStats.data = payload.data : null, state.donationReportStats.isLoading = payload.isLoading },
        [SET_DONATION_REPORT_FILE]: (state: { donationReportFile: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.donationReportFile.data = payload.data : null, state.donationReportFile.isLoading = payload.isLoading },
        [SET_DONATION_REPORT_FILES]: (state: { donationReportFiles: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.donationReportFiles.data = payload.data : null, state.donationReportFiles.isLoading = payload.isLoading }
    },
    actions: {
        [GET_DONATION_REPORT_STATS]: async({ commit }: any, payload: any) => {
            commit(SET_DONATION_REPORT_STATS, { data: null, isLoading: true })

            let result: AxiosResponse = await Report.getDonationReportStats(payload as DonationReportStats)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_DONATION_REPORT_STATS, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_DONATION_REPORT_STATS, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_DONATION_REPORT_STATS, { isLoading: false })
                return false;
            }
        },
        [GET_DONATION_REPORT]: async({ commit }: any, payload: any) => {
            commit(SET_DONATION_REPORT, { data: null, isLoading: true })

            let result: AxiosResponse = await Report.getDonationReport(payload as DonationReportStats)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_DONATION_REPORT, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_DONATION_REPORT, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_DONATION_REPORT, { isLoading: false })
                return false;
            }
        },
        [GENERATE_DONATION_REPORT_FILE]: async({ commit }: any, payload: any) => {
            commit(SET_DONATION_REPORT_FILE, { data: null, isLoading: true })

            let result: AxiosResponse = await Report.generateDonationReportFile(payload as DonationReportStats)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_DONATION_REPORT_FILE, { data: result.data.data, isLoading: false })
                    return result.data.data;
                }
                commit(SET_DONATION_REPORT_FILE, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return null;
            } else {
                commit(SET_DONATION_REPORT_FILE, { isLoading: false })
                return null;
            }
        },
        [GET_DONATION_REPORT_FILES]: async({ commit }: any, payload: any) => {
            commit(SET_DONATION_REPORT_FILES, { data: null, isLoading: true })

            let result: AxiosResponse = await ReportFile.getReportFiles(payload as GetReportFiles)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_DONATION_REPORT_FILES, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_DONATION_REPORT_FILES, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_DONATION_REPORT_FILES, { isLoading: false })
                return false;
            }
        },
        [CLEAR_DONATION_ISLOADING]: ({ commit }) => {
            commit(SET_DONATION_REPORT, { isLoading: false })
            commit(SET_DONATION_REPORT_STATS, { isLoading: false })
            commit(SET_DONATION_REPORT_FILE, { isLoading: false })
            commit(SET_DONATION_REPORT_FILES, { isLoading: false })
        }
    }
}

export default report