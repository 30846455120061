import { createStore } from 'vuex'
import { LOCAL_STORAGE_KEY } from '@/utils/const';
import createVuexPersistedState from 'vuex-persistedstate';
import account from './modules/auth'
import alert from './modules/alert'
import donation from './modules/donation'
import error from './modules/error'
import users from './modules/user';
import screen from './modules/screen';
import stats from './modules/stats';
import template from './modules/template';
import organization from './modules/organization';
import announcement from './modules/announcement';
import member from './modules/member';
import purpose from './modules/purpose';
import notification from './modules/notification';
import minyanim from './modules/minyanim';
import room from './modules/room';
import ticket from './modules/ticket';
import ticketComment from './modules/ticket_comment';
import minyanimType from './modules/minyanim_type';
import alertNotification from './modules/alert_notification';
import shiurim from './modules/shiurim';
import synagogue from './modules/synagogue';
import synagogueSeat from './modules/synagogue_seat';
import invoice from './modules/invoice';
import invoiceItem from './modules/invoice_item';
import invoiceItemPaymentLog from './modules/invoice_item_payment_log';
import synagogueSeatPriceCategory from './modules/synagogue_seat_price_category';
import synagogueSeatPricing from './modules/synagogue_seat_pricing';
import synagogueMemberSeat from './modules/synagogue_member_seat';
import receipt from './modules/receipt';
import memberType from './modules/member_type';
import pledgeItem from './modules/pledge_item';
import organizationScreen from './modules/organization_screen';
import pledgeItemPaymentLog from './modules/pledge_item_payment_log';
import report from './modules/report/donation_report';
import reportFile from './modules/report/report_file';
import pledgeItemDeletionReason from './modules/pledge_item_deletion_reason';
import pledgeType from './modules/pledge_type';
import moduleSetting from './modules/module_setting';
import memberStatement from './modules/report/member_statement';
import routerLoader from './modules/route_loader';
import alias from './modules/alias';
import aliasOfferingType from './modules/alias_offering_type';
import parshat from './modules/parshat';

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    account,
    announcement,
    alert,
    alertNotification,
    alias,
    aliasOfferingType,
    error,
    donation,
    invoice,
    invoiceItem,
    invoiceItemPaymentLog,
    member,
    memberType,
    memberStatement,
    minyanim,
    minyanimType,
    moduleSetting,
    notification,
    organization,
    organizationScreen,
    parshat,
    pledgeItem,
    pledgeItemDeletionReason,
    pledgeItemPaymentLog,
    pledgeType,
    purpose,
    receipt,
    report,
    reportFile,
    room,
    routerLoader,
    screen,
    shiurim,
    stats,
    synagogue,
    synagogueSeat,
    synagogueMemberSeat,
    synagogueSeatPricing,
    synagogueSeatPriceCategory,
    template,
    ticket,
    ticketComment,
    users
  },
  plugins: [
    createVuexPersistedState({
        key: LOCAL_STORAGE_KEY,
        storage: window.localStorage,
        paths: ['account', 'moduleSetting']
    })
  ]
})
