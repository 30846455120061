import axios, { AxiosResponse } from 'axios'
import { SET_PURPOSES, SET_PURPOSE, SET_ERRORMSG, SET_MEMBER_PURPOSES } from '../mutation_types'
import {
    CLEAR_PURPOSE_ISLOADING,
    DELETE_PURPOSE,
    EDIT_PURPOSE,
    GET_PURPOSES,
    ADD_PURPOSE,
    GET_MEMBER_PURPOSES,
} from '../action_types'
import { StateObject } from '@/dtos/state.dto'
import Purpose from '@/services/purpose'
import { Module } from 'vuex'
import { EditPurpose, AddPurpose, GetPurpose } from '@/dtos/purpose.dto'

const purpose: Module<any, {}> = {
    state: {
        purpose: {
            data: null,
            isLoading: false
        },
        purposes: {
            data: null,
            isLoading: false
        },
        memberPurposes: {
            data: null,
            isLoading: false
        }
    },
    getters: {
        purpose: (state: { purpose: any }) => state.purpose,
        purposes: (state: { purposes: any }) => state.purposes,
        memberPurposes: (state: { memberPurposes: any }) => state.memberPurposes
    },
    mutations: {
        [SET_PURPOSE]: (state: { purpose: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.purpose.data = payload.data : null, state.purpose.isLoading = payload.isLoading },
        [SET_PURPOSES]: (state: { purposes: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.purposes.data = payload.data : null, state.purposes.isLoading = payload.isLoading },
        [SET_MEMBER_PURPOSES]: (state: { memberPurposes: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.memberPurposes.data = payload.data : null, state.memberPurposes.isLoading = payload.isLoading }
    },
    actions: {
        [ADD_PURPOSE]: async({ commit }: any, payload: any) => {
            commit(SET_PURPOSE, { data: null, isLoading: true })

            let result: AxiosResponse = await Purpose.registerPurpose(payload as AddPurpose)
          
            if(result) {
                if (result.data.status) {
                    commit(SET_PURPOSE, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_PURPOSE, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_PURPOSE, { isLoading: false })
                return false;
            }
        },
        [GET_PURPOSES]: async({ commit }: any, payload: any) => {
            commit(SET_PURPOSES, { data: null, isLoading: true })

            let result: AxiosResponse = await Purpose.getPurposes(payload as GetPurpose)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_PURPOSES, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_PURPOSES, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_PURPOSES, { isLoading: false })
                return false;
            }
        },
        [GET_MEMBER_PURPOSES]: async({ commit }: any, payload: any) => {
            commit(SET_MEMBER_PURPOSES, { data: null, isLoading: true })

            let result: AxiosResponse = await Purpose.getPurposes(payload as GetPurpose)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_MEMBER_PURPOSES, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_MEMBER_PURPOSES, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_MEMBER_PURPOSES, { isLoading: false })
                return false;
            }
        },
        [EDIT_PURPOSE]: async({ commit }: any, payload: any) => {
            commit(SET_PURPOSE, { data: null, isLoading: true })

            let result: AxiosResponse = await Purpose.editPurpose(payload as EditPurpose)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_PURPOSE, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_PURPOSE, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_PURPOSE, { isLoading: false })
                return false;
            }
        },
        [DELETE_PURPOSE]: async({ commit }: any, payload: string) => {
            commit(SET_PURPOSE, { data: null, isLoading: true })

            let result: AxiosResponse = await Purpose.deletePurpose(payload)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_PURPOSE, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_PURPOSE, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_PURPOSE, { isLoading: false })
                return false;
            }
        },
        [CLEAR_PURPOSE_ISLOADING]: ({ commit }) => {
            commit(SET_PURPOSE, { isLoading: false })
            commit(SET_PURPOSES, { isLoading: false })
        }
    }
}

export default purpose