<template>
    <div class="dashboard-body">
        <div class="row">
            <sidenav class="custom-col-width-2" />
            <div style="padding-left: 0px!important" class="custom-col-width-10 content-section dashboard-content">
                <navbar />
                <viewDonationTemplateModal v-if="showViewDonationModal" :selectedDonationToView="selectedDonationToView"
                    @close-view-donation-modal="showViewDonationModal = false" />
                <div v-if="dashboardStats" class="content">
                    <div class="row justify-content-between">
                        <div class="col">
                            <h3 class="welcome">Welcome Back {{ account.fname }}!</h3>
                            <h5 class="timer">{{ currentDate }} <span>|</span> {{ currentTime }}</h5>
                        </div>
                        <div v-if="account.role == USER_ROLE_ADMIN" class="col-2">
                            <div class="dropdown org-dropdown">
                                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {{ selectedOrganization != null ? selectedOrganization.name : 'All Organizations' }}
                                </button>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <template v-bind:key="index" v-for="(organization, index) in organizations">
                                        <a class="dropdown-item" v-on:click="selectOrganization(organization)">{{
                                            organization.name }}</a>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-9 col-md-12 col-sm-12">
                            <div class="card">
                                <div class="card-body">
                                    <h4 class="chart-title">Last 20 days dontations</h4>
                                    <span class="chart-sub-title">Keep up your good work!</span>
                                    <hr>
                                    <apexchart ref="usersChart" :height="isWideScreen ? '350' : '250'" type="line"
                                        class="" :options="chartOptions" :series="series"></apexchart>
                                </div>
                            </div>
                        </div>
                        <div class="d-none d-lg-block col-lg-3 col-md-12 col-sm-12 stats-col">
                            <div class="card stats-card-1">
                                <div class="card-body">
                                    <div class="stats-content">
                                        <h6 class="title">All Time Donations</h6>
                                        <h3 class="value">{{
                                            this.$filters.formatPrice(dashboardStats.allTimeTotalDonations, 'USD') }}
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div class="card stats-card-2">
                                <div class="card-body">
                                    <div class="stats-content">
                                        <h6 class="title">Donations This Month</h6>
                                        <h3 class="value"> {{
                                            this.$filters.formatPrice(dashboardStats.currMonthTotalDonations, 'USD') }}
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="d-none d-md-block d-lg-none mid-screen-stats">
                        <div class="row">
                            <div class="col-6">
                                <div class="card stats-card-1">
                                    <div class="card-body">
                                        <div class="stats-content">
                                            <h6 class="title">All Time Donations</h6>
                                            <h3 class="value">{{
                                                this.$filters.formatPrice(dashboardStats.allTimeTotalDonations, 'USD')
                                                }}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="card stats-card-2">
                                    <div class="card-body">
                                        <div class="stats-content">
                                            <h6 class="title">Donations This Month</h6>
                                            <h3 class="value">{{
                                                this.$filters.formatPrice(dashboardStats.currMonthTotalDonations, 'USD')
                                                }}
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="card table-card">
                                <div class="card-body">
                                    <router-link
                                        :to="{ name: ORGANIZATION_DONATIONS_ROUTE, params: { id: account.org } }"
                                        class="btn">See All</router-link>
                                    <div class="row">
                                        <div class="col-8">
                                            <h4 class="table-title">Recent Dontations <span
                                                    v-if="dashboardStats.donationsCountToday"
                                                    class="badge badge-active text-center">+{{
                                                    dashboardStats.donationsCountToday
                                                    }} Donation{{ dashboardStats.donationsCountToday > 1 ? 's' : '' }}
                                                </span></h4>
                                        </div>
                                    </div>
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <!-- <th scope="col">#</th> -->
                                                <!-- <th scope="col">Title</th> -->
                                                <th scope="col">Donor</th>
                                                <th scope="col">Amount</th>
                                                <th scope="col">Donation Date</th>
                                                <th scope="col" class="d-none d-lg-block">Display Date</th>
                                                <th scope="col">Registered By</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-bind:key="index"
                                                v-for="(donation, index) in dashboardStats.recentDonations">
                                                <tr>
                                                    <!-- <th scope="row">{{ index + 1 }}</th> -->
                                                    <!-- <td><span v-html="renderContent(donation.template.content, donation)"></span></td> -->
                                                    <td><span v-if="donation.member">{{ donation.member.fname }} {{
                                                            donation.member.lname }}</span></td>
                                                    <td>{{ $filters.formatPrice(donation.amount, 'USD') }}</td>
                                                    <td>
                                                        <span class="frankRuhl-font">{{
                                                            $filters.convertToHebrewDate(donation.donationDate) }}</span>
                                                    </td>
                                                    <td class="d-none d-lg-block">
                                                        <span class="frankRuhl-font">{{ $filters.convertToHebrewDate(donation.donationDisplayStartDate)
                                                            }}</span>
                                                        <small> | </small>
                                                        <span class="frankRuhl-font">{{ $filters.convertToHebrewDate(donation.donationDisplayEndDate) }}</span>
                                                    </td>
                                                    <td>{{ donation.user.fname }}</td>
                                                    <td><i v-on:click="selectDontationToView(donation)"
                                                            class="pi pi-eye text-secondary"></i></td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <br>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import VueApexCharts from 'vue3-apexcharts'
import VueCal from 'vue-cal'
import Hebcal from 'hebcal'

import viewDonationTemplateModal from '@/components/modals/Donation/View_Donation_Template_Modal.vue'
import sidenav from '@/components/Sidenav.vue'
import navbar from '@/components/Navbar.vue'
import { GET_DASHBOARD_STATS, GET_ORGANIZATIONS } from '@/store/action_types'
import { USER_ROLE_ADMIN } from '../utils/const';
import { ORGANIZATION_DONATIONS_ROUTE } from '@/router/routes';
export default {
    title: 'Dashboard',
    components: {
        sidenav,
        navbar,
        viewDonationTemplateModal,
        apexchart: VueApexCharts,
        VueCal
    },
    data() {
        return {
            USER_ROLE_ADMIN,
            ORGANIZATION_DONATIONS_ROUTE,
            selectedOrganization: null,
            showViewDonationModal: false,
            selectedDonationToView: null,
            currentTime: '',
            currentDate: '',
            timer: null,
            isWideScreen: false,
            series: [{
                name: 'Donations',
                data: []
            },],
            chartOptions: {
                chart: {
                    height: 350,
                    type: 'line'
                },
                colors: ['#6A8CC6'],
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth',
                    width: 4
                },
                xaxis: {
                    categories: []
                },
                grid: {
                    borderColor: '#C2C6CE',
                    strokeDashArray: 4
                },
                tooltip: {
                    x: {
                        format: 'dd/MM/yy HH:mm'
                    },
                },
            },
        }
    },
    computed: {
        isLoading() {
            return this.$store.getters.dashboardStats.isLoading
        },
        dashboardStats() {
            return this.$store.getters.dashboardStats.data
        },
        organizations() {
            return this.$store.getters.organizations.data
        },
        account() {
            return this.$store.getters.account.data
        },
    },
    methods: {
        updateTime() {
            const now = new Date();
            const optionsDate = {
                year: 'numeric',
                month: 'short',
                day: 'numeric'
            };
            const optionsTime = {
                hour: '2-digit',
                minute: '2-digit',
                hour12: true
            };
            let date = now.toLocaleString('en-US', optionsDate);
            let time = now.toLocaleString('en-US', optionsTime);

            this.currentTime = time
            this.currentDate = date
        },
        onClickDate(date) {
            alert(`Selected date: ${this.toHebrewDate(date)}`);
        },
        toHebrewDate(gregorianDate) {
            const hDate = new Hebcal.HDate(gregorianDate);
            return `${hDate.toString()} (${hDate.toGorgDateString()})`;
        },
        loadHebrewDates({ start, end }) {
            // You might need to calculate and replace Gregorian dates with Hebrew dates here
            console.log(`Load Hebrew dates from ${start} to ${end}`);
        },
        isOdd(number) {
            return number % 2 !== 0;
        },
        checkScreenWidth() {
            this.isWideScreen = window.innerWidth > 1500;
        },
        updateSeriesLine() {
            this.series = [{
                name: 'Donations',
                data: this.dashboardStats.last10DaysDonation.amount
            }]
            this.chartOptions = {
                chart: {
                    type: 'line'
                },
                colors: ['#6A8CC6'],
                stroke: {
                    curve: 'smooth',
                    width: 3
                },
                dataLabels: {
                    enabled: false
                },
                grid: {
                    borderColor: '#C2C6CE',
                    strokeDashArray: 4
                },
                xaxis: {
                    categories: this.dashboardStats.last10DaysDonation.weekDays
                },
            };

        },
        renderContent(template, donation) {
            let replacements = []

            Object.keys(donation).forEach(key => {
                const regex = new RegExp(`{${key}}`, 'g');
                if (template.includes(`{${key}}`)) {
                    replacements.push({
                        replace: regex,
                        replaceBy: donation[key]
                    })
                }

            });

            replacements.forEach((e) => {
                template = template.replace(e.replace, e.replaceBy)
            })
            return template;
        },
        selectDontationToView(donation) {
            this.selectedDonationToView = donation
            this.showViewDonationModal = true
        },
        selectOrganization(organization) {
            this.selectedOrganization = organization
            this.$store.dispatch(GET_DASHBOARD_STATS, { org: organization.id }).then((res) => {
                if (res) {
                    this.updateSeriesLine()
                }
            })
        },
        getOrganizations() {
            if (this.account.role == USER_ROLE_ADMIN) {
                this.$store.dispatch(GET_ORGANIZATIONS)
            }
        },
        getDashboardStats() {
            if (this.account.role == USER_ROLE_ADMIN) {
                this.$store.dispatch(GET_DASHBOARD_STATS).then((res) => {
                    if (res) {
                        this.updateSeriesLine()
                    }
                })
            } else {
                this.$store.dispatch(GET_DASHBOARD_STATS, { org: this.account.org }).then((res) => {
                    if (res) {
                        this.updateSeriesLine()
                    }
                })
            }
        }
    },
    mounted() {
        this.checkScreenWidth()
        this.updateTime();
        this.timer = setInterval(this.updateTime, 10000);
        if (this.dashboardStats != null) {
            this.updateSeriesLine()
        }
        this.getDashboardStats()
        this.getOrganizations()
    },
    beforeDestroy() {
        clearInterval(this.timer);
    }
}
</script>
<style scoped>
.content .donations .card,
.content .donations .card-body {
    padding: 0px;
}

.content .donations {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: #F4ECE2;
}

.content .timer {
    font-size: 18px;
    font-weight: 400 !important;
    color: #606060;
    padding-bottom: 10px;
}

.content .welcome {
    font-size: 26px;
}

.content .timer span {
    margin-left: 7px;
    margin-right: 7px;
}

.content .org-dropdown {
    position: relative;
    top: 23px;
    float: right;
}

.content .org-dropdown .btn {
    border-radius: 8px;
    font-size: 14px;
    color: #6084C2;
    border-radius: 8px;
    border: 1px solid transparent;
    background-color: #D9E2F1;
}

.content .org-dropdown .dropdown-menu {
    border-radius: 8px;
    border: 1px solid #E0E2E7;
}

.content .org-dropdown .dropdown-item {
    font-size: 14px;
    cursor: pointer;
}

.content .org-dropdown .dropdown-item:hover {
    background-color: #F5F5FB;
}

.content .org-dropdown .dropdown-item:active {
    color: #212529;
    background-color: #edf4ff;
}

.stats-col {
    padding-left: 0px;
    padding-right: 0px;
}

.stats-card-1,
.stats-card-1 .card-body,
.stats-card-2,
.stats-card-2 .card-body {
    padding: 0px;
    background-color: transparent;
}

.stats-card-1 {
    background-image: url('../assets/images/dashboard-img-1.png');
    background-repeat: no-repeat;
    background-position: top;
    background-size: contain;
    height: 50%;
}

.stats-card-2 {
    background-image: url('../assets/images/dashboard-img-2.png');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
    height: 50%;
}

.stats-card-1 .card-body .stats-content .title,
.stats-card-2 .card-body .stats-content .title {
    color: #D7E0F0;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.6px;
    margin-bottom: 11px;
}

.stats-card-1 .card-body .stats-content .value,
.stats-card-2 .card-body .stats-content .value {
    color: #FFFFFF;
    font-size: 28px;
    font-weight: 500;
    letter-spacing: 0.6px;
}

.card .card-body hr {
    border: 1px solid transparent;
}

.table thead th {
    padding: 18px 30px 18px 30px;
}

table thead {
    background-color: #F9F9F9!important;
    border-bottom: 1px solid #F0F1F3 !important;
}

.table tr td {
}

.table tr td {
    padding: 23px 30px 23px 30px!important;
    border-bottom: 1px solid #F0F1F3 !important;
}

.table-title {
    padding: 12px 30px 12px 15px;
}

.table-card .card-body {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 15px;
}

.table-card .btn {
    float: right;
    font-size: 14px;
    font-weight: 600;
    color: #6084C2;
    border-radius: 8px;
    background-color: #D9E2F1;
    margin-right: 12px;
}

.table-card .badge-active {
    font-size: 14px!important;
}

.content .donations .card table .striped {
    background-color: #FAF7F4 !important;
}

.content .donations .card table tbody tr:hover {
    background-color: #FAF7F4 !important;
}

.content .card .card-body small {
    font-size: 17px;
}

.content .card table .pi-eye {
    font-size: 20px;
}

.content .card table .pi-eye {
    cursor: pointer;
}

.chart-sub-title {
    color: #777980;
}

.custom-col-width-2 {
    width: 16.66%;
}

.custom-col-width-10 {
    width: 83.3%;
}

.d-none, 
.d-lg-block {
    border-top: 0px solid transparent !important;
    border-bottom: 0px solid transparent !important;
}

@media (max-width: 1000px) {
    .custom-col-width-2 {
        width: 12%;
    }

    .custom-col-width-10 {
        width: 88%;
    }
}

@media (max-width: 1500px) {
    .stats-card-1 .card-body .stats-content {
        position: absolute;
        bottom: 30px;
        left: 28px;
    }

    .stats-card-2 .card-body .stats-content {
        position: absolute;
        bottom: 26px;
        left: 28px;
    }
}

@media (min-width: 1500px) {
    .stats-card-1 {
        height: 49%;
        margin-bottom: 10px;
    }

    .stats-card-2 {
        height: 49%;
    }

    .stats-card-1 .card-body .stats-content {
        position: absolute;
        bottom: 35px;
        left: 38px;
    }

    .stats-card-2 .card-body .stats-content {
        position: absolute;
        bottom: 31px;
        left: 38px;
    }

    .stats-card-1 .card-body .stats-content .title,
    .stats-card-2 .card-body .stats-content .title {
        font-size: 18px;
    }

    .stats-card-1 .card-body .stats-content .value,
    .stats-card-2 .card-body .stats-content .value {
        font-size: 32px;
    }

    .stats-card-1 {
    }

    .stats-col {
        padding-left: 10px;
        padding-right: 10px;
    }
}

/* typically considered medium screens */
@media (max-width: 1199px) and (min-width: 768px) {
    .mid-screen-stats {
        margin-bottom: 0px;
    }

    .stats-card-1 {
        background-image: url('../assets/images/dashboard-img-1.png');
        background-repeat: no-repeat;
        background-position: top;
        background-size: contain;
        height: 250px;
    }

    .stats-card-2 {
        background-image: url('../assets/images/dashboard-img-2.png');
        background-repeat: no-repeat;
        background-position: top;
        background-size: contain;
        height: 250px;
    }

    .stats-card-1 .card-body .stats-content {
        position: absolute;
        bottom: 50px;
        left: 30px;
    }

    .stats-card-2 .card-body .stats-content {
        position: absolute;
        bottom: 50px;
        left: 30px;
    }

    .stats-card-1 .card-body .stats-content .title,
    .stats-card-2 .card-body .stats-content .title {
        font-size: 18px;
    }

    .stats-card-1 .card-body .stats-content .value,
    .stats-card-2 .card-body .stats-content .value {
        font-size: 32px;
    }
}
</style>