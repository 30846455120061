<template>
    <div class="dashboard-body">
        <div class="row">
            <sidenav class="custom-col-width-2" />
            <div style="padding-left: 0px!important" class="custom-col-width-10 content-section dashboard-content">
                <navbar />
                <viewAnnouncementModal v-if="showAnnouncementMessageModal"
                    :selectedAnnouncementToView="selectedAnnouncementToView"
                    @close-view-announcement-modal="showAnnouncementMessageModal = false" />
                <addAnnouncementModal v-if="showAddAnnouncementModal" :organizationId="organizationId"
                    @close-add-announcement-modal="showAddAnnouncementModal = false"
                    @refresh-announcements="getAnnouncements()" />
                <editAnnouncementModal v-if="showEditAnnouncementModal" :selectedAnnouncement="selectedAnnouncement"
                    @close-edit-announcement-modal="showEditAnnouncementModal = false"
                    @refresh-announcements="getAnnouncements()" />
                <deleteAnnouncementModal v-if="showDeleteAnnouncementModal"
                    :selectedAnnouncementId="selectedAnnouncementId"
                    @close-delete-announcement-modal="showDeleteAnnouncementModal = false"
                    @refresh-announcements="getAnnouncements()" />
                <div class="content">
                    <br>
                    <div class="row button-grp justify-content-between">
                        <div class="col">
                            <h4>Organization</h4>
                        </div>
                        <div class="col">
                            <button v-on:click="showAddAnnouncementModal = true" class="btn btn-add"><i
                                    class="pi pi-plus"></i> Add
                                Announcement</button>
                        </div>
                    </div>
                    <div class="row filter-grp">
                        <div class="col-lg-9 col-md-7">
                            <i class="pi pi-search" aria-hidden="true"></i>
                            <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
                        </div>
                        <div class="col-lg-3 col-md-5">
                            <div class="row">
                                <div class="btn col"><img src="../assets/icons/calendar-icon.svg" alt=""> Select Date
                                </div>
                                <div class="btn col"><img src="../assets/icons/filter-icon.svg" alt=""> Filters</div>
                            </div>
                        </div>
                    </div>
                    <div v-if="announcementsIsLoading && announcements == null">
                        <div class="row">
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#DFDFFAFF'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                        </div>
                    </div>
                    <div v-else class="row">
                        <div class="col-lg-12 offset-lg-0">
                            <div class="card">
                                <div class="card-body">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Title</th>
                                                <th scope="col">Screen</th>
                                                <th scope="col">Display Date</th>
                                                <th scope="col">Created At</th>
                                                <th scope="col">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-bind:key="index" v-for="(announcement, index) in announcements">
                                                <tr :class="[isOdd(index + 1) ? 'striped' : null]">
                                                    <th scope="row">{{ index + 1 }}</th>
                                                    <td>{{ announcement.title }}</td>
                                                    <td><span v-if="announcement.screen != null">{{
                                                            announcement.screen.name }}</span></td>
                                                    <td><span class="frankRuhl-font">{{
                                                            $filters.convertToHebrewDate(announcement.announcementDisplayStartDate)
                                                            }}</span> <small> | </small> <span class="frankRuhl-font">{{
                                                            $filters.convertToHebrewDate(announcement.announcementDisplayEndDate)
                                                            }}</span>
                                                    </td>
                                                    <td>{{ $filters.getDateOnly(announcement.createdAt) }}</td>
                                                    <td>
                                                        <i v-on:click="selectAnnouncementToView(announcement)"
                                                            class="pi pi-eye text-secondary"></i>
                                                        <i v-on:click="selectAnnouncementToEdit(announcement)"
                                                            class="pi pi-pencil text-primary"></i>
                                                        <i v-on:click="selectAnnouncementToDelete(announcement.id)"
                                                            class="pi pi-trash text-danger"></i>
                                                    </td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <br>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { ContentLoader } from 'vue-content-loader';

import sidenav from '@/components/Sidenav.vue'
import navbar from '@/components/Navbar.vue'
import addAnnouncementModal from '@/components/modals/Announcement/Add_Announcement_Modal.vue'
import editAnnouncementModal from '@/components/modals/Announcement/Edit_Announcement_Modal.vue'
import deleteAnnouncementModal from '@/components/modals/Announcement/Delete_Announcement_Modal.vue'
import viewAnnouncementModal from '@/components/modals/Announcement/View_Announcement_Modal.vue'

import { GET_ANNOUNCEMENTS } from '@/store/action_types'

export default {
    title: 'Announcements',
    components: {
        sidenav,
        navbar,
        addAnnouncementModal,
        editAnnouncementModal,
        deleteAnnouncementModal,
        viewAnnouncementModal,
        ContentLoader
    },
    data() {
        return {
            showAddAnnouncementModal: false,
            showEditAnnouncementModal: false,
            showDeleteAnnouncementModal: false,
            showAnnouncementMessageModal: false,
            selectedAnnouncement: null,
            selectedAnnouncementId: null,
            selectedAnnouncementToView: null,
            organizationId: null
        }
    },
    computed: {
        isLoading() {
            return this.$store.getters.announcements.isLoading
        },
        announcements() {
            return this.$store.getters.announcements.data
        },
        account() {
            return this.$store.getters.account.data
        }
    },
    methods: {
        isOdd(number) {
            return number % 2 !== 0;
        },
        selectAnnouncementToView(announcement) {
            this.selectedAnnouncementToView = announcement
            this.showAnnouncementMessageModal = true
        },
        selectAnnouncementToEdit(announcement) {
            this.selectedAnnouncement = announcement
            this.showEditAnnouncementModal = true
        },
        selectAnnouncementToDelete(announcementId) {
            this.selectedAnnouncementId = announcementId
            this.showDeleteAnnouncementModal = true
        },
        setOrganization() {
            this.organizationId = this.account.org
        },
        getAnnouncements() {
            this.$store.dispatch(GET_ANNOUNCEMENTS, { org: this.account.org })
        },
        switchAnnouncementView(type) {
            this.selectedView = type
        }
    },
    mounted() {
        this.getAnnouncements()
        this.setOrganization()
    }
}
</script>
<style scoped>
.content .card,
.content .card-body {
    padding: 0px;
}

.custom-col-width-2 {
    width: 16.66%;
}

.custom-col-width-10 {
    width: 83.3%;
}

@media (max-width: 1000px) {
    .custom-col-width-2 {
        width: 12%;
    }

    .custom-col-width-10 {
        width: 88%;
    }
}
</style>