<template>
    <div class="dashboard-body">
        <div class="row">
            <sidenav class="custom-col-width-2" />
            <div style="padding-left: 0px!important" class="custom-col-width-10 content-section dashboard-content">
                <navbar />
                <div class="content">
                    <br>
                    <div class="row button-grp justify-content-between">
                        <div class="col">
                            <h4>Member <small v-if="route.params.name">({{ route.params.name }})</small></h4>
                        </div>
                    </div>
                    <div class="row justify-content-between">
                        <memberNavbar class="" />
                    </div>
                    <!-- <div class="row filter-grp">
                        <div class="col-lg-9 col-md-9">
                            <i class="pi pi-search" aria-hidden="true"></i>
                            <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
                        </div>
                        <div class="col-lg-2 col-md-2">
                            <div class="row">
                                <div class="btn col-lg-7 col-md-10" style="display: block"><img
                                        src="../../assets/icons/filter-icon.svg" alt=""> Filters</div>
                            </div>
                        </div>
                    </div> -->
                    <div class="row justify-content-between">
                        <div class="col-2">
                            <router-link :to="{ name: MEMBER_INVOICES_ROUTE }" class="btn btn-back"><i
                                    class="pi pi-chevron-left"></i> Back</router-link>
                        </div>
                    </div>
                    <br>
                    <!-- {{ invoiceItemPaymentLogs }} -->
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card">
                                <div class="card-body">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Invoice #</th>
                                                <th scope="col">Invoice Item</th>
                                                <th scope="col">Amount Paid</th>
                                                <th scope="col">Payment Method</th>
                                                <th scope="col">Payment Date</th>
                                                <th scope="col">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-bind:key="index"
                                                v-for="(paymentLog, index) in invoiceItemPaymentLogs">
                                                <tr :class="[isOdd(index + 1) ? 'striped' : null]">
                                                    <th scope="row">{{ index + 1 }}</th>
                                                    <td><span v-if="paymentLog.invoice != null">{{
                                                            paymentLog.invoice.invoiceNumber }}</span></td>
                                                    <td><span v-if="paymentLog.invoice != null">{{
                                                            paymentLog.invoiceItem.name }}, <strong
                                                                class="text-secondary">{{
                                                                $filters.formatPrice((paymentLog.invoiceItem.price *
                                                                paymentLog.invoiceItem.quantity),
                                                                paymentLog.invoice.currency) }}</strong></span></td>
                                                    <td><span v-if="paymentLog.invoice != null">
                                                            {{ $filters.formatPrice(paymentLog.amountPaid,
                                                            paymentLog.invoice.currency) }}
                                                        </span></td>
                                                    <td>{{ paymentLog.paymentMethod }} {{ paymentLog.paymentMethod == PAYMENT_METHODS[3] ? ` - ${paymentLog.checkNumber}` : '' }}</td>
                                                    <td>{{ $filters.getDateOnly(paymentLog.createdAt) }}</td>
                                                    <td>
                                                        <!-- <i v-on:click="selectInvoice(invoice)"
                                                            class="pi pi-external-link text-primary"></i> -->
                                                        <i v-on:click="selectInvoiceToDelete(invoice.id)"
                                                            class="pi pi-trash text-danger"></i>
                                                    </td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <br>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import sidenav from '@/components/Sidenav.vue'
import navbar from '@/components/Navbar.vue'
import payInvoiceModal from '@/components/modals/Invoice/Pay_Invoice_Modal.vue'
import memberNavbar from '@/components/Member_Navbar.vue'
import registerInvoiceModal from '@/components/modals/Invoice/Register_Invoice_Modal.vue'
import invoiceItemsModal from '@/components/modals/Invoice/Invoice_Items_Modal.vue'
import deleteInvoiceModal from '@/components/modals/Invoice/Delete_Invoice_Modal.vue'
import memberPurposeModal from '@/components/modals/Member/Member_Purpose_Modal.vue'
import { GET_MEMBER_INVOICES, GET_MEMBERS } from '@/store/action_types'
import { STORAGE_URL } from '@/utils/const'
import { MEMBER_INVOICES_ROUTE } from '@/router/routes'
import { GET_INVOICE_ITEM_PAYMENT_LOGS } from '../../store/action_types';
import { PAYMENT_METHODS } from '../../utils/const';

export default {
    title: 'Members',
    components: {
        sidenav,
        navbar,
        payInvoiceModal,
        registerInvoiceModal,
        invoiceItemsModal,
        deleteInvoiceModal,
        memberPurposeModal,
        memberNavbar
    },
    data() {
        return {
            MEMBER_INVOICES_ROUTE,
            PAYMENT_METHODS,
            showRegisterInvoiceModal: false,
            showInvoiceItemsModal: false,
            showDeleteInvoiceModal: false,
            showMemberPurposeModal: false,
            selectedMember: null,
            selectedMemberId: null,
            selectedInvoice: null,
            selectedInvoiceId: null,
            organizationId: null,
            showPayInvoiceModal: null
        }
    },
    computed: {
        route() {
            return this.$route
        },
        invoiceItemPaymentLogs() {
            return this.$store.getters.invoiceItemPaymentLogs.data
        },
        invoiceItemPaymentLogsIsLoading() {
            return this.$store.getters.invoiceItemPaymentLogs.isLoading
        },
        account() {
            return this.$store.getters.account.data
        }
    },
    methods: {
        isOdd(number) {
            return number % 2 !== 0;
        },
        openFile(file) {
            window.open(`${STORAGE_URL}/${file}`, '_blank');
        },
        setValues() {
            this.organizationId = this.account.org
        },
        selectInvoice(invoice) {
            this.showInvoiceItemsModal = true
            this.selectedInvoice = invoice
        },
        selectInvoiceToDelete(id) {
            this.selectedInvoiceId = id
            this.showDeleteInvoiceModal = true
        },
        selectInvoicePurpose(member) {
            this.selectedMember = member
            this.showMemberPurposeModal = true
        },
        toggleMemberIsActive(id) {
            this.$store.dispatch(TOGGLE_MEMBER_IS_ACTIVE, { id })
        },
        setMember() {
            this.selectedMemberId = this.$route.params.membId
        },
        getInvoiceItemPaymentLogs() {
            this.$store.dispatch(GET_INVOICE_ITEM_PAYMENT_LOGS, { memb: this.$route.params.membId })
        }
    },
    mounted() {
        this.setValues()
        this.getInvoiceItemPaymentLogs()
        this.setMember()
    }
}
</script>
<style scoped>
.content .card,
.content .card-body {
    padding: 0px;
}

.unpaid {
    color: #dc3545;
    margin-left: 5px;
    margin-right: 5px;
}

.paid {
    color: #1A9882;
    margin-left: 5px;
}

.btn-pay {
    color: #5E84C2;
    font-weight: 700;
    letter-spacing: 0.7px;
    background-color: #E4EBFC;
    border: 1px solid #CCDCF7;
    position: relative;
    bottom: 7px;
    right: 15px;
}

.btn-back {
    font-weight: 600;
    color: #5D84C2;
    position: relative;
    top: 10px;
}

.btn-back .pi {
    font-size: 13px;
    margin-right: 6px;
}

.status-container {
    display: flex;
    align-items: center;
    padding: 0px;
    color: #000000;
    font-size: 13px;
}

.status-active-bullet {
    width: 9px;
    height: 9px;
    background-color: green;
    border-radius: 50%;
    animation: blink 1s infinite;
    margin-right: 10px;
}

.status-inactive-bullet {
    width: 9px;
    height: 9px;
    background-color: red;
    border-radius: 50%;
    /*animation: blink 1s infinite;*/
    margin-right: 10px;
}

.status-pending-bullet {
    width: 9px;
    height: 9px;
    background-color: #6c757d;
    border-radius: 50%;
    /*animation: blink 1s infinite;*/
    margin-right: 10px;
}

.custom-col-width-2 {
    width: 16.66%;
}

.custom-col-width-10 {
    width: 83.3%;
}

@media (max-width: 1000px) {
    .custom-col-width-2 {
        width: 12%;
    }

    .custom-col-width-10 {
        width: 88%;
    }
}
</style>