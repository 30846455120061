<template>
    <div>
        <vue-final-modal :drag="false" drag-selector="" v-model="showModal" content-class="modal-content"
            classes="modal-container" focus-trap>
            <button class="modal__close btn btn-light" @click="$emit('closeRemoveMemberSeatModal')">
                <i class="pi pi-times"></i>
            </button>
            <span class="modal__title">Remove Member Seat</span>
            <br>
            <div class="modal__content">
                <p v-if="errorMessage" class="text-center text-danger auth-status-msg">{{ errorMessage }}</p>
                <div class="row">
                    <div class="col-12">
                        <h6>Are you sure you want to remove {{ selectedMember.fname }} {{ selectedMember.lname }} from this seat?</h6>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-lg-2 offset-lg-6">
                        <button v-on:click="deleteMemberSeat()" class="btn text-danger"
                            :disabled="synagogueMemberSeatIsLoading ? true : false">
                            <span v-if="synagogueMemberSeatIsLoading" class="spinner-border spinner-border-sm" role="status"
                                aria-hidden="true"></span>
                            <span v-if="!synagogueMemberSeatIsLoading">Confirm</span>
                        </button>
                    </div>
                    <div class="col-lg-2 offset-lg-1">
                        <button v-on:click="$emit('closeRemoveMemberSeatModal')" class="btn">
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </vue-final-modal>
    </div>
</template>
<script>
import { DELETE_SYNAGOGUE_MEMBER_SEAT } from '@/store/action_types'
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'

import { Field, Form, ErrorMessage } from 'vee-validate';
import { defineRule } from 'vee-validate';
import { required, email, min } from '@vee-validate/rules';

defineRule('required', required);
defineRule('email', email);
defineRule('min', min);

export default {
    props: {
        selectedMemberSeatId: String,
        selectedMember: Object
    },
    components: {
        VueFinalModal,
        ModalsContainer,
        Form,
        Field,
        ErrorMessage
    },
    data() {
        return {
            showModal: true,
        }
    },
    computed: {
        errorMessage() {
            return this.$store.getters.errorMsg
        },
        synagogueMemberSeat() {
            return this.$store.getters.synagogueMemberSeat.data
        },
        synagogueMemberSeatIsLoading() {
            return this.$store.getters.synagogueMemberSeat.isLoading
        }
    },
    methods: {
      deleteMemberSeat() {
        if (this.selectedMemberSeatId) {
          this.$store.dispatch(DELETE_SYNAGOGUE_MEMBER_SEAT, this.selectedMemberSeatId).then((res) => {
              if (res) {
                  this.$emit('closeRemoveMemberSeatModal')
                  this.$emit('refreshMemberSeats')
              }
          })  
        } else {
          this.$emit('closeRemoveMemberSeatModal')
          this.$emit('refreshMemberSeats')
        }
      }
    }
}
</script>
<style scoped>
::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    position: relative;
    left: 35%;
    margin-top: 50px;
}

::v-deep .modal-content {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.2rem;
    background: #fff;
}

button {
    font-size: 15px;
}
</style>