<template>
    <div class="dashboard-body">
        <div class="row">
            <sidenav class="custom-col-width-2" />
            <div style="padding-left: 0px!important" class="custom-col-width-10 content-section dashboard-content">
                <navbar />
                <assignSeatToMemberModal v-if="showAssignSeatToMemberModal" :organizationId="organizationId"
                    @close-assign-seat-to-member-modal="closeAssignSeatToMemberModal()" />
                <div class="content">
                    <br>
                    <div class="row button-grp justify-content-between">
                        <div class="col">
                            <h4>Member <small v-if="route.params.name">({{ route.params.name }})</small></h4>
                        </div>
                        <div class="col">
                            <button v-on:click="selectSeatToAssign()" class="btn btn-add">Manage Seats</button>
                        </div>
                    </div>
                    <div class="row justify-content-between">
                        <memberNavbar class="" />
                    </div>
                    <br>
                    <div v-if="organizationIsLoading" class="row">
                        <div class="col-4">
                            <content-loader :speed="2" :width="370" :height="180" :viewBox="'0 0 370 180'"
                                :background-color="'#FFFFFF'" :border-radius="'10px'" :foreground-color="'#FDFBFB'">
                                <rect x="0" y="0" rx="5" ry="5" width="370" height="180" />
                            </content-loader>
                        </div>
                        <div class="col-4">
                            <content-loader :speed="2" :width="370" :height="180" :viewBox="'0 0 370 180'"
                                :background-color="'#FFFFFF'" :border-radius="'10px'" :foreground-color="'#FDFBFB'">
                                <rect x="0" y="0" rx="5" ry="5" width="370" height="180" />
                            </content-loader>
                        </div>
                        <div class="col-4">
                            <content-loader :speed="2" :width="370" :height="180" :viewBox="'0 0 370 180'"
                                :background-color="'#FFFFFF'" :border-radius="'10px'" :foreground-color="'#FDFBFB'">
                                <rect x="0" y="0" rx="5" ry="5" width="370" height="180" />
                            </content-loader>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-8">
                            <div class="card">
                                <div class="card-body">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Price</th>
                                                <th scope="col">Synagogue</th>
                                                <th scope="col">Created At</th>
                                                <!-- <th scope="col">Actions</th> -->
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-bind:key="index" v-for="(memberSeat, index) in synagogueMemberSeats">
                                                <tr :class="[isOdd(index + 1) ? 'striped' : null ]">
                                                    <th scope="row">{{ index + 1 }}</th>
                                                    <td><span v-if="memberSeat.synagogueSeatPricing">{{ $filters.formatPrice(memberSeat.synagogueSeatPricing.synagogueSeatPriceCategory.price, 'USD') }}</span></td>
                                                    <td><span v-if="memberSeat.synagogue">{{ memberSeat.synagogue.name }}</span></td>
                                                    <td>{{ $filters.getDateOnly(memberSeat.createdAt) }}</td>
                                                    <!-- <td>
                                                        <i v-on:click="selectMember(member)"
                                                            class="pi pi-pencil text-primary"></i>
                                                        <i v-on:click="selectMemberToDelete(member.id)"
                                                            class="pi pi-trash text-danger"></i>
                                                    </td> -->
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <br>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { ContentLoader } from 'vue-content-loader';

import sidenav from '@/components/Sidenav.vue'
import navbar from '@/components/Navbar.vue'
import memberNavbar from '@/components/Member_Navbar.vue'
import assignSeatToMemberModal from '@/components/modals/Synagogue/Seats/Assign_Member_Seat_Modal.vue'
import { GET_SYNAGOGUE_MEMBER_SEATS } from '@/store/action_types'

export default {
    title: 'Details',
    components: {
        sidenav,
        navbar,
        memberNavbar,
        assignSeatToMemberModal,
        ContentLoader
    },
    data() {
        return {
            showRegisterOrganizationModal: false,
            showEditOrganizationModal: false,
            showDeleteOrganizationModal: false,
            selectedOrganization: null,
            selectedOrganizationId: null,
            showAssignSeatToMemberModal: null,
            organizationId: null
        }
    },
    computed: {
        route() {
            return this.$route
        },
        synagogueMemberSeatsIsLoading() {
            return this.$store.getters.synagogueMemberSeats.isLoading
        },
        synagogueMemberSeats() {
            return this.$store.getters.synagogueMemberSeats.data
        },
        organizationIsLoading() {
            return this.$store.getters.organization.isLoading
        },
        organization() {
            return this.$store.getters.organization.data
        }
    },
    methods: {
        isOdd(number) {
            return number % 2 !== 0;
        },
        setValues() {
            this.organizationId = this.$route.params.orgId
        },
        selectSeatToAssign() {
            this.selectedOrganization = this.organization
            this.showAssignSeatToMemberModal = true
        },
        selectOrganizationToDelete(templateId) {
            this.selectedOrganizationId = templateId
            this.showDeleteOrganizationModal = true
        },
        closeAssignSeatToMemberModal() {
            this.showAssignSeatToMemberModal = false
            this.getMemberSeats()
        },
        getMemberSeats() {
            this.$store.dispatch(GET_SYNAGOGUE_MEMBER_SEATS, { memb: this.$route.params.membId })
        }
    },
    mounted() {
        this.setValues()
        this.getMemberSeats()
    }
}
</script>
<style scoped>
.content .card,
.content .card-body {
    padding: 0px;
}

.content .info-card {
    margin-bottom: 20px;
}

.content .card .card-header {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: #F4ECE2;
}

.content .card .header {
    font-weight: 700;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #1D1F2C;
}

.content .card .card-content {
    padding: 15px 35px 15px 35px;
}

.content .card .card-content>.row {
    margin-bottom: 7px
}

.content .card .card-content .title {
    font-weight: 600;
    color: #1D1F2C;
}

.content .card .card-content .title img {
    height: 40px !important;
    margin-right: 6px;
}

.content .card .card-content .value {
    font-weight: 600;
    color: #1D1F2C;
    margin-top: 6px
}

.content .btn-add {
    height: 40px;
    float: right;
    font-size: 14px;
    border-radius: 8px;
    color: #FFFFFF !important;
    padding: 10px, 14px, 10px, 14px;
    
}

.content .card table small {
    margin-left: 10px;
    margin-right: 10px;
}

.content .card table .pi-trash,
.content .card table .pi-pencil {
    cursor: pointer;
}

/*.content .organization-nav .btn {
    color: #5A5A57;
    padding: 0px;
    border-radius: 0px;
    text-decoration: unset;
    margin-left: 10px;
    font-size: 18px;
}

.content .organization-nav .btn:hover,
.content .organization-nav .btn-selected {
    padding-bottom: 5px;
    color: #cf9904;
    border-bottom: 2px solid #cf9904;
}
*/

.content .organization-nav .card-body {
    padding: 15px !important;
}

.custom-col-width-2 {
    width: 16.66%;
}

.custom-col-width-10 {
    width: 83.3%;
}

@media (max-width: 1000px) {
    .custom-col-width-2 {
        width: 12%;
    }

    .custom-col-width-10 {
        width: 88%;
    }
}
</style>