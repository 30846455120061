import axios, { AxiosResponse } from 'axios'
import { SET_PARSHATS, SET_PARSHAT, SET_ERRORMSG } from '../mutation_types'
import {
    CLEAR_PARSHATS_ISLOADING,
    DELETE_PARSHAT,
    EDIT_PARSHAT,
    GET_PARSHATS,
    ADD_PARSHAT,
} from '../action_types'
import { StateObject } from '@/dtos/state.dto'
import Parshat from '@/services/parshat'
import { Module } from 'vuex'
import { EditParshat, AddParshat, GetParshat } from '@/dtos/parshat.dto'

const parshat: Module<any, {}> = {
    state: {
        parshat: {
            data: null,
            isLoading: false
        },
        parshats: {
            data: null,
            isLoading: false
        }
    },
    getters: {
        parshat: (state: { parshat: any }) => state.parshat,
        parshats: (state: { parshats: any }) => state.parshats
    },
    mutations: {
        [SET_PARSHAT]: (state: { parshat: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.parshat.data = payload.data : null, state.parshat.isLoading = payload.isLoading },
        [SET_PARSHATS]: (state: { parshats: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.parshats.data = payload.data : null, state.parshats.isLoading = payload.isLoading }
    },
    actions: {
        [ADD_PARSHAT]: async({ commit }: any, payload: any) => {
            commit(SET_PARSHAT, { data: null, isLoading: true })

            let result: AxiosResponse = await Parshat.registerParshat(payload as AddParshat)
          
            if(result) {
                if (result.data.status) {
                    commit(SET_PARSHAT, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_PARSHAT, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_PARSHAT, { isLoading: false })
                return false;
            }
        },
        [GET_PARSHATS]: async({ commit }: any, payload: any) => {
            commit(SET_PARSHATS, { data: null, isLoading: true })

            let result: AxiosResponse = await Parshat.getParshats(payload as GetParshat)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_PARSHATS, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_PARSHATS, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_PARSHATS, { isLoading: false })
                return false;
            }
        },
        [EDIT_PARSHAT]: async({ commit }: any, payload: any) => {
            commit(SET_PARSHAT, { data: null, isLoading: true })

            let result: AxiosResponse = await Parshat.editParshat(payload as EditParshat)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_PARSHAT, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_PARSHAT, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_PARSHAT, { isLoading: false })
                return false;
            }
        },
        [DELETE_PARSHAT]: async({ commit }: any, payload: string) => {
            commit(SET_PARSHAT, { data: null, isLoading: true })

            let result: AxiosResponse = await Parshat.deleteParshat(payload)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_PARSHAT, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_PARSHAT, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_PARSHAT, { isLoading: false })
                return false;
            }
        },
        [CLEAR_PARSHATS_ISLOADING]: ({ commit }) => {
            commit(SET_PARSHAT, { isLoading: false })
            commit(SET_PARSHATS, { isLoading: false })
        }
    }
}

export default parshat