import baseAPI from '@/api/base';
import { GetPledgeItemPaymentLog } from '../dtos/pledge_item_payment_log.dto';
import { PLEDGE_ITEM_PAYMENT_LOG_URL } from '@/utils/urls';
import { AxiosResponse } from 'axios';

class PledgeItemPaymentLog {
    static getPledgeItemPaymentLogs = async(getPledgeItemPaymentLog: GetPledgeItemPaymentLog) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(PLEDGE_ITEM_PAYMENT_LOG_URL, {
                    params: getPledgeItemPaymentLog
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }
}

export default PledgeItemPaymentLog