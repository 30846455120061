<template>
    <div class="dashboard-body">
        <div class="row">
            <sidenav class="custom-col-width-2" />
            <div style="padding-left: 0px!important" class="custom-col-width-10 content-section dashboard-content">
                <navbar />
                <registerOrganizationModal v-if="showRegisterOrganizationModal"
                    @close-register-organization-modal="showRegisterOrganizationModal = false"
                    @refresh-organizations="getDetails()" />
                <!-- <editOrganizationModal v-if="showEditOrganizationModal" :selectedOrganization="selectedOrganization"
                    @close-edit-organization-modal="showEditOrganizationModal = false"
                    @refresh-organization="getOrganization()" /> -->
                <deleteOrganizationModal v-if="showDeleteOrganizationModal"
                    :selectedOrganizationId="selectedOrganizationId"
                    @close-delete-template-modal="showDeleteOrganizationModal = false"
                    @refresh-organizations="getOrganizations()" />
                <headerImageUploadModal v-if="showHeaderImageUploadModal" :selectedHeaderImage="selectedHeaderImage"
                    @close-header-image-upload-modal="showHeaderImageUploadModal = false" />
                <div class="content">
                    <br>
                    <div class="row button-grp justify-content-between">
                        <div class="col">
                            <h4>Organization</h4>
                        </div>
                        <div class="col">
                            <button v-on:click="selectOrganizationToEdit()" class="btn btn-add">Edit</button>
                        </div>
                    </div>
                    <div class="row justify-content-between">
                        <organizationNavbar class="" />
                    </div>
                    <br>
                    <div v-if="organizationIsLoading" class="row">
                        <div class="col-4">
                            <content-loader :speed="1" :viewBox="'0 0 100 60'" :background-color="'#FFFFFF'"
                                :border-radius="'10px'" :foreground-color="'#F5F5FB'">
                                <rect x="0" y="0" rx="5" ry="5" width="100%" height="180" />
                            </content-loader>
                        </div>
                        <div class="col-4">
                            <content-loader :speed="1" :viewBox="'0 0 100 60'" :background-color="'#FFFFFF'"
                                :border-radius="'10px'" :foreground-color="'#F5F5FB'">
                                <rect x="0" y="0" rx="5" ry="5" width="100%" height="180" />
                            </content-loader>
                        </div>
                        <div class="col-4">
                            <content-loader :speed="1" :viewBox="'0 0 100 60'" :background-color="'#FFFFFF'"
                                :border-radius="'10px'" :foreground-color="'#F5F5FB'">
                                <rect x="0" y="0" rx="5" ry="5" width="100%" height="180" />
                            </content-loader>
                        </div>
                    </div>
                    <div v-if="!organizationIsLoading && organization" class="row">
                        <div class="col-lg-4 col-md-6 col-sm-12">
                            <div class="card info-card">
                                <div class="card-body">
                                    <div class="card-content">
                                        <div class="row">
                                            <h5 class="header">General Info</h5>
                                        </div>
                                        <div class="row justify-content-between">
                                            <div>
                                                <h6 class="title"><img src="../../assets/icons/person-icon.svg" alt="">
                                                    Organization name</h6>
                                            </div>
                                            <div class="label">
                                                <h6 class="value">{{ organization.name }}</h6>
                                            </div>
                                        </div>
                                        <div class="row justify-content-between">
                                            <div>
                                                <h6 class="title"><img src="../../assets/icons/email-icon.svg" alt="">
                                                    Email</h6>
                                            </div>
                                            <div class="label">
                                                <h6 class="value">{{ organization.email }}</h6>
                                            </div>
                                        </div>
                                        <div class="row justify-content-between">
                                            <div>
                                                <h6 class="title"><img src="../../assets/icons/phone-icon.svg" alt="">
                                                    Phone</h6>
                                            </div>
                                            <div class="label">
                                                <h6 class="value">{{ organization.phoneNumber }}</h6>
                                            </div>
                                        </div>
                                        <div class="row justify-content-between">
                                            <div>
                                                <h6 class="title"><img src="../../assets/icons/phone-icon.svg" alt="">
                                                    Header Image</h6>
                                            </div>
                                            <div class="label">
                                                <h6 class="value"><a v-on:click="openHeaderImage()"
                                                    class="btn btn-link text-primary"><i class="pi pi-eye"></i></a></h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12">
                            <div class="card info-card">
                                <div class="card-body">
                                    <div class="card-content">
                                        <div class="row">
                                            <h5 class="header">Address</h5>
                                        </div>
                                        <div class="row justify-content-between">
                                            <div>
                                                <h6 class="title"><img src="../../assets/icons/location-pin-icon.svg"
                                                        alt="">
                                                    {{ organization.address1 != null ? organization.address1 : '-' }}
                                                </h6>
                                            </div>
                                        </div>
                                        <div class="row justify-content-between">
                                            <div>
                                                <h6 class="title"><img src="../../assets/icons/email-icon.svg" alt="">
                                                    City</h6>
                                            </div>
                                            <div class="label">
                                                <h6 class="value">{{ organization.city }}</h6>
                                            </div>
                                        </div>
                                        <div class="row justify-content-between">
                                            <div>
                                                <h6 class="title"><img src="../../assets/icons/phone-icon.svg" alt="">
                                                    State</h6>
                                            </div>
                                            <div class="label">
                                                <h6 class="value">{{ organization.state }}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12">
                            <div class="card info-card">
                                <div class="card-body">
                                    <div class="card-content">
                                        <div class="row">
                                            <h5 class="header">Other Info</h5>
                                        </div>
                                        <div class="row justify-content-between">
                                            <div>
                                                <h6 class="title"><img src="../../assets/icons/person-icon.svg" alt="">
                                                    Tax Id</h6>
                                            </div>
                                            <div class="label">
                                                <h6 class="value">{{ organization.taxId }}</h6>
                                            </div>
                                        </div>
                                        <div class="row justify-content-between">
                                            <div>
                                                <h6 class="title"><img src="../../assets/icons/person-icon.svg" alt="">
                                                    Created At</h6>
                                            </div>
                                            <div class="label">
                                                <h6 class="value">{{ this.$filters.getDateOnly(organization.createdAt)
                                                    }}</h6>
                                            </div>
                                        </div>
                                        <div class="row justify-content-between">
                                            <div>
                                                <h6 class="title"><img src="../../assets/icons/person-icon.svg" alt="">
                                                    Created By</h6>
                                            </div>
                                            <div class="label">
                                                <h6 v-if="organization.user" class="value">{{ organization.user.fname }}
                                                    {{
                                                    organization.user.lname }}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <br>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { ContentLoader } from 'vue-content-loader';

import sidenav from '@/components/Sidenav.vue'
import navbar from '@/components/Navbar.vue'
import organizationNavbar from '@/components/Organization_Navbar.vue'
import registerOrganizationModal from '@/components/modals/Organization/Register_Organization_Modal.vue'
import editOrganizationModal from '@/components/modals/Organization/Edit_Organization_Modal.vue'
// import deleteOrganizationModal from '@/components/modals/Delete_Organization_Modal.vue'
import headerImageUploadModal from '@/components/modals/Organization/Header_Image_Upload_Modal.vue'
import { GET_ORGANIZATION } from '@/store/action_types'
import { ORGANIZATION_DETAILS_ROUTE, ORGANIZATION_ACCOUNTS_ROUTE, ORGANIZATION_SCREENS_ROUTE } from '@/router/routes'

export default {
    title: 'Details',
    components: {
        sidenav,
        navbar,
        organizationNavbar,
        registerOrganizationModal,
        editOrganizationModal,
        headerImageUploadModal,
        // deleteOrganizationModal,
        // addOrganizationModal,
        ContentLoader
    },
    data() {
        return {
            ORGANIZATION_DETAILS_ROUTE,
            ORGANIZATION_ACCOUNTS_ROUTE,
            ORGANIZATION_SCREENS_ROUTE,
            showRegisterOrganizationModal: false,
            showEditOrganizationModal: false,
            showDeleteOrganizationModal: false,
            showHeaderImageUploadModal: false,
            selectedHeaderImage: null,
            selectedOrganization: null,
            selectedOrganizationId: null,
        }
    },
    computed: {
        route() {
            return this.$route.name
        },
        isLoading() {
            return this.$store.getters.organizations.isLoading
        },
        organizations() {
            return this.$store.getters.organizations.data
        },
        organizationIsLoading() {
            return this.$store.getters.organization.isLoading
        },
        organization() {
            return this.$store.getters.organization.data
        }
    },
    methods: {
        isOdd(number) {
            return number % 2 !== 0;
        },
        openHeaderImage() {
            this.selectedHeaderImage = this.organization.headerImage
            this.showHeaderImageUploadModal = true
        },
        selectOrganizationToEdit(organization) {
            this.selectedOrganization = this.organization
            this.showEditOrganizationModal = true
        },
        selectOrganizationToDelete(templateId) {
            this.selectedOrganizationId = templateId
            this.showDeleteOrganizationModal = true
        },
        getOrganization() {
            console.log('this.$router.params.id', this.$route.params.id)
            this.$store.dispatch(GET_ORGANIZATION, { id: this.$route.params.id })
        }
    },
    mounted() {
        this.getOrganization()
    }
}
</script>
<style scoped>
.content .card,
.content .card-body {
    padding: 0px;
}

.content .info-card {
    margin-bottom: 20px;
}

.content .card .card-header {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: #F4ECE2;
}

.content .card .header {
    font-weight: 700;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #1D1F2C;
}

.content .card .card-content {
    padding: 15px 35px 15px 35px;
}

.content .card .card-content>.row {
    margin-bottom: 7px
}

.content .card .card-content .title {
    font-weight: 600;
    color: #1D1F2C;
}

.content .card .card-content .title img {
    height: 40px !important;
    margin-right: 6px;
}

.content .card .card-content .value {
    font-weight: 600;
    color: #1D1F2C;
    margin-top: 6px
}

.content .btn-add {
    height: 40px;
    float: right;
    font-size: 14px;
    border-radius: 8px;
    color: #FFFFFF !important;
    padding: 10px, 14px, 10px, 14px;
    background-color: #646F80;
    border-color: #646F80 !important;
}

.content .card table small {
    margin-left: 10px;
    margin-right: 10px;
}

.content .card table .pi-trash,
.content .card table .pi-pencil {
    cursor: pointer;
}

/*.content .organization-nav .btn {
    color: #5A5A57;
    padding: 0px;
    border-radius: 0px;
    text-decoration: unset;
    margin-left: 10px;
    font-size: 18px;
}

.content .organization-nav .btn:hover,
.content .organization-nav .btn-selected {
    padding-bottom: 5px;
    color: #cf9904;
    border-bottom: 2px solid #cf9904;
}
*/

.content .organization-nav .card-body {
    padding: 15px !important;
}

.custom-col-width-2 {
    width: 16.66%;
}

.custom-col-width-10 {
    width: 83.3%;
}

@media (max-width: 1000px) {
    .custom-col-width-2 {
        width: 12%;
    }

    .custom-col-width-10 {
        width: 88%;
    }
}
</style>