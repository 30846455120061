<template>
    <div>
        <vue-final-modal :drag="false" drag-selector="" v-model="showModal" content-class="modal-content"
            classes="modal-container" focus-trap>
            <button class="modal__close btn btn-light" @click="$emit('closeAddAliasOfferingTypeModal')">
                <i class="pi pi-times"></i>
            </button>
            <span class="modal__title">Add Alias Offering Type</span>
            <br>
            <div class="modal__content">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <p v-if="errorMessage" class="text-center text-danger auth-status-msg">{{ errorMessage }}</p>
                                <Form @submit="registerAliasOfferingType">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label for="name">Name</label>
                                                <Field v-model="registerAliasOfferingTypeObj.name" rules="required" type="text"
                                                    name="name" class="form-control" id="name"
                                                    placeholder="Name" />
                                                <ErrorMessage name="name" class="form-text text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row justify-content-end modal__actions">
                                        <button class="col-12 btn btn-save" :disabled="aliasOfferingTypeIsLoading ? true : false">
                                            <span v-if="aliasOfferingTypeIsLoading" class="spinner-border spinner-border-sm"
                                                role="status" aria-hidden="true"></span>
                                            <span v-if="!aliasOfferingTypeIsLoading"> Save</span>
                                        </button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </vue-final-modal>
    </div>
</template>
<script>
import Quill from 'quill';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import { ADD_ALIAS_OFFERING_TYPE } from '@/store/action_types'
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'

import { Field, Form, ErrorMessage } from 'vee-validate';
import { defineRule } from 'vee-validate';
import { required, email, min } from '@vee-validate/rules';

defineRule('required', required);
defineRule('email', email);
defineRule('min', min);

export default {
    props: {
        organizationId: String
    },
    components: {
        VueFinalModal,
        ModalsContainer,
        Form,
        Field,
        ErrorMessage,
        QuillEditor
    },
    data() {
        return {
            showModal: true,
            registerAliasOfferingTypeObj: {
                name: null,
                org: null
            }
        }
    },
    computed: {
        errorMessage() {
            return this.$store.getters.errorMsg
        },
        aliasOfferingType() {
            return this.$store.getters.aliasOfferingType.data
        },
        aliasOfferingTypeIsLoading() {
            return this.$store.getters.aliasOfferingType.isLoading
        }
    },
    methods: {
        setValues() {
            this.registerAliasOfferingTypeObj.org = this.organizationId
        },
        registerAliasOfferingType() {
            this.$store.dispatch(ADD_ALIAS_OFFERING_TYPE, this.registerAliasOfferingTypeObj).then((result) => {
                if (result) {
                    this.$emit('closeAddAliasOfferingTypeModal')
                    this.$emit('refreshAliasOfferingTypes')
                }
            })
        },
    },
    mounted() {
        this.setValues()
    }
}
</script>
<style scoped>
::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    position: relative;
    left: 30%;
    margin-top: 60px;
}

::v-deep .modal-content {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.2rem;
    background: #fff;
}

.btn-save {
    background-color: #14243D;
}
</style>