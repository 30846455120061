import axios, { AxiosResponse } from 'axios'
import { SET_MODULE_SETTINGS, SET_MODULE_SETTING, SET_ERRORMSG } from '../mutation_types'
import {
    ADD_MODULE_SETTING,
    GET_MODULE_SETTINGS,
    CLEAR_MODULE_SETTINGS_ISLOADING
} from '../action_types'
import { StateObject } from '@/dtos/state.dto'
import ModuleSetting from '@/services/module_settings'
import { Module } from 'vuex'
import { RegisterModuleSettingBody, GetModuleSettingBody } from '@/dtos/module_settings.dto'

const moduleSetting: Module<any, {}> = {
    state: {
        moduleSetting: {
            data: null,
            isLoading: false
        },
        moduleSettings: {
            data: null,
            isLoading: false
        }
    },
    getters: {
        moduleSetting: (state: { moduleSetting: any }) => state.moduleSetting,
        moduleSettings: (state: { moduleSettings: any }) => state.moduleSettings
    },
    mutations: {
        [SET_MODULE_SETTING]: (state: { moduleSetting: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.moduleSetting.data = payload.data : null, state.moduleSetting.isLoading = payload.isLoading },
        [SET_MODULE_SETTINGS]: (state: { moduleSettings: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.moduleSettings.data = payload.data : null, state.moduleSettings.isLoading = payload.isLoading }
    },
    actions: {
        [ADD_MODULE_SETTING]: async({ commit }: any, payload: any) => {
            commit(SET_MODULE_SETTING, { data: null, isLoading: true })

            let result: AxiosResponse = await ModuleSetting.registerModuleSettings(payload as RegisterModuleSettingBody)
          
            if(result) {
                if (result.data.status) {
                    commit(SET_MODULE_SETTING, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_MODULE_SETTING, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_MODULE_SETTING, { isLoading: false })
                return false;
            }
        },
        [GET_MODULE_SETTINGS]: async({ commit }: any, payload: any) => {
            commit(SET_MODULE_SETTINGS, { data: null, isLoading: true })

            let result: AxiosResponse = await ModuleSetting.getModuleSettingss(payload as GetModuleSettingBody)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_MODULE_SETTINGS, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_MODULE_SETTINGS, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_MODULE_SETTINGS, { isLoading: false })
                return false;
            }
        },
        [CLEAR_MODULE_SETTINGS_ISLOADING]: ({ commit }) => {
            commit(SET_MODULE_SETTING, { isLoading: false })
            commit(SET_MODULE_SETTINGS, { isLoading: false })
        }
    }
}

export default moduleSetting