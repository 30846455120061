import baseAPI from '@/api/base';
import { EditParshat, AddParshat, GetParshat } from '../dtos/parshat.dto';
import { DELETE_PARSHAT_URL, PARSHAT_URL, EDIT_PARSHAT_URL, ADD_PARSHAT_URL } from '@/utils/urls';
import { AxiosResponse } from 'axios';

class Parshat {
    static getParshats = async(getParshat: GetParshat) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(PARSHAT_URL, {
                    params: getParshat
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static registerParshat = async(addParshat: AddParshat) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(ADD_PARSHAT_URL, {
                    ...addParshat
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static editParshat = async(editParshat: EditParshat) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.put(EDIT_PARSHAT_URL, {
                    ...editParshat
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static deleteParshat = async(donationId: string) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.delete(`${DELETE_PARSHAT_URL}/${donationId}`)

                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }
}

export default Parshat