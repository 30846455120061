<template>
    <div>
        <vue-final-modal :drag="false" drag-selector="" v-model="showModal" content-class="modal-content"
            classes="modal-container" focus-trap>
            <button class="modal__close btn btn-light" @click="$emit('closeInvoiceItemsModal')">
                <i class="pi pi-times"></i>
            </button>
            <span class="modal__title">Statement Details</span>
            <br>
            <div class="modal__content">
                <payInvoiceModal v-if="showPayInvoiceModal" :selectedMemberId="selectedMemberId"
                    :selectedInvoiceId="selectedInvoiceId" @close-pay-invoice-modal="showPayInvoiceModal = false"
                    @refresh-invoices="refreshInvoices()" />
                <p v-if="errorMessage" class="text-center text-danger auth-status-msg">{{ errorMessage }}</p>
                <div class="row">
                    <div class="col-12">
                        <h6 v-if="invoiceItemDetailsIsLoading" class="text-center">Loading...</h6>
                        <div v-else class="card">
                            <div class="card-body">
                                <div v-if="invoiceItemDetails" class="row justify-content-between">
                                    <div class="col-2">
                                        <button v-if="currentTab == INVOICE_ITEM_MODAL_ITEMS_TAB"
                                            v-on:click="switchTab(INVOICE_ITEM_MODAL_PAYMENTS_TAB)"
                                            class="btn btn-view-payments">View Payments</button>
                                        <button v-if="currentTab == INVOICE_ITEM_MODAL_PAYMENTS_TAB"
                                            v-on:click="switchTab(INVOICE_ITEM_MODAL_ITEMS_TAB)" class="btn btn-back"><i
                                                class="pi pi-chevron-left"></i> Back</button>
                                    </div>
                                    <div class="col-6">
                                        <div class="row">
                                            <div class="col-10">
                                                <h5>Payments: <span class="unpaid text-primary">{{
                                                        $filters.formatPrice(invoiceItemDetails.totalAmount,
                                                        selectedInvoice.currency) }}</span> |
                                                    <span class="paid">{{
                                                        $filters.formatPrice(invoiceItemDetails.totalAmountPaid,
                                                        selectedInvoice.currency) }}</span> |
                                                    <span class="unpaid">{{
                                                        $filters.formatPrice(invoiceItemDetails.totalAmount
                                                        -
                                                        invoiceItemDetails.totalAmountPaid,
                                                        selectedInvoice.currency)
                                                        }}</span>
                                                </h5>
                                            </div>
                                            <div class="col-2">
                                                <button v-on:click="showPayInvoiceModal = true"
                                                    class="btn btn-pay">PAY</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr>
                                <div v-if="currentTab == INVOICE_ITEM_MODAL_ITEMS_TAB">
                                    <table v-if="invoiceItemDetails" class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Price</th>
                                                <th scope="col">Quantity</th>
                                                <th scope="col">Total</th>
                                                <th scope="col">Due Date</th>
                                                <!-- <th scope="col">Status</th> -->
                                                <!-- <th scope="col">Created At</th> -->
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-bind:key="index"
                                                v-for="(item, index) in invoiceItemDetails.invoiceItems">
                                                <tr :class="[isOdd(index + 1) ? 'striped' : null]">
                                                    <th scope="row">{{ index + 1 }}</th>
                                                    <td>{{ item.name }}</td>
                                                    <td>{{ $filters.formatPrice(item.price, selectedInvoice.currency) }}
                                                    </td>
                                                    <td>{{ item.quantity }}</td>
                                                    <td>{{ $filters.formatPrice(item.price * item.quantity,
                                                        selectedInvoice.currency) }}</td>
                                                    <td>{{ $filters.getDateOnly(item.dueDate) }}</td>
                                                    <!-- <td>
                                                        <span
                                                            v-if="!(invoiceItem != null && invoiceItemIsLoading && invoiceItem.id == item.id)"
                                                            v-on:click="toggleItemIsPaid(item.id)"
                                                            :class="[ item.isPaid ? 'badge-active' : 'badge-inactive', 'badge text-center']">{{
                                                            item.isPaid ? 'Paid' : 'Unpaid' }}</span>
                                                        <span v-else class="spinner-border spinner-border-sm" role="status"
                                                            aria-hidden="true"></span>
                                                    </td> -->
                                                    <!-- <td>{{ $filters.getDateOnly(item.createdAt) }}</td> -->
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                                <div v-if="currentTab == INVOICE_ITEM_MODAL_PAYMENTS_TAB">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Invoice #</th>
                                                <th scope="col">Invoice Item</th>
                                                <th scope="col">Amount Paid</th>
                                                <th scope="col">Payment Method</th>
                                                <th scope="col">Payment Date</th>
                                                <th scope="col">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-bind:key="index"
                                                v-for="(paymentLog, index) in singleInvoiceItemPaymentLogs">
                                                <tr :class="[isOdd(index + 1) ? 'striped' : null]">
                                                    <th scope="row">{{ index + 1 }}</th>
                                                    <td><span v-if="paymentLog.invoice != null">{{
                                                            paymentLog.invoice.invoiceNumber }}</span></td>
                                                    <td><span v-if="paymentLog.invoice != null">{{
                                                            paymentLog.invoiceItem.name }}, <strong
                                                                class="text-secondary">{{
                                                                $filters.formatPrice((paymentLog.invoiceItem.price *
                                                                paymentLog.invoiceItem.quantity),
                                                                paymentLog.invoice.currency) }}</strong></span></td>
                                                    <td><span v-if="paymentLog.invoice != null">
                                                            {{ $filters.formatPrice(paymentLog.amountPaid,
                                                            paymentLog.invoice.currency) }}
                                                        </span></td>
                                                    <td>{{ paymentLog.paymentMethod }} {{ paymentLog.paymentMethod ==
                                                        PAYMENT_METHODS[3] ? ` - ${paymentLog.checkNumber}` : '' }}</td>
                                                    <td>{{ $filters.getDateOnly(paymentLog.createdAt) }}</td>
                                                    <td>

                                                        <i v-on:click="selectInvoiceToDelete(invoice.id)"
                                                            class="pi pi-trash text-danger"></i>
                                                    </td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <!-- <div class="row">
                    <div class="col-lg-2 offset-lg-10">
                        <button v-on:click="$emit('closeInvoiceItemsModal')" class="btn btn-close">
                            <img src="../../../assets/icons/close-icon.svg" alt=""> Close
                        </button>
                    </div>
                </div> -->
            </div>
        </vue-final-modal>
    </div>
</template>
<script>
import { GET_INVOICE_ITEM_DETAILS, GET_SINGLE_INVOICE_ITEM_PAYMENT_LOGS, TOGGLE_INVOICE_ITEM_ISPAID } from '@/store/action_types'
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'

import { Field, Form, ErrorMessage } from 'vee-validate';
import { defineRule } from 'vee-validate';
import { required, email, min } from '@vee-validate/rules';

import payInvoiceModal from '@/components/modals/Invoice/Pay_Invoice_Modal.vue'
import { INVOICE_ITEM_MODAL_ITEMS_TAB, INVOICE_ITEM_MODAL_PAYMENTS_TAB } from '@/utils/const';
import { PAYMENT_METHODS } from '@/utils/const';

defineRule('required', required);
defineRule('email', email);
defineRule('min', min);

export default {
    props: {
        selectedInvoice: String
    },
    components: {
        payInvoiceModal,
        VueFinalModal,
        ModalsContainer,
        Form,
        Field,
        ErrorMessage
    },
    data() {
        return {
            INVOICE_ITEM_MODAL_ITEMS_TAB,
            INVOICE_ITEM_MODAL_PAYMENTS_TAB,
            PAYMENT_METHODS,
            showModal: true,
            showPayInvoiceModal: false,
            selectedMemberId: null,
            selectedInvoiceId: null,
            currentTab: INVOICE_ITEM_MODAL_ITEMS_TAB
        }
    },
    computed: {
        errorMessage() {
            return this.$store.getters.errorMsg
        },
        invoiceItem() {
            return this.$store.getters.invoiceItem.data
        },
        invoiceItemIsLoading() {
            return this.$store.getters.invoiceItem.isLoading
        },
        invoiceItemDetails() {
            return this.$store.getters.invoiceItemDetails.data
        },
        invoiceItemDetailsIsLoading() {
            return this.$store.getters.invoiceItemDetails.isLoading
        },
        singleInvoiceItemPaymentLogs() {
            return this.$store.getters.singleInvoiceItemPaymentLogs.data
        },
        singleInvoiceItemPaymentLogsIsLoading() {
            return this.$store.getters.singleInvoiceItemPaymentLogs.isLoading
        }
    },
    methods: {
        isOdd(number) {
            return number % 2 !== 0;
        },
        setValues() {
            this.selectedMemberId = this.selectedInvoice.memb
            this.selectedInvoiceId = this.selectedInvoice.id
        },
        switchTab(tab) {
            this.currentTab = tab
        },
        toggleItemIsPaid(itemId) {
            this.$store.dispatch(TOGGLE_INVOICE_ITEM_ISPAID, { id: itemId })
        },
        getSingleInvoiceItemPaymentLogs() {
            this.$store.dispatch(GET_SINGLE_INVOICE_ITEM_PAYMENT_LOGS, { inv: this.selectedInvoice.id })
        },
        getInvoiceItems() {
            this.$store.dispatch(GET_INVOICE_ITEM_DETAILS, { inv: this.selectedInvoice.id })
        },
        refreshInvoices() {
            this.getInvoiceItems()
            this.getSingleInvoiceItemPaymentLogs()
        }
    },
    mounted() {
        this.setValues()
        this.getInvoiceItems()
        this.getSingleInvoiceItemPaymentLogs()
    }
}
</script>
<style scoped>
::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    position: relative;
    left: 20%;
    margin-top: 50px;
}

::v-deep .modal-content {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.2rem;
    background: #fff;
    max-height: 80vh;
    overflow-y: auto;
}
    
::v-deep .modal__content {
    max-height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.modal__title {
    font-size: 18px;
    font-weight: 600;
}

.modal__close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
}

.modal__content .nav-tabs .nav-link {
    font-size: 15px !important;
}

.modal__content .badge {
    cursor: pointer;
}

.modal__content .badge-active {
    font-size: 12px;
    color: #1A9882;
    padding: 4px, 12px, 4px, 12px;
    background-color: #E9FAF7;
}

.card-body .badge-inactive {
    font-size: 12px;
    color: #dc3545;
    padding: 4px, 12px, 4px, 12px;
    background-color: #fae9eb;
}

.btn-pay {
    color: #5E84C2;
    font-weight: 700;
    letter-spacing: 0.7px;
    background-color: #E4EBFC;
    border: 1px solid #CCDCF7;
    position: relative;
    bottom: 7px;
    right: 15px;
}

.btn-back {
    font-weight: 600;
    color: #5D84C2;
}

.btn-back .pi {
    font-size: 13px;
    margin-right: 6px;
}

.unpaid {
    color: #dc3545;
    margin-left: 5px;
    margin-right: 5px;
}

.paid {
    color: #1A9882;
    margin-left: 5px;
}

.btn-view-payments {
    font-weight: 600;
    color: #5D84C2;
    position: relative;
}
</style>