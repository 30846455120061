<template>
    <div class="dashboard-body">
        <div class="row">
            <sidenav class="custom-col-width-2" />
            <div style="padding-left: 0px!important" class="custom-col-width-10 content-section dashboard-content">
                <navbar />
                <addPurposeModal v-if="showAddPurposeModal" :selectedMemberId="selectedMemberId"
                    :selectedOrgId="selectedOrgId" @close-add-purpose-modal="showAddPurposeModal = false"
                    @refresh-purposes="getMemberPurposes()" />
                <editPurposeModal v-if="showEditPurposeModal" :selectedPurpose="selectedPurpose"
                    @close-edit-purpose-modal="showEditPurposeModal = false" @refresh-purposes="getMemberPurposes()" />
                <deletedPurposeModal v-if="showDeletePurposeModal" :selectedPurposeId="selectedPurposeId"
                    @close-delete-purpose-modal="showDeletePurposeModal = false"
                    @refresh-purposes="getMemberPurposes()" />
                <div class="content">
                    <br>
                    <div class="row button-grp justify-content-between">
                        <div class="col">
                            <h4>Member <small v-if="route.params.name">({{ route.params.name }})</small></h4>
                        </div>
                        <div class="col">
                            <button v-on:click="showAddPurposeModal = true" class="btn btn-add"><i
                                    class="pi pi-plus"></i>
                                Add Purpose</button>
                        </div>
                    </div>
                    <div class="row justify-content-between">
                        <memberNavbar class="" />
                    </div>
                    <br>
                    <div v-if="memberPurposesIsLoading && memberPurposes == null">
                        <div class="row">
                            <div class="col-10 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#DFDFFAFF'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-10 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-10 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-10 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-10 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-10 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-10 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-10 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-10 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                        </div>
                    </div>
                    <div v-else class="row">
                        <div class="col-10">
                            <div class="card">
                                <div class="card-body">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Purpose</th>
                                                <th scope="col">Date</th>
                                                <th scope="col">Reminder Date</th>
                                                <th scope="col">Recurring Annually</th>
                                                <!-- <th scope="col">Date (Gregorian)</th> -->
                                                <!-- <th scope="col">Created At</th> -->
                                                <th scope="col">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-bind:key="index" v-for="(purpose, index) in memberPurposes">
                                                <tr :class="[isOdd(index + 1) ? 'striped' : null]">
                                                    <th scope="row">{{ index + 1 }}</th>
                                                    <td>{{ purpose.name }}</td>
                                                    <td><span class="frankRuhl-font">{{
                                                            $filters.convertToHebrewDate(purpose.eventDate) }}</span>
                                                    </td>
                                                    <td><span class="frankRuhl-font">{{
                                                            $filters.convertToHebrewDateDayAndMonth(purpose.reminderDate)
                                                            }}</span></td>
                                                    <td>
                                                        <span v-if="purpose.isRecurringAnnually"
                                                            class="badge badge-active text-center">YES</span>
                                                        <span v-else class="badge badge-inactive text-center">NO</span>
                                                    </td>
                                                    <!-- <td>{{ $filters.getDateOnly(purpose.eventDate) }}</td> -->
                                                    <!-- <td>{{ $filters.convertToHebrewDate(purpose.createdAt) }}</td> -->
                                                    <td>
                                                        <i v-on:click="selectPurposeToEdit(purpose)"
                                                            class="pi pi-pencil text-primary"></i>
                                                        <i v-on:click="selectPurposeToDelete(purpose.id)"
                                                            class="pi pi-trash text-danger"></i>
                                                    </td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <br>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { ContentLoader } from 'vue-content-loader';

import sidenav from '@/components/Sidenav.vue'
import navbar from '@/components/Navbar.vue'
import memberNavbar from '@/components/Member_Navbar.vue'
import addPurposeModal from '@/components/modals/Purpose/Add_Purpose_Modal.vue'
import editPurposeModal from '@/components/modals/Purpose/Edit_Purpose_Modal.vue'
import deletedPurposeModal from '@/components/modals/Purpose/Delete_Purpose_Modal.vue'
import { GET_MEMBER_PURPOSES } from '@/store/action_types'

export default {
    title: 'Details',
    components: {
        sidenav,
        navbar,
        memberNavbar,
        addPurposeModal,
        editPurposeModal,
        deletedPurposeModal,
        ContentLoader
    },
    data() {
        return {
            showAddPurposeModal: false,
            showEditPurposeModal: false,
            showDeletePurposeModal: false,
            selectedMemberId: null,
            selectedOrgId: null,
            selectedPurpose: null,
            selectedPurposeId: null
        }
    },
    computed: {
        route() {
            return this.$route
        },
        memberPurposesIsLoading() {
            return this.$store.getters.memberPurposes.isLoading
        },
        memberPurposes() {
            return this.$store.getters.memberPurposes.data
        }
    },
    methods: {
        isOdd(number) {
            return number % 2 !== 0;
        },
        setValues() {
            this.selectedMemberId = this.$route.params.membId
            this.selectedOrgId = this.$route.params.orgId
        },
        selectPurposeToEdit(purpose) {
            this.selectedPurpose = purpose
            this.showEditPurposeModal = true
        },
        selectPurposeToDelete(purposeId) {
            this.selectedPurposeId = purposeId
            this.showDeletePurposeModal = true
        },
        getMemberPurposes() {
            this.$store.dispatch(GET_MEMBER_PURPOSES, { memb: this.$route.params.membId })
        }
    },
    mounted() {
        this.setValues()
        this.getMemberPurposes()
    }
}
</script>
<style scoped>
.content .card,
.content .card-body {
    padding: 0px;
}

.content .info-card {
    margin-bottom: 20px;
}

.content .card .card-header {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: #F4ECE2;
}

.content .card .header {
    font-weight: 700;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #1D1F2C;
}

.content .card .card-content {
    padding: 15px 35px 15px 35px;
}

.content .card .card-content>.row {
    margin-bottom: 7px
}

.content .card .card-content .title {
    font-weight: 600;
    color: #1D1F2C;
}

.content .card .card-content .title img {
    height: 40px !important;
    margin-right: 6px;
}

.content .card .card-content .value {
    font-weight: 600;
    color: #1D1F2C;
    margin-top: 6px
}

.content .card table small {
    margin-left: 10px;
    margin-right: 10px;
}

.content .card table .pi-trash,
.content .card table .pi-pencil {
    cursor: pointer;
}

/*.content .organization-nav .btn {
    color: #5A5A57;
    padding: 0px;
    border-radius: 0px;
    text-decoration: unset;
    margin-left: 10px;
    font-size: 18px;
}

.content .organization-nav .btn:hover,
.content .organization-nav .btn-selected {
    padding-bottom: 5px;
    color: #cf9904;
    border-bottom: 2px solid #cf9904;
}
*/

.content .organization-nav .card-body {
    padding: 15px !important;
}

.custom-col-width-2 {
    width: 16.66%;
}

.custom-col-width-10 {
    width: 83.3%;
}

@media (max-width: 1000px) {
    .custom-col-width-2 {
        width: 12%;
    }

    .custom-col-width-10 {
        width: 88%;
    }
}
</style>