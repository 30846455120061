<template>
    <div>
        <vue-final-modal :drag="false" drag-selector="" v-model="showModal" content-class="modal-content"
            classes="modal-container" focus-trap>
            <button class="modal__close btn btn-light" @click="$emit('closeAddShiurimModal')">
                <i class="pi pi-times"></i>
            </button>
            <span class="modal__title">Add Shiurim</span>
            <br>
            <div class="modal__content">
                <JewishDatePicker v-if="currentlyShowingJewDatePicker == DATE_PICKER_SHIURIM_DISPLAY_START_DATE"
                    @select-date="selectShiurimDisplayStartDate" @close-date-picker-modal="closeModal()" />
                <JewishDatePicker v-if="currentlyShowingJewDatePicker == DATE_PICKER_SHIURIM_DISPLAY_END_DATE"
                    @select-date="selectShiurimDisplayEndDate" @close-date-picker-modal="closeModal()" />
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <p v-if="errorMessage" class="text-center text-danger auth-status-msg">{{ errorMessage
                                    }}</p>
                                <Form @submit="addShiurim">
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label for="title">Title</label>
                                                <Field v-model="addShiurimObj.title" rules="required" type="text"
                                                    name="Title" class="form-control" id="title" placeholder="Title" />
                                                <ErrorMessage name="title" class="form-text text-danger" />
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label for="amount">Screen</label>
                                                <select v-model="addShiurimObj.scr" class="form-control"
                                                    rules="required">
                                                    <option>---</option>
                                                    <template v-bind:key="index" v-for="(screen, index) in screens">
                                                        <option :value="screen">{{ screen.name }}</option>
                                                    </template>
                                                </select>
                                                <ErrorMessage name="amount" class="form-text text-danger" />
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div @click="currentlyShowingJewDatePicker = DATE_PICKER_SHIURIM_DISPLAY_START_DATE"
                                                class="form-group">
                                                <label for="shiurimDisplayStartDate">Display Start Date</label>
                                                <div class="row date-picker-row">
                                                    <div class="col-10">
                                                        <Field v-model="shiurimDisplayStartDate" rules="required"
                                                            disabled type="text" name="shiurimDisplayStartDate"
                                                            class="form-control" id="shiurimDisplayStartDate"
                                                            placeholder="Shiurim Display Start Date" />
                                                    </div>
                                                    <div class="col-2">
                                                        <i class="pi pi-calendar-plus text-primary"></i>
                                                    </div>
                                                </div>
                                                <ErrorMessage name="shiurimDisplayStartDate"
                                                    class="form-text text-danger" />
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div @click="currentlyShowingJewDatePicker = DATE_PICKER_SHIURIM_DISPLAY_END_DATE"
                                                class="form-group">
                                                <label for="shiurimDisplayEndDate">Display End Date</label>
                                                <div class="row date-picker-row">
                                                    <div class="col-10">
                                                        <Field v-model="shiurimDisplayEndDate" rules="required" disabled
                                                            type="text" name="shiurimDisplayEndDate"
                                                            class="form-control" id="shiurimDisplayEndDate"
                                                            placeholder="Shiurim Display End Date" />
                                                    </div>
                                                    <div class="col-2">
                                                        <i class="pi pi-calendar-plus text-primary"></i>
                                                    </div>
                                                </div>
                                                <ErrorMessage name="shiurimDisplayEndDate"
                                                    class="form-text text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                    <label for="shiurim">Shiurim</label>
                                    <QuillEditor ref="myQuillEditor" :options="editorOption" style="height: 250px;">
                                    </QuillEditor>
                                    <br>
                                    <div class="row justify-content-end modal__actions">
                                        <button class="col-12 btn btn-save" :disabled="shiurimIsLoading ? true : false">
                                            <span v-if="shiurimIsLoading" class="spinner-border spinner-border-sm"
                                                role="status" aria-hidden="true"></span>
                                            <span v-if="!shiurimIsLoading"> Save</span>
                                        </button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </vue-final-modal>
    </div>
</template>
<script>
import Quill from 'quill';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import { ADD_SHIURIM, GET_SCREENS } from '@/store/action_types'
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'

import { Field, Form, ErrorMessage } from 'vee-validate';
import { defineRule } from 'vee-validate';
import { required, email, min } from '@vee-validate/rules';

import JewishDatePicker from '@/components/JewishDatePicker.vue';
import { DATE_PICKER_SHIURIM_DISPLAY_START_DATE, DATE_PICKER_SHIURIM_DISPLAY_END_DATE } from '@/utils/const';

defineRule('required', required);
defineRule('email', email);
defineRule('min', min);

export default {
    props: {
        organizationId: String
    },
    components: {
        JewishDatePicker,
        VueFinalModal,
        ModalsContainer,
        Form,
        Field,
        ErrorMessage,
        QuillEditor
    },
    data () {
        return {
            DATE_PICKER_SHIURIM_DISPLAY_START_DATE,
            DATE_PICKER_SHIURIM_DISPLAY_END_DATE,
            showModal: true,
            addShiurimObj: {
                title: null,
                message: null,
                scr: null,
                org: null,
                shiurimDisplayStartDate: null,
                shiurimDisplayEndDate: null
            },
            shiurimDisplayStartDate: null,
            shiurimDisplayEndDate: null,
            currentlyShowingJewDatePicker: null,
            editorOption: {
                modules: {
                    toolbar: [
                        [{ 'font': ['TzlilimPM', 'Pyutim'] }],
                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
                        [{ 'direction': 'rtl' }],                         // text direction
                        [{ 'align': [] }],
                        ['link', 'image', 'video', 'formula'],            // add's image support
                        ['clean']
                    ]
                }
            }
        }
    },
    computed: {
        errorMessage() {
            return this.$store.getters.errorMsg
        },
        screens() {
            return this.$store.getters.screens.data
        },
        screensIsLoading() {
            return this.$store.getters.screens.isLoading
        },
        shiurim() {
              return this.$store.getters.shiurim.data
        },
        shiurimIsLoading() {
            return this.$store.getters.shiurim.isLoading
        }
    },
    methods: {
        setValues() {
            this.addShiurimObj.org = this.organizationId
        },
        registerFonts() {
            const Font = Quill.import('formats/font');
            Font.whitelist = ['TzlilimPM', 'Pyutim']; // add custom fonts to the whitelist
            Quill.register(Font, true);
        },
        closeModal() {
            this.currentlyShowingJewDatePicker = null;
        },
        selectShiurimDisplayStartDate(selectedDate) {
            this.addShiurimObj.shiurimDisplayStartDate = this.$filters.convertHDateToGregorian(selectedDate)
            this.shiurimDisplayStartDate = this.$filters.convertToHebrewDate(this.addShiurimObj.shiurimDisplayStartDate)
            this.closeModal()
        },
        selectShiurimDisplayEndDate(selectedDate) {
            this.addShiurimObj.shiurimDisplayEndDate = this.$filters.convertHDateToGregorian(selectedDate)
            this.shiurimDisplayEndDate = this.$filters.convertToHebrewDate(this.addShiurimObj.shiurimDisplayEndDate)
            this.closeModal()
        },
        getScreens() {
            this.$store.dispatch(GET_SCREENS)
        },
        addShiurim() {   
            this.addShiurimObj.message = this.$refs.myQuillEditor.getHTML()

            this.$store.dispatch(ADD_SHIURIM, this.addShiurimObj).then((result) => {
                if(result) {
                    this.$emit('closeAddShiurimModal')
                    this.$emit('refreshShiurims')
                }
            })           
        },
    },
    mounted() {
        this.setValues()
        this.getScreens()
        this.$nextTick(() => {
            if (this.$refs.myQuillEditor && this.$refs.myQuillEditor.getQuill) {
                console.log("Quill instance:", this.$refs.myQuillEditor.getQuill());
            } else {
                console.error("Quill editor is not available.");
            }
        });
        this.registerFonts()
    }
}
</script>
<style scoped>
::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    position: relative;
    left: 25%;
    margin-top: 60px;
}
::v-deep .modal-content {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.2rem;
    background: #fff;
}
.modal__title {
    font-size: 18px;
    font-weight: 600;
}
.modal__close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
}

.modal__content {
}

.modal__content .nav-tabs .nav-link {
    font-size: 15px!important;
}

.btn-save {
    background-color: #14243D;
}

.btn-close {
    border-radius: 10px;
    font-size: 13px;
    font-weight: 600;
    width: 75%;
    background-color: #CAB781;

}
.appointment-card {
    margin-top: 5px;
}

.appointment-card .card-body {
    padding: 0px 5px 0px 5px;
}

.appointment-card .customer {
    font-size: 15px;
}
.appointment-card .badge {
    margin-left: 10px;
    margin-right: 5px;
}

label {
    font-size: 13px;
}

input {
    height: 35px!important;
    font-size: 13px;
}

input::placeholder {
    font-size: 13px;
}

select {
    height: 35px!important;
    font-size: 13px;
}

select::placeholder {
    font-size: 13px;
}
.form-check {
    position: relative;
    display: block;
    margin-top: 25px;
    margin-bottom: 0.75rem;
    padding-left: 0px;
  }
  
  .form-check-toggle {
    position: relative;
    padding-left: 0;
    line-height: 30px;
  }
  .form-check-toggle input {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 0%;
    margin: 0;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
  }
  .form-check-toggle input + span {
    cursor: pointer;
    shiurim-select: none;
    height: 30px;
    margin-left: 70px;
    display: block;
  }
  .form-check-toggle input + span:before {
    content: "";
    position: absolute;
    left: 0;
    display: inline-block;
    height: 30px;
    width: 50px;
    background: #F0F5FC;
    border: solid 1px #D4D3D3;
    transition: background 0.3s ease-in-out, border-color 0.3s ease-in-out;
    border-radius: 7px;
  }
  .form-check-toggle input + span:after {
    width: 28px;
    height: 28px;
    margin-top: 1px;
    margin-left: 1px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    background: #fff;
    transition: margin-left 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    text-align: center;
    font-weight: bold;
    content: "";
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2), 0 0 5px rgba(0, 0, 0, 0.05);
  }
  .form-check-toggle input:checked + span:after {
    content: "";
    margin-left: 21px;
    box-shadow: none;
  }
  .form-check-toggle input:checked + span:before {
    background-color: #CAB781;
    border-color: #CAB781;
    transition: background 0.3s ease-in-out, border-color 0.3s ease-in-out;
  }

  .content-row {
    margin-bottom: 8px;
  }

  .button-insert-var {
    font-size: 12px;
    padding: 3px 8px 3px 8px;
  }

  .dropdown-item {
    font-size: 12px;
  }
</style>