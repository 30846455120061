<template>
    <div>
        <vue-final-modal :drag="false" drag-selector="" v-model="showModal" content-class="modal-content"
            classes="modal-container" focus-trap>
            <button class="modal__close btn btn-light" @click="$emit('closeCloseTicketRemarkModal')">
                <i class="pi pi-times"></i>
            </button>
            <span class="modal__title">Close TIcket</span>
            <br>
            <div class="modal__content">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <p v-if="errorMessage" class="text-center text-danger auth-status-msg">{{ errorMessage }}</p>
                                <Form @submit="closeTicket">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label for="comment">Remark</label>
                                                <Field v-slot="{ field, errors }"
                                                    v-model="closeTicketCommentObj.comment" rules="required"
                                                    name="details" class="form-control">
                                                    <textarea v-bind="field" name="comment" class="form-control"
                                                        rows="10" placeholder="Enter your remark...." />
                                                    <ErrorMessage name="comment" class="form-text text-danger" />
                                                </Field>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <button type="submit" class="col-2 btn btn-save btn-danger">
                                            <span v-if="ticketCommentIsLoading" class="spinner-border spinner-border-sm"
                                                role="status" aria-hidden="true"></span>
                                            <span v-if="!ticketCommentIsLoading">Close</span>
                                        </button>
                                        <a v-on:click="closeModal()"
                                            class="col-2 btn btn-add-comment btn-cancel">Cancel</a>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </vue-final-modal>
    </div>
</template>
<script>
import { ADD_TICKET_COMMENT } from '@/store/action_types'
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'

import { Field, Form, ErrorMessage } from 'vee-validate';
import { defineRule } from 'vee-validate';
import { required, email, min } from '@vee-validate/rules';
import { TICKET_COMMENT_TYPES } from '@/utils/const';

defineRule('required', required);
defineRule('email', email);
defineRule('min', min);

export default {
    props: {
        selectedTicketId: String
    },
    components: {
        VueFinalModal,
        ModalsContainer,
        Form,
        Field,
        ErrorMessage,
    },
    data() {
        return {
            showModal: true,
            closeTicketCommentObj: {
                comment: null,
                type: null,
                tikt: null
            },
        }
    },
    computed: {
        errorMessage() {
            return this.$store.getters.errorMsg
        },
        ticketComment() {
            return this.$store.getters.ticketComment.data
        },
        ticketCommentIsLoading() {
            return this.$store.getters.ticketComment.isLoading
        }
    },
    methods: {
        setValues() {
            this.closeTicketCommentObj.tikt = this.selectedTicketId
        },
        closeModal() {
            this.$emit('closeCloseTicketRemarkModal')
        },
        closeTicket() {
            this.closeTicketCommentObj.type = TICKET_COMMENT_TYPES[0]
            this.$store.dispatch(ADD_TICKET_COMMENT, this.closeTicketCommentObj).then((res) => {
                if (res) {
                    this.closeTicketCommentObj.comment = null
                    this.$emit('closeCloseTicketRemarkModal')
                    this.$emit('refreshTicketComments')
                }
            })
        },
    },
    mounted() {
        this.setValues()
    }
}
</script>
<style scoped>
::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    position: relative;
    left: 30%;
    margin-top: 60px;
}

::v-deep .modal-content {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.2rem;
    background: #fff;
}

.btn-save {
    background-color: #dc3545;
}
</style>