<template>
    <div>
        <vue-final-modal :drag="false" drag-selector="" v-model="showModal" content-class="modal-content"
            classes="modal-container" focus-trap>
            <button class="modal__close btn btn-light" @click="$emit('closeViewNotificationMemberModal')">
                <i class="pi pi-times"></i>
            </button>
            <span class="modal__title">Member Details</span>
            <br>
            <div class="modal__content">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="row justify-content-between">
                                    <div class="col">
                                        <h6>Name:</h6>
                                    </div>
                                    <div class="col">
                                        <h6>{{ selectedMemberToView.fname }} {{ selectedMemberToView.lname }}</h6>
                                    </div>
                                </div>
                                <div class="row justify-content-between">
                                    <div class="col">
                                        <h6>Email:</h6>
                                    </div>
                                    <div class="col">
                                        <h6>{{ selectedMemberToView.email }}</h6>
                                    </div>
                                </div>
                                <div class="row justify-content-between">
                                    <div class="col">
                                        <h6>Phone Number:</h6>
                                    </div>
                                    <div class="col">
                                        <h6>{{ selectedMemberToView.phoneNumber }}</h6>
                                    </div>
                                </div>
                                <div class="row justify-content-between">
                                    <div class="col">
                                        <h6>Address:</h6>
                                    </div>
                                    <div class="col">
                                        <h6>{{ selectedMemberToView.address ?? '-' }}</h6>
                                    </div>
                                </div>
                                <div class="row justify-content-between">
                                    <div class="col">
                                        <h6>Created At:</h6>
                                    </div>
                                    <div class="col">
                                        <h6>{{ this.$filters.getDateOnly(selectedMemberToView.createdAt) }}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
            </div>
        </vue-final-modal>
    </div>
</template>
<script>
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'

import { Field, Form, ErrorMessage } from 'vee-validate';
import { defineRule } from 'vee-validate';
import { required, email, min } from '@vee-validate/rules';

defineRule('required', required);
defineRule('email', email);
defineRule('min', min);

export default {
    props: {
        selectedMemberToView: Object,
    },
    components: {
        VueFinalModal,
        ModalsContainer,
        Form,
        Field,
        ErrorMessage
    },
    data() {
        return {
            showModal: true
        }
    },
    watch: {
    },
    computed: {
        errorMessage() {
            return this.$store.getters.errorMsg
        }
    },
    methods: {
        
    },
    created() {
    }
}
</script>
<style scoped>
::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    position: relative;
    left: 30%;
    margin-top: 50px;
}

::v-deep .modal-content {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.2rem;
    background: #fff;
}
</style>