import axios, { AxiosResponse } from 'axios'
import { SET_PLEDGE_ITEM_DELETION_REASONS, SET_PLEDGE_ITEM_DELETION_REASON, SET_ERRORMSG } from '../mutation_types'
import {
    GET_PLEDGE_ITEM_DELETION_REASON,
    ADD_PLEDGE_ITEM_DELETION_REASON,
    CLEAR_PLEDGE_ITEM_DELETION_REASONS_ISLOADING
} from '../action_types'
import { StateObject } from '@/dtos/state.dto'
import PledgeItemDeletionReason from '@/services/pledge_item_deletion_reason'
import { Module } from 'vuex'
import { AddPledgeItemDeletionReason, GetPledgeItemDeletionReason } from '@/dtos/pledge_item_deletion_reason.dto'

const pledgeItemDeletionReason: Module<any, {}> = {
    state: {
        pledgeItemDeletionReason: {
            data: null,
            isLoading: false
        },
        pledgeItemDeletionReasons: {
            data: null,
            isLoading: false
        }
    },
    getters: {
        pledgeItemDeletionReason: (state: { pledgeItemDeletionReason: any }) => state.pledgeItemDeletionReason,
        pledgeItemDeletionReasons: (state: { pledgeItemDeletionReasons: any }) => state.pledgeItemDeletionReasons
    },
    mutations: {
        [SET_PLEDGE_ITEM_DELETION_REASON]: (state: { pledgeItemDeletionReason: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.pledgeItemDeletionReason.data = payload.data : null, state.pledgeItemDeletionReason.isLoading = payload.isLoading },
        [SET_PLEDGE_ITEM_DELETION_REASONS]: (state: { pledgeItemDeletionReasons: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.pledgeItemDeletionReasons.data = payload.data : null, state.pledgeItemDeletionReasons.isLoading = payload.isLoading }
    },
    actions: {
        [ADD_PLEDGE_ITEM_DELETION_REASON]: async({ commit }: any, payload: any) => {
            commit(SET_PLEDGE_ITEM_DELETION_REASON, { data: null, isLoading: true })

            let result: AxiosResponse = await PledgeItemDeletionReason.addPledgeItemDeletionReason(payload as AddPledgeItemDeletionReason)
          
            if(result) {
                if (result.data.status) {
                    commit(SET_PLEDGE_ITEM_DELETION_REASON, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_PLEDGE_ITEM_DELETION_REASON, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_PLEDGE_ITEM_DELETION_REASON, { isLoading: false })
                return false;
            }
        },
        [GET_PLEDGE_ITEM_DELETION_REASON]: async({ commit }: any, payload: any) => {
            commit(SET_PLEDGE_ITEM_DELETION_REASONS, { data: null, isLoading: true })

            let result: AxiosResponse = await PledgeItemDeletionReason.getPledgeItemDeletionReasons(payload as GetPledgeItemDeletionReason)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_PLEDGE_ITEM_DELETION_REASONS, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_PLEDGE_ITEM_DELETION_REASONS, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_PLEDGE_ITEM_DELETION_REASONS, { isLoading: false })
                return false;
            }
        },
        [CLEAR_PLEDGE_ITEM_DELETION_REASONS_ISLOADING]: ({ commit }) => {
            commit(SET_PLEDGE_ITEM_DELETION_REASON, { isLoading: false })
            commit(SET_PLEDGE_ITEM_DELETION_REASONS, { isLoading: false })
        }
    }
}

export default pledgeItemDeletionReason