<template>
    <div>
        <div v-if="router.params.id == DISPLAY_SCREEN_ONE_ID">
            <displayScreen1/>
        </div>
        <div v-if="router.params.id == DISPLAY_SCREEN_TWO_ID">
            <displayScreen2/>
        </div>
        <div v-if="router.params.id == DISPLAY_SCREEN_THREE_ID">
            <displayScreen3/>
        </div>
    </div>
</template>
<script>
import displayScreen1 from '@/views/display/Display_Screen_1.vue'
import displayScreen2 from '@/views/display/Display_Screen_2.vue'
import displayScreen3 from '@/views/display/Display_Screen_3.vue'
import { DISPLAY_SCREEN_ONE_ID, DISPLAY_SCREEN_TWO_ID, DISPLAY_SCREEN_THREE_ID } from '../../utils/const';

export default {
    components: {
        displayScreen1,
        displayScreen2,
        displayScreen3
    },
    data() {
        return {
            DISPLAY_SCREEN_ONE_ID,
            DISPLAY_SCREEN_TWO_ID,
            DISPLAY_SCREEN_THREE_ID
        }
    },
    computed: {
        router() {
            return this.$route
        }
    }
}
</script>