import axios, { AxiosResponse } from 'axios'
import { SET_SHIURIMS, SET_SHIURIM, SET_ERRORMSG } from '../mutation_types'
import {
    CLEAR_SHIURIM_ISLOADING,
    DELETE_SHIURIM,
    EDIT_SHIURIM,
    GET_SHIURIMS,
    ADD_SHIURIM,
} from '../action_types'
import { StateObject } from '@/dtos/state.dto'
import Shiurim from '@/services/shiurim'
import { Module } from 'vuex'
import { EditShiurim, GetShiurim, RegisterShiurim } from '@/dtos/shiurim.dto'

const shiurim: Module<any, {}> = {
    state: {
        shiurim: {
            data: null,
            isLoading: false
        },
        shiurims: {
            data: null,
            isLoading: false
        }
    },
    getters: {
        shiurim: (state: { shiurim: any }) => state.shiurim,
        shiurims: (state: { shiurims: any }) => state.shiurims
    },
    mutations: {
        [SET_SHIURIM]: (state: { shiurim: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.shiurim.data = payload.data : null, state.shiurim.isLoading = payload.isLoading },
        [SET_SHIURIMS]: (state: { shiurims: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.shiurims.data = payload.data : null, state.shiurims.isLoading = payload.isLoading }
    },
    actions: {
        [ADD_SHIURIM]: async({ commit }: any, payload: any) => {
            commit(SET_SHIURIM, { data: null, isLoading: true })

            let result: AxiosResponse = await Shiurim.registerShiurim(payload as RegisterShiurim)
          
            if(result) {
                if (result.data.status) {
                    commit(SET_SHIURIM, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_SHIURIM, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_SHIURIM, { isLoading: false })
                return false;
            }
        },
        [GET_SHIURIMS]: async({ commit }: any, payload: any) => {
            commit(SET_SHIURIMS, { data: null, isLoading: true })

            let result: AxiosResponse = await Shiurim.getShiurims(payload as GetShiurim)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_SHIURIMS, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_SHIURIMS, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_SHIURIMS, { isLoading: false })
                return false;
            }
        },
        [EDIT_SHIURIM]: async({ commit }: any, payload: any) => {
            commit(SET_SHIURIM, { data: null, isLoading: true })

            let result: AxiosResponse = await Shiurim.editShiurim(payload as EditShiurim)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_SHIURIM, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_SHIURIM, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_SHIURIM, { isLoading: false })
                return false;
            }
        },
        [DELETE_SHIURIM]: async({ commit }: any, payload: string) => {
            commit(SET_SHIURIM, { data: null, isLoading: true })

            let result: AxiosResponse = await Shiurim.deleteShiurim(payload)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_SHIURIM, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_SHIURIM, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_SHIURIM, { isLoading: false })
                return false;
            }
        },
        [CLEAR_SHIURIM_ISLOADING]: ({ commit }) => {
            commit(SET_SHIURIM, { isLoading: false })
            commit(SET_SHIURIMS, { isLoading: false })
        }
    }
}

export default shiurim