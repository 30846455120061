import axios, { AxiosResponse } from 'axios'
import { SET_PLEDGE_ITEMS, SET_PLEDGE_ITEM, SET_ERRORMSG } from '../mutation_types'
import {
    CLEAR_PLEDGE_ITEMS_ISLOADING,
    DELETE_PLEDGE_ITEM,
    EDIT_PLEDGE_ITEM,
    GET_PLEDGE_ITEMS,
    ADD_PLEDGE_ITEM,
    PLEDGE_PLEDGE_ITEM,
    ADD_BULK_MEMBERS_PLEDGE_ITEMS,
} from '../action_types'
import { StateObject } from '@/dtos/state.dto'
import PledgeItem from '@/services/pledge_item'
import { Module } from 'vuex'
import { EditPledgeItem, AddPledgeItem, GetPledgeItem, DeletePledgeItem, AddBulkMembersPledgeItems } from '@/dtos/pledge_item.dto'

const pledgeItem: Module<any, {}> = {
    state: {
        pledgeItem: {
            data: null,
            isLoading: false
        },
        pledgeItems: {
            data: null,
            isLoading: false
        },
    },
    getters: {
        pledgeItem: (state: { pledgeItem: any }) => state.pledgeItem,
        pledgeItems: (state: { pledgeItems: any }) => state.pledgeItems
    },
    mutations: {
        [SET_PLEDGE_ITEM]: (state: { pledgeItem: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.pledgeItem.data = payload.data : null, state.pledgeItem.isLoading = payload.isLoading },
        [SET_PLEDGE_ITEMS]: (state: { pledgeItems: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.pledgeItems.data = payload.data : null, state.pledgeItems.isLoading = payload.isLoading },
    },
    actions: {
        [ADD_PLEDGE_ITEM]: async({ commit }: any, payload: any) => {
            commit(SET_PLEDGE_ITEM, { data: null, isLoading: true })

            let result: AxiosResponse = await PledgeItem.registerPledgeItem(payload as AddPledgeItem)
          
            if(result) {
                if (result.data.status) {
                    commit(SET_PLEDGE_ITEM, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_PLEDGE_ITEM, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_PLEDGE_ITEM, { isLoading: false })
                return false;
            }
        },
        [ADD_BULK_MEMBERS_PLEDGE_ITEMS]: async({ commit }: any, payload: any) => {
            commit(SET_PLEDGE_ITEM, { data: null, isLoading: true })

            let result: AxiosResponse = await PledgeItem.registerBulkMembersPledgeItems(payload as AddBulkMembersPledgeItems)
          
            if(result) {
                if (result.data.status) {
                    commit(SET_PLEDGE_ITEM, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_PLEDGE_ITEM, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_PLEDGE_ITEM, { isLoading: false })
                return false;
            }
        },
        [GET_PLEDGE_ITEMS]: async({ commit }: any, payload: any) => {
            commit(SET_PLEDGE_ITEMS, { data: null, isLoading: true })

            let result: AxiosResponse = await PledgeItem.getPledgeItems(payload as GetPledgeItem)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_PLEDGE_ITEMS, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_PLEDGE_ITEMS, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_PLEDGE_ITEMS, { isLoading: false })
                return false;
            }
        },
        [EDIT_PLEDGE_ITEM]: async({ commit }: any, payload: any) => {
            commit(SET_PLEDGE_ITEM, { data: null, isLoading: true })

            let result: AxiosResponse = await PledgeItem.editPledgeItem(payload as EditPledgeItem)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_PLEDGE_ITEM, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_PLEDGE_ITEM, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_PLEDGE_ITEM, { isLoading: false })
                return false;
            }
        },
        [PLEDGE_PLEDGE_ITEM]: async({ commit }: any, payload: any) => {
            commit(SET_PLEDGE_ITEM, { data: null, isLoading: true })

            let result: AxiosResponse = await PledgeItem.payPledgeItem(payload as EditPledgeItem)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_PLEDGE_ITEM, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_PLEDGE_ITEM, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_PLEDGE_ITEM, { isLoading: false })
                return false;
            }
        },
        [DELETE_PLEDGE_ITEM]: async({ commit }: any, payload: any) => {
            commit(SET_PLEDGE_ITEM, { data: null, isLoading: true })

            let result: AxiosResponse = await PledgeItem.deletePledgeItem(payload as DeletePledgeItem)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_PLEDGE_ITEM, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_PLEDGE_ITEM, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_PLEDGE_ITEM, { isLoading: false })
                return false;
            }
        },
        [CLEAR_PLEDGE_ITEMS_ISLOADING]: ({ commit }) => {
            commit(SET_PLEDGE_ITEM, { isLoading: false })
            commit(SET_PLEDGE_ITEMS, { isLoading: false })
        }
    }
}

export default pledgeItem