<template>
    <div>
        <vue-final-modal :drag="false" drag-selector="" v-model="showModal" content-class="modal-content"
            classes="modal-container" focus-trap>
            <button class="modal__close btn btn-light" @click="$emit('closeEditPurposeModal')">
                <i class="pi pi-times"></i>
            </button>
            <span class="modal__title">Edit Purpose</span>
            <br>
            <div class="modal__content">
                <JewishDatePicker v-if="selectedJewDatePicker == DATE_PICKER_PURPOSE_DATE"
                    @select-date="selectEventDate" @close-date-picker-modal="closeModal()" />
                <JewishDatePicker v-if="selectedJewDatePicker == DATE_PICKER_PURPOSE_CUSTOM_DATE"
                    @select-date="selectReminderDate" @close-date-picker-modal="closeModal()" />
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <p v-if="errorMessage" class="text-center text-danger auth-status-msg">{{ errorMessage
                                    }}</p>
                                <Form @submit="editPurpose">
                                    <div class="form-group">
                                        <label for="name">Purpose</label>
                                        <Field v-model="editPurposeObj.name" rules="required" type="text" name="name"
                                            class="form-control" id="name" placeholder="Purpose" />
                                        <ErrorMessage name="name" class="form-text text-danger" />
                                    </div>
                                    <div @click="selectedJewDatePicker = DATE_PICKER_PURPOSE_DATE">
                                        <div class="form-group">
                                            <label for="eventDate">Date</label>
                                            <div class="row date-picker-row">
                                                <div class="col-11">
                                                    <Field v-model="purposeEventDisplayDate" rules="" type="text"
                                                        disabled name="eventDate" class="form-control" id="eventDate"
                                                        placeholder="Date" />
                                                </div>
                                                <div class="col-1">
                                                    <i class="pi pi-calendar-plus text-primary"></i>
                                                </div>
                                            </div>
                                            <ErrorMessage name="eventDate" class="form-text text-danger" />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-3">
                                            <div class="form-group">
                                                <label for="name">Is Recurring Annually</label>
                                                <br>
                                                <label class="form-switch">
                                                    <input type="checkbox"
                                                        :checked="editPurposeObj.isRecurringAnnually">
                                                    <i></i>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-9">
                                            <div @click="selectedJewDatePicker = DATE_PICKER_PURPOSE_CUSTOM_DATE">
                                                <div class="form-group">
                                                    <label for="reminderDate">Reminder date</label>
                                                    <div class="row date-picker-row">
                                                        <div class="col-10">
                                                            <Field v-model="purposeReminderDisplayDate" rules=""
                                                                type="text" disabled name="reminderDate"
                                                                class="form-control" id="reminderDate"
                                                                placeholder="Date" />
                                                        </div>
                                                        <div class="col-1">
                                                            <i class="pi pi-calendar-plus text-primary"></i>
                                                        </div>
                                                    </div>
                                                    <ErrorMessage name="reminderDate" class="form-text text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row justify-content-end modal__actions">
                                        <button class="col-12 btn btn-save" :disabled="purposeIsLoading ? true : false">
                                            <span v-if="purposeIsLoading" class="spinner-border spinner-border-sm"
                                                role="status" aria-hidden="true"></span>
                                            <span v-if="!purposeIsLoading">Save</span>
                                        </button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </vue-final-modal>
    </div>
</template>
<script>
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import { ADD_PURPOSE, EDIT_PURPOSE } from '@/store/action_types'
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'

import { Field, Form, ErrorMessage } from 'vee-validate';
import { defineRule } from 'vee-validate';
import { required, email, min } from '@vee-validate/rules';

import JewishDatePicker from '@/components/JewishDatePicker.vue';
import { PURPOSE_REMINDER_DATES, DATE_PICKER_PURPOSE_DATE, DATE_PICKER_PURPOSE_CUSTOM_DATE } from '@/utils/const';

defineRule('required', required);
defineRule('email', email);
defineRule('min', min);

export default {
    props: {
        selectedPurpose: Object
    },
    components: {
        JewishDatePicker,
        VueFinalModal,
        ModalsContainer,
        Form,
        Field,
        ErrorMessage,
        QuillEditor
    },
    data () {
        return {
            PURPOSE_REMINDER_DATES,
            DATE_PICKER_PURPOSE_DATE,
            DATE_PICKER_PURPOSE_CUSTOM_DATE,
            showModal: true,
            selectedJewDatePicker: false,
            editPurposeObj: {
                id: null,
                name: null,
                eventDate: null,
                reminderDate: null,
                isRecurringAnnually: true,
            },
            reminderDateType: null,
            purposeEventDisplayDate: null,
            purposeReminderDisplayDate: null
        }
    },
    watch: {
        'editPurposeObj.eventDate': function (newValue, oldValue) {
            console.log('asdasdasdd000 - = ')
            this.updateReminderDate();
        },
        'reminderDateType': function (newValue, oldValue) {
            console.log('asdasdasdd000 - = 45422')
            this.updateReminderDate();
        }
    },
    computed: {
        errorMessage() {
            return this.$store.getters.errorMsg
        },
        purpose() {
              return this.$store.getters.purpose.data
        },
        purposeIsLoading() {
            return this.$store.getters.purpose.isLoading
        }
    },
    methods: {
        setValues() {
            this.editPurposeObj.id = this.selectedPurpose.id
            this.editPurposeObj.name = this.selectedPurpose.name
            this.editPurposeObj.eventDate = this.$filters.formatDate(this.selectedPurpose.eventDate)
            this.editPurposeObj.reminderDate = this.$filters.formatDate(this.selectedPurpose.reminderDate)
            this.editPurposeObj.isRecurringAnnually = this.selectedPurpose.isRecurringAnnually

            this.purposeEventDisplayDate = this.$filters.convertToHebrewDate(this.selectedPurpose.eventDate)
            this.purposeReminderDisplayDate = this.$filters.convertToHebrewDate(this.selectedPurpose.reminderDate)
        },
        closeModal() {
            this.selectedJewDatePicker = null;
        },
        selectEventDate(selectedDate) {
            this.editPurposeObj.eventDate = this.$filters.convertHDateToGregorian(selectedDate)
            this.purposeEventDisplayDate = this.$filters.convertToHebrewDate(this.editPurposeObj.eventDate)
            this.closeModal()
        },
        selectReminderDate(selectedDate) {
            this.editPurposeObj.reminderDate = this.$filters.convertHDateToGregorian(selectedDate)
            this.purposeReminderDisplayDate = this.$filters.convertToHebrewDate(this.editPurposeObj.reminderDate)
            this.closeModal()
        },
        updateReminderDate() {
            let date = this.editPurposeObj.eventDate

            if (this.reminderDateType == PURPOSE_REMINDER_DATES[0]) {
                const result = new Date(date);
                result.setDate(result.getMonth() - 1);
                this.editPurposeObj.reminderDate = result
            }

            if (this.reminderDateType == PURPOSE_REMINDER_DATES[1]) {
                const result = new Date(date);
                result.setDate(result.getDate() - 7);
                this.editPurposeObj.reminderDate = result
            }

            if (this.reminderDateType == PURPOSE_REMINDER_DATES[2]) {
                const result = new Date(date);
                result.setDate(result.getDate() - 3);
                this.editPurposeObj.reminderDate = result
            }

            if (this.reminderDateType == PURPOSE_REMINDER_DATES[3]) {
                const result = new Date(date);
                result.setDate(result.getDate() - 1);
                this.editPurposeObj.reminderDate = result
            }
        },
        editPurpose() {   
            this.$store.dispatch(EDIT_PURPOSE, this.editPurposeObj).then((result) => {
                if(result) {
                    this.$emit('closeEditPurposeModal')
                    this.$emit('refreshPurposes')
                }
            })           
        },
    },
    created() {
        this.setValues()
    }
}
</script>
<style scoped>
::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    position: relative;
    left: 30%;
    margin-top: 60px;
}

::v-deep .modal-content {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.2rem;
    background: #fff;
}

.btn-save {
    margin-top: 10px;
    background-color: #14243D;
}
</style>