import baseAPI from '@/api/base';
import { EditPledgeItem, AddPledgeItem, GetPledgeItem, DeletePledgeItem, AddBulkMembersPledgeItems } from '../dtos/pledge_item.dto';
import { DELETE_PLEDGE_ITEM_URL, PLEDGE_ITEM_URL, EDIT_PLEDGE_ITEM_URL, ADD_PLEDGE_ITEM_URL, PAY_PLEDGE_ITEM_URL, ADD_BULK_MEMBERS_PLEDGE_ITEM_URL } from '@/utils/urls';
import { AxiosResponse } from 'axios';

class PledgeItem {
    static getPledgeItems = async(getPledgeItem: GetPledgeItem) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(PLEDGE_ITEM_URL, {
                    params: getPledgeItem
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static registerPledgeItem = async(addPledgeItem: AddPledgeItem) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(ADD_PLEDGE_ITEM_URL, {
                    ...addPledgeItem
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static registerBulkMembersPledgeItems = async(addBulkMembersPledgeItems: AddBulkMembersPledgeItems) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(ADD_BULK_MEMBERS_PLEDGE_ITEM_URL, {
                    ...addBulkMembersPledgeItems
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static editPledgeItem = async(editPledgeItem: EditPledgeItem) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.put(EDIT_PLEDGE_ITEM_URL, {
                    ...editPledgeItem
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static payPledgeItem = async(editPledgeItem: EditPledgeItem) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(PAY_PLEDGE_ITEM_URL, {
                    ...editPledgeItem
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static deletePledgeItem = async(deletePledgeItem: DeletePledgeItem) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.delete(DELETE_PLEDGE_ITEM_URL, {
                    data: {
                        ...deletePledgeItem
                    }
                })

                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }
}

export default PledgeItem