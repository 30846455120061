<template>
    <div class="dashboard-body">
        <div class="row">
            <sidenav class="custom-col-width-2" />
            <div style="padding-left: 0px!important" class="custom-col-width-10 content-section dashboard-content">
                <navbar />
                <addScreenModal v-if="showAddScreenModal" :organizationId="organizationId"
                    @close-add-screen-modal="showAddScreenModal = false" @refresh-screens="getScreens()" />
                <editScreenModal v-if="showEditScreenModal" :selectedScreen="selectedScreen"
                    @close-edit-screen-modal="showEditScreenModal = false" @refresh-screens="getScreens()" />
                <deleteScreenModal v-if="showDeleteScreenModal" :selectedScreenId="selectedScreenId"
                    @close-delete-screen-modal="showDeleteScreenModal = false" @refresh-screens="getScreens()" />
                <registerOrganizationScreenModal v-if="showRegisterOrganizationScreenModal" :organizationId="organizationId"
                    @close-register-organization-screen-modal="showRegisterOrganizationScreenModal = false" @refresh-organization-screens="getOrgnizationScreens()" />
                <editOrganizationScreenModal v-if="showEditOrganizationScreenModal" :selectedOrganizationScreen="selectedOrganizationScreen"
                    @close-edit-organization-screen-modal="showEditOrganizationScreenModal = false" @refresh-organization-screens="getOrgnizationScreens()" />
                <deleteOrganizationScreenModal v-if="showDeleteOrganizationScreenModal" :selectedOrganizationScreenId="selectedOrganizationScreenId"
                    @close-delete-organization-screen-modal="showDeleteOrganizationScreenModal = false" @refresh-organization-screens="getOrgnizationScreens()" />
                <div class="content">
                    <br>
                    <div class="row button-grp justify-content-between">
                        <div class="col">
                            <h4>Organization</h4>
                        </div>
                        <div v-if="account.role == USER_ROLE_ADMIN" class="col">
                            <button v-on:click="showRegisterOrganizationScreenModal = true" class="btn btn-add"> <i
                                    class="pi pi-plus"></i> Assign Screen</button>
                        </div>
                    </div>
                    <div class="row justify-content-between">
                        <organizationNavbar class="" />
                    </div>
                    <br>
                    <!-- <div class="row">
                        <template v-bind:key="index" v-for="(screen, index) in screens">
                            <div v-if="screen.id == 'c8df9f96-41c1-4324-8bf0-fc146f204b7e' ? route.params.id == '21e1b3e2-27d6-4262-8312-bd2b7051fde7' ? true : false : true"
                                class="col-3">
                                <div class="card">
                                    <div
                                        :class="[ (refreshedScreenIsLoading && refreshedScreen.id == screen.id) ? 'card-loading' : null, 'card-overlay']">
                                        <i v-on:click="openDisplay(screen.id)" class="pi pi-external-link"></i>
                                        <span v-if="refreshedScreenIsLoading" class="spinner-border spinner-border-sm"
                                            role="status" aria-hidden="true"></span>
                                        <i v-else v-on:click="refreshScreen(screen.id)" class="pi pi-refresh"></i>
                                    </div>
                                    <img class="card-img-top" :src="`${STORAGE_URL}/${screen.image}`"
                                        alt="Card image cap">
                                    <div class="card-body">
                                        <p class="title text-center">{{ screen.name }} <i
                                                class="pi pi-external-link"></i></p>
                                        <p class="donations text-center">Donations: 21</p>
                                        <div class="badge-container">
                                            <span v-if="screen.isActive"
                                                class="badge badge-active text-center">Active</span>
                                            <span v-else class="badge badge-inactive text-center">In Active</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div> -->
                    <div class="row">
                        <template v-bind:key="index" v-for="(organizationScreen, index) in organizationScreens">
                            <div v-if="organizationScreen.screen.id == 'c8df9f96-41c1-4324-8bf0-fc146f204b7e' ? route.params.id == '21e1b3e2-27d6-4262-8312-bd2b7051fde7' ? true : false : true"
                                class="col-3">
                                <div class="card">
                                    <div
                                        :class="[ (refreshedScreenIsLoading && refreshedScreen.id == organizationScreen.screen.id) ? 'card-loading' : null, 'card-overlay']">
                                        <i v-on:click="openDisplay(organizationScreen.screen.id)" class="pi pi-external-link"></i>
                                        <span v-if="refreshedScreenIsLoading" class="spinner-border spinner-border-sm"
                                            role="status" aria-hidden="true"></span>
                                        <i v-else v-on:click="refreshScreen(organizationScreen.screen.id)" class="pi pi-refresh"></i>
                                        <i v-on:click="selectOrganizationScreenToEdit(organizationScreen)" class="pi pi-pencil"></i>
                                        <i v-on:click="selectOrganizationScreenToDelete(organizationScreen.id)" class="pi pi-trash"></i>
                                    </div>
                                    <img class="card-img-top" :src="`${STORAGE_URL}/${organizationScreen.screen.image}`"
                                        alt="Card image cap">
                                    <div class="card-body">
                                        <p class="title text-center">{{ organizationScreen.name }} <i
                                                class="pi pi-external-link"></i></p>
                                        <p class="donations text-center">Donations: 21</p>
                                        <div class="badge-container">
                                            <span v-if="organizationScreen.screen.isActive"
                                                class="badge badge-active text-center">Active</span>
                                            <span v-else class="badge badge-inactive text-center">In Active</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                    <br>
                    <br>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import sidenav from '@/components/Sidenav.vue'
import navbar from '@/components/Navbar.vue'
import organizationNavbar from '@/components/Organization_Navbar.vue'
import addScreenModal from '@/components/modals/Screen/Add_Screen_Modal.vue'
import editScreenModal from '@/components/modals/Screen/Edit_Screen_Modal.vue'
import deleteScreenModal from '@/components/modals/Screen/Delete_Screen_Modal.vue'
import registerOrganizationScreenModal from '@/components/modals/Screen/Organization/Register_Organization_Screen.vue'
import editOrganizationScreenModal from '@/components/modals/Screen/Organization/Edit_Organization_Screen.vue'
import deleteOrganizationScreenModal from '@/components/modals/Screen/Organization/Delete_Organization_Screen.vue'
import { GET_ORGANIZATION_SCREENS, GET_SCREENS, REFRESH_SCREEN } from '@/store/action_types'
import { ORGANIZATION_DETAILS_ROUTE, ORGANIZATION_ACCOUNTS_ROUTE, ORGANIZATION_SCREENS_ROUTE, DISPLAY_ROUTE } from '@/router/routes'
import { STORAGE_URL, TOAST_GLOBAL_CONFIG, USER_ROLE_ADMIN } from '@/utils/const';

export default {
    title: 'Screens',
    components: {
        sidenav,
        navbar,
        organizationNavbar,
        addScreenModal,
        editScreenModal,
        deleteScreenModal,
        registerOrganizationScreenModal,
        editOrganizationScreenModal,
        deleteOrganizationScreenModal
    },
    data() {
        return {
            USER_ROLE_ADMIN,
            ORGANIZATION_DETAILS_ROUTE,
            ORGANIZATION_ACCOUNTS_ROUTE,
            ORGANIZATION_SCREENS_ROUTE,
            STORAGE_URL,
            showAddScreenModal: false,
            showEditScreenModal: false,
            showDeleteScreenModal: false,
            showRegisterOrganizationScreenModal: false,
            showEditOrganizationScreenModal: false,
            showDeleteOrganizationScreenModal: false,
            selectedOrganizationScreen: null,
            selectedOrganizationScreenId: null,
            selectedScreen: null,
            selectedScreenId: null,
            organizationId: null
        }
    },
    computed: {
        route() {
            return this.$route
        },
        isLoading() {
            return this.$store.getters.organizations.isLoading
        },
        organizations() {
            return this.$store.getters.organizations.data
        },
        organization() {
            return this.$store.getters.organization.data
        },
        actingScreen() {
            return this.$store.getters.user.data
        },
        actingScreenIsLoading() {
            return this.$store.getters.user.isLoading
        },
        screens() {
            return this.$store.getters.screens.data
        },
        screensIsLoading() {
            return this.$store.getters.screens.isLoading
        },
        organizationScreens() {
            return this.$store.getters.organizationScreens.data
        },
        organizationScreensIsLoading() {
            return this.$store.getters.organizationScreens.isLoading
        },
        refreshedScreen() {
            return this.$store.getters.refreshScreen.data
        },
        refreshedScreenIsLoading() {
            return this.$store.getters.refreshScreen.isLoading
        },
        account() {
            return this.$store.getters.account.data
        }
    },
    methods: {
        isOdd(number) {
            return number % 2 !== 0;
        },
        openDisplay(id) {
            const routeData = this.$router.resolve({ name: DISPLAY_ROUTE, params: { id: id, org: this.$route.params.id } });
            window.open(routeData.href, '_blank');
        },
        selectOrganizationScreenToEdit(screen) {
            this.selectedOrganizationScreen = screen
            this.showEditOrganizationScreenModal = true
        },
        selectOrganizationScreenToDelete(screenId) {
            this.selectedOrganizationScreenId = screenId
            this.showDeleteOrganizationScreenModal = true
        },
        setValues() {
            this.organizationId = this.$route.params.id
        },
        refreshScreen(scr) {
            this.$store.dispatch(REFRESH_SCREEN, { scr, org: this.$route.params.id }).then((res) => {
                if (res) {
                    this.$toast.success('Screen Refreshed', TOAST_GLOBAL_CONFIG);
                }
            })
        },
        getScreens() {
            this.$store.dispatch(GET_SCREENS, { org: this.$route.params.id })
        },
        getOrgnizationScreens() {
            this.$store.dispatch(GET_ORGANIZATION_SCREENS, { org: this.$route.params.id })
        }
    },
    mounted() {
        this.setValues()
        this.getScreens()
        this.getOrgnizationScreens()
    }
}
</script>
<style scoped>
.content .card {
    padding: 0px;
}

.content .card .card-header {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: #F4ECE2;
}

.content .card .card-header {
    color: #5A5A57;
}

.content .card .card-content {
    padding: 15px;
}

.content .card .card-content .value {
    font-weight: 300!important;
}

.content .btn-group .btn {
    border-color: #8b7433!important;
    color: #000000!important;
}

.content .btn-group .btn-selected {
    border-color: #8b7433!important;
    color: #000000!important;
    background-color: #CAB781!important;
}

.content .card table .striped {
    background-color: #FAF7F4!important;
}

.content .card table tbody tr:hover {
    background-color: #FAF7F4!important;
}

.content .card table small {
    margin-left: 10px;
    margin-right: 10px;
}

.content .card table .pi-trash,
.content .card table .pi-pencil {
    cursor: pointer;
}

.content .card-body hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.content .card {
    padding: 0px;
}

.card .pi:hover {
    cursor: pointer;
}

.card-body {
    padding: 10px;
}

.card-body .title {
    font-weight: 600;
    cursor: pointer;
    margin-bottom: 0px;
    color: #1D1F2C;
}

.card-body .donations {
    font-size: 12px;
    margin-bottom: 7px;
}

.card-body .badge-container {
    display: grid;
    place-items: center;
}

.card-body .pi {
    margin-left: 10px;
    color: #007bff;
    font-size: 13px;
}

.card-overlay .pi {
    font-size: 15px;
    margin-left: 20px;
    margin-right: 20px;
}

.card-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    border-radius: 10px;
    transition: opacity 0.3s ease-in-out;
}

.card:hover .card-overlay {
    opacity: 1;
}

.card .card-loading {
    opacity: 1;
}

.card-overlay i {
    font-size: 2rem;
    color: #fff;
}

.custom-col-width-2 {
    width: 16.66%;
}

.custom-col-width-10 {
    width: 83.3%;
}

@media (max-width: 1000px) {
    .custom-col-width-2 {
        width: 12%;
    }

    .custom-col-width-10 {
        width: 88%;
    }
}
</style>