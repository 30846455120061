<template>
    <div>
        <!-- Modal -->
        <div class="modal fade" tabindex="-1" :class="{ 'show': showModal }"
            :style="{ display: showModal ? 'block' : 'none' }" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <!-- <div class="modal-header">
                        <h5 class="modal-title">Select a Date</h5>
                        <button type="button" class="btn-close" @click="showModal = false" aria-label="Close"></button>
                    </div> -->
                    <div class="modal-body frankRuhl-font">
                        <div class="calendar-container">
                            <button type="button" class="btn btn-light" style="float: right; padding-bottom: 0px"
                                @click="closeModal()" aria-label="Close"><i class="pi pi-times"></i></button>
                            <div class="calendar-header">
                                <select v-model="currentMonth" @change="updateCalendar">
                                    <option v-for="month in months" :key="month.number" :value="month.number">
                                        {{ month.name }}
                                    </option>
                                </select>
                                <select v-model="currentYear" @change="updateCalendar">
                                    <!-- <option v-for="year in generateYearRange" :key="year" :value="year">{{ year }} -->
                                    <option v-for="year in generateYearRange" :key="year" :value="year">{{
                                        YEARS_IN_HEBREW[HEBREW_YEARS.indexOf(year.toString())] }}
                                    </option>
                                </select>
                            </div>
                            <div class="calendar-grid">
                                <div class="day-name" v-for="name in dayNames" :key="name">{{ name }}</div>
                                <!-- Placeholder divs for empty grid cells -->
                                <div v-for="empty in firstDayOffset" :key="empty" class="day empty"></div>
                                <!-- Day cells -->
                                <div class="day" v-for="day in daysOfMonth" :key="day.toString()"
                                    :class="{ 'selected': isSelected(day), 'today': isToday(day) }"
                                    @click="selectDate(day)">
                                    {{ DAYS_IN_HEBREW[day.getDate() - 1] }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="showModal = false">Close</button>
                        <button type="button" class="btn btn-primary" @click="confirmDate">Select Date</button>
                    </div> -->
                </div>
            </div>
        </div>

        <div v-if="selectedDate">
            <p>Selected Date: {{ selectedDate.toString() }}</p>
        </div>
    </div>
</template>

<script>
import { HDate, HebrewCalendar } from 'hebcal';
import { DAYS_IN_HEBREW, YEARS_IN_HEBREW, HEBREW_YEARS } from '@/utils/const';

export default {
    data() {
        return {
            DAYS_IN_HEBREW,
            YEARS_IN_HEBREW,
            HEBREW_YEARS,
            showModal: true,
            selectedDate: null,
            currentMonth: new HDate().getMonth(),
            currentYear: new HDate().getFullYear(),
            // months: [
            //     { name: 'Nisan', number: 1 },
            //     { name: 'Iyyar', number: 2 },
            //     { name: 'Sivan', number: 3 },
            //     { name: 'Tammuz', number: 4 },
            //     { name: 'Av', number: 5 },
            //     { name: 'Elul', number: 6 },
            //     { name: 'Tishrei', number: 7 },
            //     { name: 'Cheshvan', number: 8 },
            //     { name: 'Kislev', number: 9 },
            //     { name: 'Tevet', number: 10 },
            //     { name: 'Shevat', number: 11 },
            //     { name: 'Adar', number: 12 },
            //     { name: 'Adar I', number: 13 }, // For leap years
            //     { name: 'Adar II', number: 14 } // For leap years
            // ],
            months: [
                { name: 'ניסן', number: 1 },
                { name: 'אייר', number: 2 },
                { name: 'סיון', number: 3 },
                { name: 'תמוז', number: 4 },
                { name: 'אב', number: 5 },
                { name: 'אלול', number: 6 },
                { name: 'תשרי', number: 7 },
                { name: 'חשון', number: 8 },
                { name: 'כסלו', number: 9 },
                { name: 'טבת', number: 10 },
                { name: 'שבט', number: 11 },
                { name: 'אדר', number: 12 },
                { name: "אדר א", number: 13 }, // For leap years
                { name: "אדר ב", number: 14 } // For leap years
            ],
            dayNames: ['ראשון', 'שני', 'שלישי', 'רביעי', 'חמישי', 'שישי', 'שב"ק']
        };
    },
    computed: {
        daysOfMonth() {
            const days = [];
            const date = new HDate(1, this.currentMonth, this.currentYear);
            const totalDays = date.daysInMonth();
            for (let i = 1; i <= totalDays; i++) {
                days.push(new HDate(i, this.currentMonth, this.currentYear));
            }
            return days;
        },
        firstDayOffset() {
            const firstDay = new HDate(1, this.currentMonth, this.currentYear);
            return firstDay.getDay();
        },
        generateYearRange() {
            let currentYear = new HDate().getFullYear();
            return Array.from({ length: 30 }, (_, i) => currentYear - 10 + i);
        }
    },
    methods: {
        closeModal() {
            this.$emit('closeDatePickerModal')
        },
        updateCalendar() {
            this.currentMonth = parseInt(this.currentMonth);
            this.currentYear = parseInt(this.currentYear);
        },
        selectDate(day) {
            this.selectedDate = day;
            this.$emit('selectDate', this.selectedDate)
            this.closeModal()
        },
        isSelected(day) {
            return this.selectedDate && this.selectedDate.toString() === day.toString();
        },
        isToday(day) {
            const today = new HDate();
            return today.toString() === day.toString();
        }
    }
};
</script>
<style scoped>
.modal-content {
    border-radius: 20px !important;
    border: 1px solid transparent;
    background-color: #FDFDFE !important;
}

.calendar-container {
    margin: auto;
    text-align: center;
    margin: 0px
}

.calendar-header {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 15px;
}

.calendar-header select,
.calendar-header select option:checked {
    padding: 5px 10px 5px 10px;
    border-radius: 8px;
    color: #1D1F2C;
    background-color: #F9F9FC;
    border: 1px solid #CACBCE
}

.calendar-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 5px;
}

.day-name,
.day,
.empty {
    padding: 10px;
    border: 1px solid #E0E2E7;
    background-color: #FFFFFF;
    height: 40px;
    border-radius: 6px;
}

.day-name {
    border-radius: 8px;
    color: #1D1F2C;
    background-color: #F9F9FC;
    border: 1px solid #CACBCE
}

.day:hover {
    background-color: #F9F9FC;
    cursor: pointer;
}

.selected {
    background-color: #14243D;
    color: white;
}

.today {
    border: 2px solid #007bff;
}

.empty {
    background: none;
    border: none;
}

.modal {
    display: none;
}

.modal.show {
    display: block;
}
</style>

Availability builds on top of reliability

