// account
export const SIGNUP_URL = '/auth/signup'
export const LOGIN_URL = '/auth/login'

// dashboard
export const DASHBOARD_URL = '/dashboard'

// donation
// export const DONATION_URL = '/donation'
export const DONATION_URL = '/donation/old'
export const CALENDAR_DONATION_URL = '/donation/calendar'
export const MEMBER_DONATION_URL = '/donation/member'
export const REGISTER_DONATION_URL = '/donation/register'
export const EDIT_DONATION_URL = '/donation/edit'
export const TOGGLE_DONATION_IS_ACTIVE_URL = '/donation/toggleIsActive'
export const DELETE_DONATION_URL = '/donation'

// templates
export const TEMPLATE_URL = '/template'
export const ADD_TEMPLATE_URL = '/template/add'
export const EDIT_TEMPLATE_URL = '/template/edit'
export const DELETE_TEMPLATE_URL = '/template'

// announcement
export const ANNOUNCEMENT_URL = '/announcement'
export const ADD_ANNOUNCEMENT_URL = '/announcement/add'
export const EDIT_ANNOUNCEMENT_URL = '/announcement/edit'
export const DELETE_ANNOUNCEMENT_URL = '/announcement'

// screen
export const SCREEN_URL = '/screen'
export const ADD_SCREEN_URL = '/screen/add'
export const EDIT_SCREEN_URL = '/screen/edit'
export const DELETE_SCREEN_URL = '/screen'
export const REFRESH_SCREEN_URL = '/screen/refresh'

// organizations
export const ORGANIZATION_URL = '/organization'
export const REGISTER_ORGANIZATION_URL = '/organization/register'
export const EDIT_ORGANIZATION_URL = '/organization/edit'
export const HEADER_IMG_ORGANIZATION_URL = '/organization/headerImage'
export const TOGGLE_ORGANIZATION_IS_ACTIVE_URL = '/organization/toggleIsActive'
export const DELETE_ORGANIZATION_URL = '/organization'

// user
export const GET_USER_URL = '/account'
export const REGISTER_USER_URL = '/account/register'
export const EDIT_USER_URL = '/account/edit'
export const TOGGLE_USER_IS_ACTIVE_URL = '/account/toggleIsActive'
export const DELETE_USER_URL = '/account'

// member
export const MEMBER_URL = '/member'
export const ADD_MEMBER_URL = '/member/add'
export const EDIT_MEMBER_URL = '/member/edit'
export const DELETE_MEMBER_URL = '/member'

// purpose
export const PURPOSE_URL = '/purpose'
export const ADD_PURPOSE_URL = '/purpose/add'
export const EDIT_PURPOSE_URL = '/purpose/edit'
export const DELETE_PURPOSE_URL = '/purpose'

// notification
export const NOTIFICATION_URL = '/notification'
export const CLEAR_NOTIFICATION_URL = '/notification/clear'

// minyanim
export const MINYANIM_URL = '/minyanim'
export const DISPLAY_MINYANIM_URL = '/minyanim/display'
export const ADD_MINYANIM_URL = '/minyanim/add'
export const EDIT_MINYANIM_URL = '/minyanim/edit'
export const TOGGLE_MINYANIM_IS_ACTIVE_URL = '/minyanim/toggleIsActive'
export const DELETE_MINYANIM_URL = '/minyanim'

// room
export const ROOM_URL = '/room'
export const REGISTER_ROOM_URL = '/room/register'
export const EDIT_ROOM_URL = '/room/edit'
export const DELETE_ROOM_URL = '/room'

// alert
export const ALERT_URL = '/alert'
export const ADD_ALERT_URL = '/alert/add'
export const EDIT_ALERT_URL = '/alert/edit'
export const DELETE_ALERT_URL = '/alert'

// alert_notification
export const ALERT_NOTIFICATION_URL = '/alertNotification'
export const VIEW_ALERT_NOTIFICATION_URL = '/alertNotification/view'

// ticket
export const TICKET_URL = '/ticket'
export const ADD_TICKET_URL = '/ticket/add'
export const EDIT_TICKET_URL = '/ticket/edit'
export const CLOSE_TICKET_URL = '/ticket/close'
export const DELETE_TICKET_URL = '/ticket'

// ticket
export const TICKET_COMMENT_URL = '/ticketComment'
export const ADD_TICKET_COMMENT_URL = '/ticketComment/add'
export const EDIT_TICKET_COMMENT_URL = '/ticketComment/edit'
export const DELETE_TICKET_COMMENT_URL = '/ticketComment'

// minyanim_type
export const MINYANIM_TYPE_URL = '/minyanimType'
export const ADD_MINYANIM_TYPE_URL = '/minyanimType/add'
export const EDIT_MINYANIM_TYPE_URL = '/minyanimType/edit'
export const DELETE_MINYANIM_TYPE_URL = '/minyanimType'

// shiurim
export const SHIURIM_URL = '/shiurim'
export const ADD_SHIURIM_URL = '/shiurim/add'
export const EDIT_SHIURIM_URL = '/shiurim/edit'
export const DELETE_SHIURIM_URL = '/shiurim'

// synagogue
export const SYNAGOGUE_URL = '/synagogue'
export const REGISTER_SYNAGOGUE_URL = '/synagogue/register'
export const EDIT_SYNAGOGUE_URL = '/synagogue/edit'
export const DELETE_SYNAGOGUE_URL = '/synagogue'

// invoice
export const INVOICE_URL = '/invoice'
export const MEMBER_INVOICE_URL = '/invoice/member'
export const REGISTER_INVOICE_URL = '/invoice/register'
export const EDIT_INVOICE_URL = '/invoice/edit'
export const PAY_INVOICE_URL = '/invoice/pay'
export const DELETE_INVOICE_URL = '/invoice'

// invoice_item
export const INVOICE_ITEM_URL = '/invoiceItem'
export const INVOICE_ITEM_DETAILS_URL = '/invoiceItem/detail'
export const TOGGLE_INVOICE_ITEM_ISPAID_URL = '/invoiceItem/toggleIsPaid'

// invoice_item_payment_log
export const INVOICE_ITEM_PAYMENT_LOG_URL = '/invoiceItemPaymentLog'

// synagogue_seat
export const SYNAGOGUE_SEAT_URL = '/synagogueSeat'

// synagogue_seat_price_category
export const SYNAGOGUE_SEAT_PRICE_CATEGORY_URL = '/synagogueSeatPriceCategory'
export const ADD_SYNAGOGUE_SEAT_PRICE_CATEGORY_URL = '/synagogueSeatPriceCategory/add'
export const EDIT_SYNAGOGUE_SEAT_PRICE_CATEGORY_URL = '/synagogueSeatPriceCategory/edit'
export const DELETE_SYNAGOGUE_SEAT_PRICE_CATEGORY_URL = '/synagogueSeatPriceCategory'

// synagogue_seat_price_category
export const SYNAGOGUE_SEAT_PRICING_URL = '/synagogueSeatPricing'
export const ADD_SYNAGOGUE_SEAT_PRICING_URL = '/synagogueSeatPricing/add'
export const EDIT_SYNAGOGUE_SEAT_PRICING_URL = '/synagogueSeatPricing/edit'
export const DELETE_SYNAGOGUE_SEAT_PRICING_URL = '/synagogueSeatPricing'

// synagogue_member_seat
export const SYNAGOGUE_MEMBER_SEAT_URL = '/synagogueMemberSeat'
export const ADD_SYNAGOGUE_MEMBER_SEAT_URL = '/synagogueMemberSeat/add'
export const EDIT_SYNAGOGUE_MEMBER_SEAT_URL = '/synagogueMemberSeat/edit'
export const DELETE_SYNAGOGUE_MEMBER_SEAT_URL = '/synagogueMemberSeat'

// member_type
export const MEMBER_TYPE_URL = '/memberType'
export const ADD_MEMBER_TYPE_URL = '/memberType/add'
export const EDIT_MEMBER_TYPE_URL = '/memberType/edit'
export const DELETE_MEMBER_TYPE_URL = '/memberType'

// pledge_item
export const PLEDGE_ITEM_URL = '/pledgeItem'
export const ADD_PLEDGE_ITEM_URL = '/pledgeItem/add'
export const ADD_BULK_MEMBERS_PLEDGE_ITEM_URL = '/pledgeItem/add/membersBulk'
export const EDIT_PLEDGE_ITEM_URL = '/pledgeItem/edit'
export const PAY_PLEDGE_ITEM_URL = '/pledgeItem/pay'
export const DELETE_PLEDGE_ITEM_URL = '/pledgeItem'

// organization_screen
export const ORGANIZATION_SCREEN_URL = '/organizationScreen'
export const ADD_ORGANIZATION_SCREEN_URL = '/organizationScreen/add'
export const EDIT_ORGANIZATION_SCREEN_URL = '/organizationScreen/edit'
export const DELETE_ORGANIZATION_SCREEN_URL = '/organizationScreen'

// pledge_item_payment_log
export const PLEDGE_ITEM_PAYMENT_LOG_URL = '/pledgeItemPaymentLog'

// receipt
export const RECEIPT_URL = '/receipt'

// receipt
export const GET_DONATION_REPORT_STATS_URL = '/report/donation/stats'
export const GET_DONATION_REPORT_URL = '/report/donation'
export const GENERATE_DONATION_REPORT_FILE_URL = '/report/donation/generate'

// report_files
export const GET_REPORT_FILES_URL = '/report/file'
export const DELETE_REPORT_FILE_URL = '/report/file'

// pledge_item_deletion_reason
export const GET_PLEDGE_ITEM_DELETION_REASONS_URL = '/pledgeItemDeletionReason'
export const ADD_PLEDGE_ITEM_DELETION_REASON_URL = '/pledgeItemDeletionReason/add'

// pledge_item_edit_reason
export const GET_PLEDGE_ITEM_EDIT_REASONS_URL = '/pledgeItemEditReason'
export const ADD_PLEDGE_ITEM_EDIT_REASON_URL = '/pledgeItemEditReason/add'

// pledge_type
export const PLEDGE_TYPE_URL = '/pledgeType'
export const ADD_PLEDGE_TYPE_URL = '/pledgeType/add'
export const EDIT_PLEDGE_TYPE_URL = '/pledgeType/edit'
export const DELETE_PLEDGE_TYPE_URL = '/pledgeType'

// member_statement
export const GET_MEMBER_STATEMENT_URL = '/report/member'
export const GENERATE_MEMBER_STATEMENT_FILE_URL = '/report/member/statement/generate'

// module_setting
export const MODULE_SETTING_URL = '/moduleSettings'
export const ADD_MODULE_SETTING_URL = '/moduleSettings/add'

// alias
export const ALIAS_URL = '/alias'
export const GROUPED_ALIAS_URL = '/alias/grouped'
export const ADD_ALIAS_URL = '/alias/add'
export const EDIT_ALIAS_URL = '/alias/edit'
export const DELETE_ALIAS_URL = '/alias'

// alias_offering_type
export const ALIAS_OFFERING_TYPE_URL = '/aliasOfferingType'
export const ADD_ALIAS_OFFERING_TYPE_URL = '/aliasOfferingType/add'
export const EDIT_ALIAS_OFFERING_TYPE_URL = '/aliasOfferingType/edit'
export const DELETE_ALIAS_OFFERING_TYPE_URL = '/aliasOfferingType'

// parshat
export const PARSHAT_URL = '/parshat'
export const ADD_PARSHAT_URL = '/parshat/add'
export const EDIT_PARSHAT_URL = '/parshat/edit'
export const DELETE_PARSHAT_URL = '/parshat'

// stats
export const GET_DASHBOARD_STATS_URL = '/stats/dashboard'