<template>
    <div>
        <vue-final-modal :drag="false" drag-selector="" v-model="showModal" content-class="modal-content"
            classes="modal-container" focus-trap>
            <button class="modal__close btn btn-light" @click="$emit('closePayInvoiceModal')">
                <i class="pi pi-times"></i>
            </button>
            <span class="modal__title">Pay Partial Statement</span>
            <br>
            <div class="modal__content">
                <p v-if="errorMessage" class="text-center text-danger auth-status-msg">{{ errorMessage }}</p>
                <div class="row">
                    <div class="col-12">
                        <Form @submit="addInvoicePayment">
                            <div class="card">
                                <div class="card-body">
                                    <div class="form-group">
                                        <label for="amount">Amount</label>
                                        <Field v-model="payment.amount" rules="required" type="number" min="0"
                                            step="0.01" name="amount" class="form-control" id="amount"
                                            placeholder="0.00" />
                                        <ErrorMessage name="amount" class="form-text text-danger" />
                                    </div>
                                    <div class="form-group">
                                        <label for="paymentMethod">Payment Method</label>
                                        <select v-model="payment.paymentMethod" class="form-control" rules="required">
                                            <option>---</option>
                                            <template v-bind:key="index" v-for="(payment, index) in PAYMENT_METHODS">
                                                <option :value="payment">{{ payment }}</option>
                                            </template>
                                        </select>
                                        <ErrorMessage name="paymentMethod" class="form-text text-danger" />
                                    </div>
                                    <div v-if="payment.paymentMethod == PAYMENT_METHODS[3]" class="form-group">
                                        <label for="checkNumber">Check Number</label>
                                        <Field v-model="payment.checkNumber" rules="required" type="text"
                                            name="checkNumber" class="form-control" id="checkNumber"
                                            placeholder="##########" />
                                        <ErrorMessage name="checkNumber" class="form-text text-danger" />
                                    </div>
                                    <div v-if="payment.paymentMethod == PAYMENT_METHODS[4]" class="form-group">
                                        <label for="cardOptions">Charity Card Option</label>
                                        <select v-model="payment.charityCardOption" class="form-control"
                                            rules="required">
                                            <option>---</option>
                                            <template v-bind:key="index"
                                                v-for="(cardOptions, index) in CHARITY_CARD_OPTIONS">
                                                <option :value="cardOptions">{{ cardOptions }}</option>
                                            </template>
                                        </select>
                                        <ErrorMessage name="cardOptions" class="form-text text-danger" />
                                    </div>
                                    <div v-if="payment.charityCardOption == CHARITY_CARD_OPTIONS[4]" class="form-group">
                                        <label for="charityCardOptionOther">Charity Card Option Other</label>
                                        <Field v-model="charityCardOptionOther" rules="required" type="text"
                                            name="charityCardOptionOther" class="form-control"
                                            id="charityCardOptionOther" placeholder="Type here" />
                                        <ErrorMessage name="charityCardOptionOther" class="form-text text-danger" />
                                    </div>
                                </div>
                            </div>
                            <br>
                            <div class="row justify-content-end modal__actions">
                                <button class="col-2 btn btn-save" :disabled="invoiceIsLoading ? true : false">
                                    <span v-if="invoiceIsLoading" class="spinner-border spinner-border-sm" role="status"
                                        aria-hidden="true"></span>
                                    <span v-if="!invoiceIsLoading">Pay</span>
                                </button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </vue-final-modal>
    </div>
</template>
<script>
import { PAY_INVOICE } from '@/store/action_types'
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'

import { Field, Form, ErrorMessage } from 'vee-validate';
import { defineRule } from 'vee-validate';
import { required, email, min } from '@vee-validate/rules';
import { PAYMENT_METHODS, CHARITY_CARD_OPTIONS } from '@/utils/const';

defineRule('required', required);
defineRule('email', email);
defineRule('min', min);

export default {
    props: {
        selectedMemberId: String,
        selectedInvoiceId: String
    },
    components: {
        VueFinalModal,
        ModalsContainer,
        Form,
        Field,
        ErrorMessage
    },
    data() {
        return {
            PAYMENT_METHODS,
            CHARITY_CARD_OPTIONS,
            showModal: true,
            payment: {
                amount: null,
                paymentMethod: null,
                checkNumber: null,
                charityCardOption: null,
                memb: null,
                inv: null
            },
            charityCardOptionOther: null
        }
    },
    computed: {
        errorMessage() {
            return this.$store.getters.errorMsg
        },
        invoice() {
            return this.$store.getters.invoice.data
        },
        invoiceIsLoading() {
            return this.$store.getters.invoice.isLoading
        }
    },
    methods: {
        setValues() {
            this.payment.memb = this.selectedMemberId
            this.payment.inv = this.selectedInvoiceId
        },
        addInvoicePayment() {
            if (this.charityCardOptionOther != null) {
                this.payment.charityCardOption = this.charityCardOptionOther
            }

            this.$store.dispatch(PAY_INVOICE, this.payment).then((res) => {
                if (res) {
                    this.$emit('closePayInvoiceModal')
                    this.$emit('refreshInvoices')
                }
            })
        }
    },
    mounted() {
        this.setValues()
    }
}
</script>
<style scoped>
::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    position: relative;
    left: 35%;
    margin-top: 50px;
}

::v-deep .modal-content {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.2rem;
    background: #fff;
}

.modal__title {
    font-size: 18px;
    font-weight: 600;
}

.modal__close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
}

.modal__content .nav-tabs .nav-link {
    font-size: 15px !important;
}

.btn-save {
    background-color: #14243D;
}
</style>