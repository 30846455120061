<template>
    <div>
        <vue-final-modal :drag="false" drag-selector="" v-model="showModal" content-class="modal-content"
            classes="modal-container" focus-trap>
            <button class="modal__close btn btn-light" @click="$emit('closeViewPledgeTypesModal')">
                <i class="pi pi-times"></i>
            </button>
            <addPledgeTypeModal v-if="showAddPledgeTypeModal"
                @close-add-pledge-type-modal="showAddPledgeTypeModal = false"
                @refresh-pledge-types="getPledgeTypes()" />
            <editPledgeTypeModal v-if="showEditPledgeTypeModal" :selectedPledgeType="selectedPledgeType"
                @close-edit-pledge-type-modal="showEditPledgeTypeModal = false"
                @refresh-pledge-types="getPledgeTypes()" />
            <deletePledgeTypeModal v-if="showDeletePledgeTypeModal" :selectedPledgeTypeId="selectedPledgeTypeId"
                @close-delete-pledge-type-modal="showDeletePledgeTypeModal = false"
                @refresh-pledge-types="getPledgeTypes()" />
            <span class="modal__title">Pledge Types</span>
            <br>
            <div class="modal__content">
                <div class="row justify-content-end">
                    <button v-on:click="showAddPledgeTypeModal = true" class="btn btn-add btn-add-pledge-type">Add Pledge Type</button>
                </div>
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-bind:key="index" v-for="(type, index) in pledgeTypes">
                                            <tr :class="[isOdd(index + 1) ? 'striped' : null]">
                                                <th scope="row">{{ index + 1 }}</th>
                                                <td>{{ type.name }}</td>
                                                <td>
                                                    <i v-on:click="selectPledgeTypeToEdit(type)" class="pi pi-pencil"></i>
                                                    <i v-on:click="selectPledgeTypeToDelete(type.id)" class="pi pi-trash text-danger"></i>
                                                        
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </vue-final-modal>
    </div>
</template>
<script>
import Quill from 'quill';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import addPledgeTypeModal from '@/components/modals/Pledge_Type/Add_Pledge_Type_Modal.vue'
import editPledgeTypeModal from '@/components/modals/Pledge_Type/Edit_Pledge_Type_Modal.vue'
import deletePledgeTypeModal from '@/components/modals/Pledge_Type/Delete_Pledge_Type_Modal.vue'

import { GET_PLEDGE_TYPES } from '@/store/action_types'
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'

import { Field, Form, ErrorMessage } from 'vee-validate';
import { defineRule } from 'vee-validate';
import { required, email, min } from '@vee-validate/rules';

defineRule('required', required);
defineRule('email', email);
defineRule('min', min);

export default {
    props: {
        organizationId: String
    },
    components: {
        addPledgeTypeModal,
        editPledgeTypeModal,
        deletePledgeTypeModal,
        VueFinalModal,
        ModalsContainer,
        Form,
        Field,
        ErrorMessage,
        QuillEditor
    },
    data() {
        return {
            showModal: true,
            selectedPledgeType: null,
            showAddPledgeTypeModal: false,
            showEditPledgeTypeModal: false,
            showDeletePledgeTypeModal: false,
            selectedPledgeTypeId: null,
            registerPledgeTypeObj: {
                name: null,
                org: null
            }
        }
    },
    computed: {
        errorMessage() {
            return this.$store.getters.errorMsg
        },
        account() {
            return this.$store.getters.account.data
        },
        pledgeTypes() {
            return this.$store.getters.pledgeTypes.data
        },
        pledgeTypesIsLoading() {
            return this.$store.getters.pledgeTypes.isLoading
        }
    },
    methods: {
        isOdd(number) {
            return number % 2 !== 0;
        },
        setValues() {
            this.registerPledgeTypeObj.org = this.organizationId
        },
        selectPledgeTypeToEdit(pledgeType) {
            this.showEditPledgeTypeModal = true
            this.selectedPledgeType = pledgeType
        },
        selectPledgeTypeToDelete(id) {
            this.showDeletePledgeTypeModal = true
            this.selectedPledgeTypeId = id
        },
        getPledgeTypes() {
            this.$store.dispatch(GET_PLEDGE_TYPES, { org: this.organizationId })
        }
    },
    mounted() {
        this.setValues()
        this.getPledgeTypes()
    }
}
</script>
<style scoped>
.modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    position: relative;
    left: 30%;
    margin-top: 60px;
}

.modal-content {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.2rem;
    background: #fff;
}

.btn-save {
    background-color: #14243D;
}

.btn-add-pledge-type {
    font-size: 13px;
    color: #14243D !important;
    background-color: transparent;
    border-radius: 8px;
    margin-right: 16px;
    margin-bottom: 8px;
    border: 1px solid #14243D;
}

.btn-add-pledge-type:hover {
    color: #FFFFFF !important;
    background-color: #14243D;
    border: 1px solid #14243D;
}
</style>