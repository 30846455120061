import baseAPI from '@/api/base';
import { EditSynagogue, AddSynagogue, GetSynagogue } from '../dtos/synagogue.dto';
import { DELETE_SYNAGOGUE_URL, SYNAGOGUE_URL, EDIT_SYNAGOGUE_URL, REGISTER_SYNAGOGUE_URL } from '@/utils/urls';
import { AxiosResponse } from 'axios';

class Synagogue {
    static getSynagogues = async(getSynagogue: GetSynagogue) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(SYNAGOGUE_URL, {
                    params: getSynagogue
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static registerSynagogue = async(addSynagogue: AddSynagogue) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(REGISTER_SYNAGOGUE_URL, {
                    ...addSynagogue
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static editSynagogue = async(editSynagogue: EditSynagogue) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.put(EDIT_SYNAGOGUE_URL, {
                    ...editSynagogue
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static deleteSynagogue = async(donationId: string) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.delete(`${DELETE_SYNAGOGUE_URL}/${donationId}`)

                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }
}

export default Synagogue