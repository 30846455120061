import baseAPI from '@/api/base';
import { AddPledgeItemDeletionReason, GetPledgeItemDeletionReason } from '../dtos/pledge_item_deletion_reason.dto';
import { GET_PLEDGE_ITEM_DELETION_REASONS_URL, ADD_PLEDGE_ITEM_DELETION_REASON_URL } from '@/utils/urls';
import { AxiosResponse } from 'axios';

class PledgeItemDeletionReason {
    static getPledgeItemDeletionReasons = async(getPledgeItemDeletionReason: GetPledgeItemDeletionReason) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(GET_PLEDGE_ITEM_DELETION_REASONS_URL, {
                    params: getPledgeItemDeletionReason
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static addPledgeItemDeletionReason = async(addPledgeItemDeletionReason: AddPledgeItemDeletionReason) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(ADD_PLEDGE_ITEM_DELETION_REASON_URL, {
                    ...addPledgeItemDeletionReason
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }
}

export default PledgeItemDeletionReason