<template>
    <div>
        <vue-final-modal :drag="false" drag-selector="" v-model="showModal" content-class="modal-content"
            classes="modal-container" focus-trap>
            <button class="modal__close btn btn-light" @click="$emit('closePreviewScreenImageModal')">
                <i class="pi pi-times"></i>
            </button>
            <span class="modal__title">View Image</span>
            <div class="modal__content">
                <div class="row">
                    <div class="col-12">
                        <img class="img-fluid" :src="`${STORAGE_URL}/${selectedScreenImage}`" alt="">
                    </div>
                </div>
            </div>
        </vue-final-modal>
    </div>
</template>
<script>
import { STORAGE_URL } from '@/utils/const';
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'

export default {
    props: {
        selectedScreenImage: String
    },
    components: {
        VueFinalModal,
        ModalsContainer
    },
    data() {
        return {
            STORAGE_URL,
            showModal: true
        }
    },
    computed: {
        errorMessage() {
            return this.$store.getters.errorMsg
        },
    },
    methods: {
        closeModal() {
            this.$emit('closePreviewScreenImageModal')
        }
    }
}
</script>
<style scoped>
::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    position: relative;
    left: 10%;
    margin-top: 50px;
}

::v-deep .modal-content {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.2rem;
    background: #fff;
}

.modal__title {
    margin-bottom: 10px;
}

.modal__content img {
    border-radius: 10px;
}
</style>