import axios, { AxiosResponse } from 'axios'
import { SET_PLEDGE_TYPES, SET_PLEDGE_TYPE, SET_ERRORMSG } from '../mutation_types'
import {
    DELETE_PLEDGE_TYPE,
    EDIT_PLEDGE_TYPE,
    GET_PLEDGE_TYPES,
    ADD_PLEDGE_TYPE,
    CLEAR_PLEDGE_TYPES_ISLOADING,
} from '../action_types'
import { StateObject } from '@/dtos/state.dto'
import PledgeType from '@/services/pledge_type'
import { Module } from 'vuex'
import { EditPledgeType, GetPledgeType, AddPledgeType } from '@/dtos/pledge_type.dto'

const pledgeType: Module<any, {}> = {
    state: {
        pledgeType: {
            data: null,
            isLoading: false
        },
        pledgeTypes: {
            data: null,
            isLoading: false
        }
    },
    getters: {
        pledgeType: (state: { pledgeType: any }) => state.pledgeType,
        pledgeTypes: (state: { pledgeTypes: any }) => state.pledgeTypes
    },
    mutations: {
        [SET_PLEDGE_TYPE]: (state: { pledgeType: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.pledgeType.data = payload.data : null, state.pledgeType.isLoading = payload.isLoading },
        [SET_PLEDGE_TYPES]: (state: { pledgeTypes: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.pledgeTypes.data = payload.data : null, state.pledgeTypes.isLoading = payload.isLoading }
    },
    actions: {
        [ADD_PLEDGE_TYPE]: async({ commit }: any, payload: any) => {
            commit(SET_PLEDGE_TYPE, { data: null, isLoading: true })

            let result: AxiosResponse = await PledgeType.registerPledgeType(payload as AddPledgeType)
          
            if(result) {
                if (result.data.status) {
                    commit(SET_PLEDGE_TYPE, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_PLEDGE_TYPE, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_PLEDGE_TYPE, { isLoading: false })
                return false;
            }
        },
        [GET_PLEDGE_TYPES]: async({ commit }: any, payload: any) => {
            commit(SET_PLEDGE_TYPES, { data: null, isLoading: true })

            let result: AxiosResponse = await PledgeType.getPledgeTypes(payload as GetPledgeType)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_PLEDGE_TYPES, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_PLEDGE_TYPES, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_PLEDGE_TYPES, { isLoading: false })
                return false;
            }
        },
        [EDIT_PLEDGE_TYPE]: async({ commit }: any, payload: any) => {
            commit(SET_PLEDGE_TYPE, { data: null, isLoading: true })

            let result: AxiosResponse = await PledgeType.editPledgeType(payload as EditPledgeType)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_PLEDGE_TYPE, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_PLEDGE_TYPE, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_PLEDGE_TYPE, { isLoading: false })
                return false;
            }
        },
        [DELETE_PLEDGE_TYPE]: async({ commit }: any, payload: string) => {
            commit(SET_PLEDGE_TYPE, { data: null, isLoading: true })

            let result: AxiosResponse = await PledgeType.deletePledgeType(payload)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_PLEDGE_TYPE, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_PLEDGE_TYPE, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_PLEDGE_TYPE, { isLoading: false })
                return false;
            }
        },
        [CLEAR_PLEDGE_TYPES_ISLOADING]: ({ commit }) => {
            commit(SET_PLEDGE_TYPE, { isLoading: false })
            commit(SET_PLEDGE_TYPES, { isLoading: false })
        }
    }
}

export default pledgeType