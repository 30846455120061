import { Module } from 'vuex'
import axios, { AxiosResponse } from 'axios'
import { SET_USERS, SET_ERRORMSG, SET_USER } from '../mutation_types'
import {
    REGISTER_USER,
    GET_USERS,
    CLEAR_USERS_ISLOADING,
    EDIT_USER,
    TOGGLE_USER_IS_ACTIVE,
    DELETE_USER
} from '../action_types'
import { StateObject } from '@/dtos/state.dto'
import User from '@/services/user'
import { EditUser, GetUser, RegisterUser } from '@/dtos/users.dto'

const users: Module<any, {}> = {
    state: {
        user: {
            data: null,
            isLoading: false
        },
        users: {
            data: null,
            isLoading: false
        } 
    },
    getters: {
        user: (state: { user: any }) => state.user,
        users: (state: { users: any }) => state.users
    },
    mutations: {
        [SET_USER]: (state: { user: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.user.data = payload.data : null, state.user.isLoading = payload.isLoading },
        [SET_USERS]: (state: { users: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.users.data = payload.data : null, state.users.isLoading = payload.isLoading }

    },
    actions: {
        [REGISTER_USER]: async({ commit }: any, payload: any) => {
            commit(SET_USER, { data: null, isLoading: true })

            let result: AxiosResponse = await User.registerUser(payload as RegisterUser)
          
            if(result) {
                if (result.data.status) {
                    commit(SET_USER, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_USER, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_USER, { isLoading: false })
                return false;
            }
        },
        [GET_USERS]: async({ commit }: any, payload: any) => {
            commit(SET_USERS, { data: null, isLoading: true })

            let result: AxiosResponse = await User.getUsers(payload as GetUser)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_USERS, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_USERS, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_USERS, { isLoading: false })
                return false;
            }
        },
        [EDIT_USER]: async({ commit }: any, payload: any) => {
            commit(SET_USER, { data: null, isLoading: true })

            let result: AxiosResponse = await User.editUser(payload as EditUser)
          
            if(result) {
                if (result.data.status) {
                    commit(SET_USER, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_USER, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_USER, { isLoading: false })
                return false;
            }
        },
        [TOGGLE_USER_IS_ACTIVE]: async({ commit, state }: any, payload: EditUser) => {
            commit(SET_USER, { data: { id: payload.id }, isLoading: true })

            let result: AxiosResponse = await User.toggleUserIsActive(payload as EditUser)
          
            if(result) {
                if (result.data.status) {
                    let index
                    state.users.data.forEach((e: any, i: number) => {
                        if (e.id == result.data.data.id) {
                            index = i
                        }
                    })
                    if(index != null) state.users.data[index].isActive = result.data.data.isActive

                    commit(SET_USER, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_USER, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_USER, { isLoading: false })
                return false;
            }
        },
        [DELETE_USER]: async({ commit }: any, payload: string) => {
            commit(SET_USER, { data: null, isLoading: true })

            let result: AxiosResponse = await User.deleteUser(payload)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_USER, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_USER, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_USER, { isLoading: false })
                return false;
            }
        },
        [CLEAR_USERS_ISLOADING]: ({ commit }) => {
            commit(SET_USER, { isLoading: false })
            commit(SET_USERS, { isLoading: false })
        }
    }
}

export default users