<template>
    <div style="overflow-x: hidden;">
        <div class="row">
            <div class="col-6">
                <div class="row">
                    <div class="col-8 offset-3">
                        <div class="form-section">
                            <Form @submit="login">
                                <div class="content">
                                    <br>
                                    <br>
                                    <br class="hide">
                                    <!-- <div class="row">
                                <div class="col-lg-6"> -->
                                    <img src="../../assets/images/logo_new.png" class="col-6 offset-3" alt="">
                                    <br>
                                    <div class="hide">
                                        <br>
                                        <br>
                                    </div>
                                    <br>
                                    <h4 class="title">Welcome!</h4>
                                    <br>
                                    <p class="sub-title">Today is a new day. It's your day. You shape it. <br>
                                        Sign in now.</p>
                                    <br>
                                    <p v-if="errorMessage" class="text-center text-danger">{{ errorMessage }}</p>
                                    <div class="row">
                                        <div class="col-lg-12 col-sm-12">
                                            <div class="form-group">
                                                <label for="email">Email</label>
                                                <Field v-model="loginObj.email"
                                                    :rules="{ required: ['Email'], email: ['Email'] }" type="email"
                                                    name="email" class="form-control shadow-none" id="email"
                                                    placeholder="example@email.com" />
                                                <ErrorMessage name="email" class="form-text text-danger" />
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12">
                                            <div class="form-group">
                                                <label for="password">Password</label>
                                                <Field v-model="loginObj.password" :rules="{ required: ['Password'] }"
                                                    type="password" name="password" class="form-control shadow-none"
                                                    id="password" placeholder="Password" />
                                                <ErrorMessage name="password" class="text-sm text-danger" />
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <a class="forgot-password">Forgot password?</a>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="auth-btn">
                                                <button type="submit" class="btn col-sm-12"
                                                    :disabled="isLoading ? true : false">
                                                    <span v-if="isLoading" class="spinner-border spinner-border-sm"
                                                        role="status" aria-hidden="true"></span>
                                                    <span v-if="!isLoading">Sign In</span>
                                                </button>
                                            </div>
                                            <br>
                                            <br>
                                            <div class="copyright">
                                                <span class="text-center">&copy; Copyright 2024, Persim Systems.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- </div>
                            </div> -->
                                </div>
                                <br>
                                <!-- <button>Login with Google</button> -->
                                <br>
                                <!-- <h6 class="text-center text-secondary">Forgot Password? <a href class="sign-in"><strong>Reset via Email</strong></a></h6> -->
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-6 info-section">
                <div class="content">
                    <br>
                    <br>
                    <br>
                    <br>
                    <br>
                    <br>
                    <h1 class="title">
                        <img src="../../assets/images/logo_icon.png" class="" alt=""><br>
                        <!-- Make your <br>
                        donors <br>
                        give again <br>
                        simple and <br>
                        easy! -->
                        Just Simple <br>
                        and easy!<br>
                    </h1>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Field, Form, ErrorMessage } from 'vee-validate';
import { defineRule, extend } from 'vee-validate';
import { required, email, min, email as baseEmail } from '@vee-validate/rules';
import { SIGNIN, GOOGLE_SIGNIN, LOGIN } from '@/store/action_types';
import { DASHBOARD_ROUTE } from '@/router/routes';

defineRule('email', email);
defineRule('min', min);

defineRule('required', (value, [fieldName = 'Field']) => {
    if (!value || value.length === 0) {
        return `${fieldName} is required`;
    }
    return true;
});

defineRule('email', (value, [fieldName = 'Email field']) => {
    if (!baseEmail(value)) {
        return `${fieldName} must be a valid email address`;
    }
    return true;
});

export default {
    title: 'Login',
    components: {
        Field,
        Form,
        ErrorMessage
    },
    data() {
        return {
            loginObj: {
                email: null,
                password: null
            },
        }
    },
    computed: {
        errorMessage() {
            return this.$store.getters.errorMsg
        },
        isLoading() {
            return this.$store.getters.account.isLoading
        }
    },
    methods: {
        login() {
            this.$store.dispatch(LOGIN, this.loginObj).then((res) => {
                if (res) {
                    this.$router.push({ name: DASHBOARD_ROUTE })
                }
            })
        }
    },
    created() {
        this.$nprogress.done();
    }
}
</script>
<style scoped>
.content {
    height: 100vh;
    overflow-x: hidden;
}

.title {
    font-size: 36px; 
    font-weight: 400;
}

.sub-title {
    font-size: 18px;
}

.form-group label {
    font-size: 16px;
}

.form-group input {
    border-radius: 8px;
    height: 45px;
}

input:not(:placeholder-shown) {
    background-color: transparent !important;
}


input:placeholder-shown {
    background-color: transparent !important;
}

input::placeholder {
    background-color: transparent !important;
}

.forgot-password {
    float: right;
    font-size: 16px;
    color: #6084C2;
    margin-top: 0px;
    margin-bottom: 15px;
    cursor: pointer;
}

.form-section {
    margin-left: 6%;
    margin-right: 8%;
    padding-left: 5px;
    padding-right: 5px;
}

.form-section .content {
    overflow: hidden !important;
}

.auth-btn .btn {
    font-size: 16px;
    background-color: #14243D;
    height: 55px;
    border-radius: 10px;
    color: #FFFFFF;
}

.copyright {
    display: flex;
    justify-content: center;
    align-items: center;
}

.copyright span {
    font-size: 16px;
    color: #14243D;
}

.info-section .content {
    margin-top: 3.5%;
    height: 88.5%;    
    margin-left: 15%;
    margin-right:3%;
    padding-left: 10%;
    background-color: #14243D;
}

.info-section .content img {
    height: 170px;
    margin-left: -15%!important;
}

.info-section .content .title {
    font-size: 65px;
    line-height: 65px;
    color: #FFFFFF;
}

.sign-in {
    text-decoration: underline;
    color: #052EBF;
}

.desc-section {
    padding: 20px;
    padding-right: 30px;
    color: #ffffff;
}

.desc-section .desc {
    overflow-y: auto;
    overflow-x: hidden;
    /* subtract the top 20px and bottom 20px margin from the total vh */
    height: calc(100vh - 40px);
    border-radius: 12px;
    background-color: #1137BE;
}

.desc-section .desc::-webkit-scrollbar {
    width: 10px;
}

.desc-section .desc::-webkit-scrollbar-track {
    position: relative;
    right: 50px;
}

.desc-section .desc::-webkit-scrollbar-thumb {
    background-color: #496df1;
    outline: 1px solid #052EBF;
    border-radius: 20px;
    position: relative;
    right: 50px;
}

.desc .text {
    padding: 8%;
}

.desc .title {
    font-size: 50px;
    font-weight: 700;
}

.desc .sub-title {
    font-weight: 300;
}

.desc .sub-title strong {
    font-weight: 700 !important;
}

.desc p {
    color: #F0F2FB;
}

.events {
    margin-left: 1.5rem;
    border-left: 3px solid #8D9FE0;
    list-style-type: none
}

li {
    padding-left: .5rem;
    padding-bottom: 1.5rem;
    position: relative;
}

li::before {
    content: "";
    width: 40px;
    height: 40px;
    background: #1137BE;
    border: 4px solid #FFFFFF;
    border-radius: 50%;
    position: absolute;
    left: -3.8rem;
}

@media (min-width: 1700px) {
    .info-section .content img {
        height: 200px;
        margin-left: -15% !important;
    }

    .info-section .content .title {
        font-size: 65px;
        line-height: 75px;
        color: #FFFFFF;
    }

    .form-section .title {
        font-size: 40px;
    }

    .form-section .sub-title {
        font-size: 20px;
    }
    
}

@media (max-width: 1700px) {
    .hide {
        display: none;
    }
}

</style>