<template>
    <div class="card organization-nav">
        <div class="card-body">
            <div class="row">
                <div class="">
                    <router-link :class="[[DONATION_REPORT_STATS_ROUTE, DONATION_REPORT_ROUTE].includes(route) ? 'btn-add' : null, 'btn btn-link']"
                        :to="{ name: DONATION_REPORT_STATS_ROUTE }">Donations</router-link>
                </div>
                <div class="">
                    <router-link :class="[route == REPORT_SEATS_ROUTE ? 'btn-add' : null, 'btn btn-link']"
                        :to="{ name: REPORT_SEATS_ROUTE }">Seats</router-link>
                </div>
                <div class="">
                    <router-link :class="[route == REPORT_PLEDGES_ROUTE ? 'btn-add' : null, 'btn btn-link']"
                        :to="{ name: REPORT_PLEDGES_ROUTE }">Pledges</router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { DONATION_REPORT_STATS_ROUTE, DONATION_REPORT_ROUTE, REPORT_SEATS_ROUTE, REPORT_PLEDGES_ROUTE } from '@/router/routes'

export default {
    data() {
        return {
            DONATION_REPORT_STATS_ROUTE,
            DONATION_REPORT_ROUTE,
            REPORT_SEATS_ROUTE,
            REPORT_PLEDGES_ROUTE
        }
    },
    computed: {
        route() {
            return this.$route.name
        }
    }
}
</script>
<style scoped>
.content .organization-nav {
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 15px;
    border: 1px solid #E0E2E7
}

.content .organization-nav .row {
    margin-left: 0px;
    margin-right: -8px;
}

.content .organization-nav .row>div {
    margin-right: 10px;
}

.content .organization-nav .btn {
    font-size: 16px;
    color: #667085 !important;
    text-decoration: unset;
    border-radius: 7px;
}

.dashboard-body .btn-add {
    font-weight: 600;
    color: #14243D !important;
    background-color: #F4F4F8 !important;
    border-color: #F4F4F8 !important;
    cursor: pointer;
}

.content .organization-nav .btn:hover {
    background-color: #F4F4F8;
}

.content .organization-nav .card-body {
    padding: 4px !important;
    border-radius: 8px;
}
</style>