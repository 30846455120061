<template>
    <div>
        <vue-final-modal :drag="false" drag-selector="" v-model="showModal" content-class="modal-content"
            classes="modal-container" focus-trap>
            <button class="modal__close btn btn-light" @click="$emit('closeAliasDetailModal')">
                <i class="pi pi-times"></i>
            </button>
            <span class="modal__title">Alias Detail | ({{ parshiyot }}, {{ $filters.getDateOnly(aliasDate) }})</span>
            <br>
            <div class="modal__content">
                <div class="card">
                    <div class="card-body">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Parshiyot</th>
                                    <th scope="col">Note</th>
                                    <th scope="col">Offering Type</th>
                                    <th scope="col">Member (קנה)</th>
                                    <th scope="col">Paid Amount</th>
                                    <th scope="col">Member (עלה - מי שברך)</th>
                                    <th scope="col">Offered Amount</th>
                                    <th scope="col">Alias Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-bind:key="index" v-for="(alias, index) in aliases">
                                    <tr :class="[isOdd(index + 1) ? 'striped' : null]">
                                        <th scope="row">{{ index + 1 }}</th>
                                        <td>{{ alias.parshiyot }}</td>
                                        <td>{{ alias.note }}</td>
                                        <td>{{ alias.alasOfferingType.name }}</td>
                                        <td><span v-if="alias.memberPaid">{{ alias.memberPaid.fname }}</span></td>
                                        <td>{{ $filters.formatPrice(alias.memberPaidAmount, 'USD') }}</td>
                                        <td><span v-if="alias.memberOffering">{{ alias.memberOffering.fname }}</span></td>
                                        <td>{{ $filters.formatPrice(alias.memberOfferingAmount, 'USD') }}</td>
                                        <td>{{ $filters.getDateOnly(alias.aliasDate) }}</td>
                                    </tr>  
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </vue-final-modal>
    </div>
</template>
<script>
import JewishDatePicker from '@/components/JewishDatePicker.vue';

import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'

import { GET_ALIASES } from '@/store/action_types';

export default {
    props: {
        organizationId: String,
        aliasDate: String,
        parshiyot: String
    },
    components: {
        JewishDatePicker,
        VueFinalModal,
        ModalsContainer
    },
    data() {
        return {
          showModal: true
        }
    },
    computed: {
        errorMessage() {
            return this.$store.getters.errorMsg
        },
        aliases() {
            return this.$store.getters.aliases.data
        },
        aliasesIsLoading() {
            return this.$store.getters.aliases.isLoading
        },
        account() {
            return this.$store.getters.account.data
        },
    },
    methods: {
        isOdd(number) {
            return number % 2 !== 0;
        },
        getAliases() {
            this.$store.dispatch(GET_ALIASES, { org: this.organizationId, parshiyot: this.parshiyot, aliasDate: this.aliasDate })
        }
    },
    mounted() {
        this.getAliases()
    }
}
</script>
<style scoped>
::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    position: relative;
    left: 5%;
    margin-top: 50px;
}

::v-deep .modal-content {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.2rem;
    background: #fff;
}

.modal__content .nav-tabs .nav-link {
    font-size: 15px !important;
}

.btn-add-new-item {
    border-radius: 8px;
    font-size: 13px;
    margin-top: 10px;
    color: #14243D !important;
    background-color: transparent;
    border: 1px solid #14243D;
}

.invoice-item {
    height: 50px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.add-new-badge {
    font-size: 10px;
}

.btn-delete-invoice-item {
    font-size: 15px;
}
</style>