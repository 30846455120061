<template>
    <div>
        <vue-final-modal :drag="false" drag-selector="" v-model="showModal" content-class="modal-content"
            classes="modal-container" focus-trap>
            <button class="modal__close btn btn-light" @click="$emit('closeRegisterRoomModal')">
                <i class="pi pi-times"></i>
            </button>
            <span class="modal__title">Register Minyanim Room</span>
            <br>
            <div class="modal__content">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <p v-if="errorMessage" class="text-center text-danger auth-status-msg">{{ errorMessage }}</p>
                                <Form @submit="registerRoom">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label for="name">Name</label>
                                                <Field v-model="registerRoomObj.name" rules="required" type="text"
                                                    name="name" class="form-control" id="name" placeholder="Room Name" />
                                                <ErrorMessage name="name" class="form-text text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row justify-content-end modal__actions">
                                        <button class="col-12 btn btn-save"
                                            :disabled="roomIsLoading ? true : false">
                                            <span v-if="roomIsLoading" class="spinner-border spinner-border-sm"
                                                role="status" aria-hidden="true"></span>
                                            <span v-if="!roomIsLoading"> Save</span>
                                        </button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </vue-final-modal>
    </div>
</template>
<script>
import Quill from 'quill';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import { ADD_ANNOUNCEMENT, REGISTER_ROOM } from '@/store/action_types'
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'

import { Field, Form, ErrorMessage } from 'vee-validate';
import { defineRule } from 'vee-validate';
import { required, email, min } from '@vee-validate/rules';

import { DATE_PICKER_ANNOUNCEMENT_DISPLAY_START_DATE, DATE_PICKER_ANNOUNCEMENT_DISPLAY_END_DATE } from '@/utils/const';

defineRule('required', required);
defineRule('email', email);
defineRule('min', min);

export default {
    props: {
        organizationId: String
    },
    components: {
        VueFinalModal,
        ModalsContainer,
        Form,
        Field,
        ErrorMessage,
        QuillEditor
    },
    data() {
        return {
            DATE_PICKER_ANNOUNCEMENT_DISPLAY_START_DATE,
            DATE_PICKER_ANNOUNCEMENT_DISPLAY_END_DATE,
            showModal: true,
            registerRoomObj: {
                name: null,
                org: null
            }
        }
    },
    computed: {
        errorMessage() {
            return this.$store.getters.errorMsg
        },
        room() {
            return this.$store.getters.room.data
        },
        roomIsLoading() {
            return this.$store.getters.room.isLoading
        }
    },
    methods: {
        setValues() {
            this.registerRoomObj.org = this.organizationId
        },
        registerRoom() {
            this.$store.dispatch(REGISTER_ROOM, this.registerRoomObj).then((result) => {
                if (result) {
                    this.$emit('closeRegisterRoomModal')
                    this.$emit('refreshRooms')
                }
            })
        },
    },
    mounted() {
        this.setValues()
    }
}
</script>
<style scoped>
::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    position: relative;
    left: 30%;
    margin-top: 60px;
}

::v-deep .modal-content {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.2rem;
    background: #fff;
}

.btn-save {
    background-color: #14243D;
}
</style>