import baseAPI from '@/api/base';
import { EditMemberType, AddMemberType, GetMemberType } from '../dtos/member_type.dto';
import { DELETE_MEMBER_TYPE_URL, MEMBER_TYPE_URL, EDIT_MEMBER_TYPE_URL, ADD_MEMBER_TYPE_URL } from '@/utils/urls';
import { AxiosResponse } from 'axios';

class MemberType {
    static getMemberTypes = async(getMemberType: GetMemberType) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(MEMBER_TYPE_URL, {
                    params: getMemberType
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static registerMemberType = async(addMemberType: AddMemberType) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(ADD_MEMBER_TYPE_URL, {
                    ...addMemberType
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static editMemberType = async(editMemberType: EditMemberType) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.put(EDIT_MEMBER_TYPE_URL, {
                    ...editMemberType
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static deleteMemberType = async(minyanimTypeId: string) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.delete(`${DELETE_MEMBER_TYPE_URL}/${minyanimTypeId}`)

                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }
}

export default MemberType