<template>
    <div>
        <div v-if="screenIsLoading" class="loading-container">
            <div class="loading-content">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <p>Loading Screen...</p>
            </div>
        </div>
        <div v-if="displayDonations != null" :style="backgroundStyle" class="content">
            <nav @mouseenter="showNavbar" @mouseleave="hideNavbar"
                :class="[!isNavbarVisible ? 'transparent' : null, 'navbar']">
                <a v-if="isNavbarVisible" class="navbar-brand">-</a>
                <a v-if="isNavbarVisible" class="fullscreen-toggle" v-on:click="toggleFullScreen()"><i
                        v-if="isFullScreen" class="pi pi-window-minimize"></i><i v-else
                        class="pi pi-window-maximize"></i></a>
            </nav>
            <div class="">
                <div class="daily-donations d-flex align-items-center justify-content-center"
                    v-if="dailyDonations.length > 0">
                    <div class="w3-content w3-section" style="max-width:500px">
                        <template v-bind:key="index" v-for="(donation, index) in dailyDonations">
                            <div class="">
                                <h5 class="text-center dailyDonationSlides w3-animate-top"
                                    v-html="renderContent(donation.template.content, donation)">
                                </h5>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="weekly-donations d-flex align-items-center justify-content-center"
                    v-if="weeklyDonations.length > 0">
                    <div class="w3-content w3-section" style="max-width:500px">
                        <template v-bind:key="index" v-for="(donation, index) in weeklyDonations">
                            <div class="">
                                <h5 class="text-center weeklyDonationSlides w3-animate-top"
                                    v-html="renderContent(donation.template.content, donation)">
                                </h5>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="monthly-donations d-flex align-items-center justify-content-center"
                    v-if="monthlyDonations.length > 0">
                    <div class="w3-content w3-section" style="max-width:500px">
                        <template v-bind:key="index" v-for="(donation, index) in monthlyDonations">
                            <div class="">
                                <h5 class="text-center monthlyDonationSlides w3-animate-top"
                                    v-html="renderContent(donation.template.content, donation)">
                                </h5>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="credits-container">
                    <div class="credits" @animationiteration="onAnimationIteration">
                        <div v-for="announcement in announcements" :key="announcement.id" class="credit">
                            <h5 class="text-center" v-html="announcement.message"></h5>
                        </div>
                    </div>
                    <!-- Clone of credits for seamless looping -->
                    <div class="credits" ref="creditsClone">
                        <div v-for="announcement in announcements" :key="`clone-${announcement.id}`" class="credit">
                            <h5 class="text-center" v-html="announcement.message"></h5>
                        </div>
                    </div>
                </div>
                <!-- <div class="yearly-donations d-flex align-items-center justify-content-center"
                    v-if="yearlyDonations.length > 0">
                    <div class="w3-content w3-section" style="max-width:500px">
                        <template v-bind:key="index" v-for="(donation, index) in yearlyDonations">
                            <div class="">
                                <h5 class="text-center yearlyDonationSlides w3-animate-top"
                                    v-html="renderContent(donation.template.content, donation)">
                                </h5>
                            </div>
                        </template>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>
<script>
import io from 'socket.io-client';

import { GET_DISPLAY_DONATIONS, GET_SCREEN, GET_ANNOUNCEMENTS } from '@/store/action_types'
import { DISPLAY_REFRESH_DONATION_SOCKET_EVENT, DONATION_TYPE_DAILY, DONATION_TYPE_MONTHLY, DONATION_TYPE_WEEKLY, DONATION_TYPE_YEARLY } from '@/utils/const';
import { STORAGE_URL, API_URL } from '@/utils/const';

export default {
    title: 'Display',
    data() {
        return {
            dailyDonationsIndex: 0,
            weeklyDonationsIndex: 0,
            monthlyDonationsIndex: 0,
            yearlyDonationsIndex: 0,
            isFullScreen: false,
            isNavbarVisible: false,
            socket: null
        }
    },
    computed: {
        displayDonations() {
            return this.$store.getters.displayDonations.data
        },
        dailyDonations() {
            return this.filterDonationByType(DONATION_TYPE_DAILY)
        },
        weeklyDonations() {
            return this.filterDonationByType(DONATION_TYPE_WEEKLY)
        },
        monthlyDonations() {
            return this.filterDonationByType(DONATION_TYPE_MONTHLY)
        },
        yearlyDonations() {
            return this.filterDonationByType(DONATION_TYPE_YEARLY)
        },
        account() {
            return this.$store.getters.account.data
        },
        screen() {
            return this.$store.getters.screen.data
        },
        screenIsLoading() {
            return this.$store.getters.screen.isLoading
        },
        announcements() {
            return this.$store.getters.announcements.data
        },
        announcementsIsLoading() {
            return this.$store.getters.announcements.isLoading
        },
        backgroundStyle() {
            return {
                backgroundImage: `url(${STORAGE_URL}/${this.screen != null ? this.screen.image : ''})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                height: '100vh',
                width: '100%',
                margin: '0',
                padding: '0'
            };
        }
    },
    methods: {
        filterDonationByType(type) {
            return this.displayDonations.filter((e) => { if (e.type == type) { if (e != null) return e } })
        },
        showNavbar() {
            this.isNavbarVisible = true;
        },
        hideNavbar() {
            this.isNavbarVisible = false;
        },
        onAnimationIteration() {
            const creditsOriginal = this.$refs.creditsClone;
            if (creditsOriginal) {
                creditsOriginal.style.animationPlayState = 'running';
            }
        },
        toggleFullScreen() {
            if (document.fullscreenElement) {
                // Exit fullscreen mode
                document.exitFullscreen()
                    .then(() => {
                        this.isFullScreen = false;
                    })
                    .catch((err) => {
                        console.error(`Error attempting to exit full-screen mode: ${err.message} (${err.name})`);
                    });
            } else {
                // Enter fullscreen mode
                document.documentElement.requestFullscreen()
                    .then(() => {
                        this.isFullScreen = true;
                    })
                    .catch((err) => {
                        console.error(`Error attempting to enter full-screen mode: ${err.message} (${err.name})`);
                    });
            }
        },
        dailyDonationCarousel() {
            if (this.dailyDonations.length > 0) {
                var i;
                var x = document.getElementsByClassName("dailyDonationSlides");
                for (i = 0; i < x.length; i++) {
                    x[i].style.animation = "fadeOut 10s";
                    x[i].style.display = "none";
                }
                this.dailyDonationsIndex++;
                if (this.dailyDonationsIndex > x.length) { this.dailyDonationsIndex = 1 }
                x[this.dailyDonationsIndex - 1].style.animation = "fadeIn 7s";
                x[this.dailyDonationsIndex - 1].style.display = "block";
                setTimeout(this.dailyDonationCarousel, 120000);
            }
        },
        weeklyDonationCarousel() {
            if (this.weeklyDonations.length > 0) {
                var i;
                var x = document.getElementsByClassName("weeklyDonationSlides");
                for (i = 0; i < x.length; i++) {
                    x[i].style.animation = "fadeOut 10s";
                    x[i].style.display = "none";
                }
                this.weeklyDonationsIndex++;
                if (this.weeklyDonationsIndex > x.length) { this.weeklyDonationsIndex = 1 }
                x[this.weeklyDonationsIndex - 1].style.animation = "fadeIn 7s";
                x[this.weeklyDonationsIndex - 1].style.display = "block";
                setTimeout(this.weeklyDonationCarousel, 120000);
            }
        },
        monthlyDonationCarousel() {
            if (this.monthlyDonations.length > 0) {
                var i;
                var x = document.getElementsByClassName("monthlyDonationSlides");
                for (i = 0; i < x.length; i++) {
                    x[i].style.animation = "fadeOut 10s";
                    x[i].style.display = "none";
                }
                this.monthlyDonationsIndex++;
                if (this.monthlyDonationsIndex > x.length) { this.monthlyDonationsIndex = 1 }
                x[this.monthlyDonationsIndex - 1].style.animation = "fadeIn 7s";
                x[this.monthlyDonationsIndex - 1].style.display = "block";
                setTimeout(this.monthlyDonationCarousel, 120000);
            }
        },
        yearlyDonationCarousel() {
            if (this.yearlyDonations.length > 0) {
                var i;
                var x = document.getElementsByClassName("yearlyDonationSlides");
                for (i = 0; i < x.length; i++) {
                    x[i].style.animation = "fadeOut 10s";
                    x[i].style.display = "none";
                }
                this.yearlyDonationsIndex++;
                if (this.yearlyDonationsIndex > x.length) { this.yearlyDonationsIndex = 1 }
                x[this.yearlyDonationsIndex - 1].style.animation = "fadeIn 7s";
                x[this.yearlyDonationsIndex - 1].style.display = "block";
                setTimeout(this.yearlyDonationCarousel, 120000);
            }
        },
        processBreakAtComma(rawText) {
            let result = []
            let values = rawText.split(',')
            values.forEach((e) => {
                result.push(decodeURIComponent(`<span style="display:inline-block;">${e}</span>`))
            })

            return result.join(', ')

            return rawText
        },
        renderContent(template, donation) {
            let replacements = []

            Object.keys(donation).forEach(key => {
                const regex = new RegExp(`{${key}}`, 'g');
                if (template.includes(`{${key}}`)) {
                    replacements.push({
                        replace: regex,
                        replaceBy: key == 'amount' ? this.$filters.formatPrice(donation[key], 'USD') : key == 'member' ? `${donation['member'].fname} ${donation['member'].lname}` : donation[key]
                    })
                }

            });

            replacements.forEach((e) => {
                template = template.replace(e.replace, e.replaceBy)
            })
            return template;
        },
        reloadPage() {
            window.location.reload();
        },
        initiateSocket() {
            this.socket = io(API_URL, {
                withCredentials: true,
                transports: ['websocket', 'polling'], // Try WebSockets first, then fallback to polling
            });

            this.socket.on('connect', () => {
                console.log('SOCKET CONNECTED');
            });

            this.socket.on('DISPLAY_REFRESH_DONATION_SOCKET_EVENT', (data) => {
                if (data.screen == this.$route.params.id && data.organization == this.$route.params.org) {
                    console.log('DONATION DISPLAY REFRESH TRIGGERED');
                    this.getDonations();
                    this.getAnnouncements()
                }
            });

            this.socket.on('DISPLAY_REFRESH_ANNOUNCEMENT_SOCKET_EVENT', (data) => {
                if (data.screen == this.$route.params.id && data.organization == this.$route.params.org) {
                    console.log('ANNOUNCEMENT DISPLAY REFRESH TRIGGERED');
                    this.getAnnouncements()
                }
            });
        },
        getScreen() {
            this.$store.dispatch(GET_SCREEN, { id: this.$route.params.id })
        },
        getAnnouncements() {
            this.$store.dispatch(GET_ANNOUNCEMENTS, { org: this.$route.params.org, scr: '1f3d1081-d159-4c82-b4cb-9d63dde22def', startDate: new Date(Date.now()), endDate: new Date(Date.now()), shuffle: true, isActive: true })
        },
        getDonations() {
            this.$store.dispatch(GET_DISPLAY_DONATIONS, { scr: this.$route.params.id, org: this.$route.params.org, startDate: new Date(Date.now()), endDate: new Date(Date.now()), isActive: true }).then(() => {
                this.dailyDonationCarousel();
                this.weeklyDonationCarousel();
                this.monthlyDonationCarousel();
                this.yearlyDonationCarousel();
            })
        }
    },
    mounted() {
        this.getScreen()
        this.getDonations();
        this.getAnnouncements()

        setInterval(() => { this.getDonations(); this.getAnnouncements() }, 43200000);

        // setInterval(() => { this.reloadPage() }, 43200000);

        this.hideNavbar();


        // this.dailyDonationCarousel();
        // this.weeklyDonationCarousel();
        // this.monthlyDonationCarousel();
        // this.yearlyDonationCarousel();

        this.initiateSocket()


        // setTimeout(() => {
        // this.dailyDonationCarousel();
        //     setTimeout(() => {
        //         this.weeklyDonationCarousel();
        //         setTimeout(() => {
        //             this.monthlyDonationCarousel();
        //             setTimeout(() => {
        //                 this.yearlyDonationCarousel();
        //             }, 120000);
        //         }, 120000);
        //     }, 120000);
        // }, 120000);
    },
    beforeDestroy() {
        this.socket.off(DISPLAY_REFRESH_DONATION_SOCKET_EVENT);
        this.socket.disconnect();
    }
}
</script>
<style scoped>
/*.content {
    background-image: url('../assets/images/background_img.png');
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100%;
    margin: 0;
    padding: 0;
}*/

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Full viewport height */
    width: 100%;
}

.loading-content {
    text-align: center;
}

.navbar {
    transition: top 0.3s ease-in-out;
    background-color: transparent !important;
}

.navbar .transparent {
    background-color: transparent !important;
}

.hover-area {
    position: fixed;
    top: 0;
    height: 10%;
    width: 100%;
}

.navbar .fullscreen-toggle {
    margin-right: 1%;
}

.navbar .pi {
    font-size: 22px;
    color: #5A5A57;
    cursor: pointer;
}

/* center */
.daily-donations {
    width: 30%;
    height: 28%;
    position: absolute;
    top: 40%;
    left: 35.5%;
}

/* left */
.weekly-donations {
    width: 18%;
    height: 28%;
    position: absolute;
    top: 38%;
    left: 6.4%;
}

/* right */
.monthly-donations {
    width: 18%;
    height: 28%;
    position: absolute;
    top: 38%;
    left: 75.5%;
}

/* top-right */
.yearly-donations {
    width: 30%;
    height: 28%;
    position: absolute;
    top: 18%;
    left: 64.6%;
}

.donnor-name {
    font-weight: 800;
    color: #5A5A57;
}

.ticker-wrap {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    height: 4rem;
    background-color: transparent;
    box-sizing: content-box;
}

.ticker-wrap .ticker {
    display: inline-block;
    line-height: 5rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 90s;
    animation-duration: 90s;
}

.ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 2rem;
    font-size: 18px;
    color: #000000;
}

@-webkit-keyframes ticker {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        visibility: visible;
    }

    100% {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
}

@keyframes ticker {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        visibility: visible;
    }

    100% {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
}

.center-content {
    display: flex;
    justify-content: center;
    /* Horizontally center the content */
}

.footer-banner {
    display: flex;
    justify-content: center;
    width: 100%;
    position: fixed;
    left: 0.5%;
    bottom: 2%;
}

.footer-banner h6 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.rtl {
    direction: rtl !important;
}

.credits-container {
    width: 35%;
    height: 12%;
    margin-top: 39.5%;
    margin-left: 32.5%;
    overflow: hidden;
    position: absolute;
    font-family: Arial, sans-serif;
    font-size: 14px;
    line-height: 1.4;
    background-color: transparent;
}

.credits {
    position: absolute;
    width: 100%;
    animation: scroll linear infinite;
    animation-duration: 25s;
    /* Adjust speed here */
}

.credit {
    height: 20%;
}

@keyframes scroll {
    0% {
        transform: translateX(100%); /* Start off-screen to the right */
    }
    100% {
        transform: translateX(-100%); /* Move off-screen to the left */
    }
}

.credit {
    padding: 10px;
}
</style>