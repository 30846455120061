<template>
    <div>
        <vue-final-modal :drag="false" drag-selector="" v-model="showModal" content-class="modal-content"
            classes="modal-container" focus-trap>
            <button class="modal__close btn btn-light" @click="$emit('closeDeleteMinyanimModal')">
                <i class="pi pi-times"></i>
            </button>
            <span class="modal__title">Delete Minyanim</span>
            <br>
            <div class="modal__content">
                <p v-if="errorMessage" class="text-center text-danger auth-status-msg">{{ errorMessage }}</p>
                <div class="row">
                    <div class="col-12">
                        <h6>Are you sure you want to delete this minyanim?</h6>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-lg-2 offset-lg-6">
                        <button v-on:click="deleteMinyanim()" class="btn text-danger"
                            :disabled="minyanimIsLoading ? true : false">
                            <span v-if="minyanimIsLoading" class="spinner-border spinner-border-sm" role="status"
                                aria-hidden="true"></span>
                            <span v-if="!minyanimIsLoading">Confirm</span>
                        </button>
                    </div>
                    <div class="col-lg-2 offset-lg-1">
                        <button v-on:click="$emit('closeDeleteMinyanimModal')" class="btn">
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </vue-final-modal>
    </div>
</template>
<script>
import { DELETE_MEMBER, DELETE_MINYANIM, GET_MEMBERS } from '@/store/action_types'
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'

import { Field, Form, ErrorMessage } from 'vee-validate';
import { defineRule } from 'vee-validate';
import { required, email, min } from '@vee-validate/rules';
import Minyanim from '../../../services/minyanim';
import minyanim from '../../../store/modules/minyanim';

defineRule('required', required);
defineRule('email', email);
defineRule('min', min);

export default {
    props: {
        selectedMinyanimId: String
    },
    components: {
        VueFinalModal,
        ModalsContainer,
        Form,
        Field,
        ErrorMessage
    },
    data() {
        return {
            showModal: true
        }
    },
    computed: {
        errorMessage() {
            return this.$store.getters.errorMsg
        },
        minyanim() {
            return this.$store.getters.minyanim.data
        },
        minyanimIsLoading() {
            return this.$store.getters.minyanim.isLoading
        }
    },
    methods: {
        deleteMinyanim() {
            this.$store.dispatch(DELETE_MINYANIM, this.selectedMinyanimId).then((res) => {
                if (res) {
                    this.$emit('closeDeleteMinyanimModal')
                    this.$emit('refreshMinyanim')
                }
            })
        }
    }
}
</script>
<style scoped>
::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    position: relative;
    left: 35%;
    margin-top: 50px;
}

::v-deep .modal-content {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.2rem;
    background: #fff;
}

button {
    font-size: 15px;
}
</style>