<template>
    <div>
        <vue-final-modal :drag="false" drag-selector="" v-model="showModal" content-class="modal-content"
            classes="modal-container" focus-trap>
            <button class="modal__close btn btn-light" @click="$emit('closeViewAnnouncementModal')">
                <i class="pi pi-times"></i>
            </button>
            <span class="modal__title">View Announcement</span>
            <br>
            <div class="modal__content">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">
                                <span v-html="selectedAnnouncementToView.message"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
            </div>
        </vue-final-modal>
    </div>
</template>
<script>
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'

import { Field, Form, ErrorMessage } from 'vee-validate';
import { defineRule } from 'vee-validate';
import { required, email, min } from '@vee-validate/rules';

defineRule('required', required);
defineRule('email', email);
defineRule('min', min);

export default {
    props: {
        selectedAnnouncementToView: Object,
    },
    components: {
        VueFinalModal,
        ModalsContainer,
        Form,
        Field,
        ErrorMessage
    },
    data() {
        return {
            showModal: true
        }
    },
    watch: {
        'editDonationObj.type': function (newValue, oldValue) {
            this.updateDonationDisplayEndDate();
        },
        'editDonationObj.donationDisplayStartDate': function (newValue, oldValue) {
            this.updateDonationDisplayEndDate();
        }
    },
    computed: {
        errorMessage() {
            return this.$store.getters.errorMsg
        },
        templates() {
            return this.$store.getters.templates.data
        },
        templatesIsLoading() {
            return this.$store.getters.templates.isLoading
        },
        donation() {
            return this.$store.getters.donation.data
        },
        donationIsLoading() {
            return this.$store.getters.donation.isLoading
        }
    },
    methods: {
        renderContent(template, donation) {
            let content = '';
            let replacements = []

            if (template) {
                content = template.content
                Object.keys(donation).forEach(key => {
                    const regex = new RegExp(`{${key}}`, 'g');
                    if (content) {
                        if (content.includes(`{${key}}`)) {
                            replacements.push({
                                replace: regex,
                                replaceBy: key == 'amount' ? this.$filters.formatPrice(donation[key], 'USD') : key == 'member' ? `${donation['member'].fname} ${donation['member'].lname}` : donation[key]
                            })
                        }
                    }

                });

                replacements.forEach((e) => {
                    content = content.replace(e.replace, e.replaceBy)
                })
            }
            return content;
        }
    },
    created() {
    }
}
</script>
<style scoped>
::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    position: relative;
    left: 30%;
    margin-top: 50px;
}

::v-deep .modal-content {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.2rem;
    background: #fff;
}

.modal__title {
    font-size: 18px;
    font-weight: 600;
}

.modal__close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
}

.modal__content .nav-tabs .nav-link {
    font-size: 15px !important;
}

.modal__content .card {
    height: 100%;
}

.btn-close {
    border-radius: 10px;
    font-size: 13px;
    font-weight: 600;
    width: 75%;
    background-color: #CAB781;

}

.appointment-card {
    margin-top: 5px;
}

.appointment-card .card-body {
    padding: 0px 5px 0px 5px;
}

.appointment-card .customer {
    font-size: 15px;
}

.appointment-card .badge {
    margin-left: 10px;
    margin-right: 5px;
}

label {
    font-size: 13px;
}

input {
    height: 35px !important;
    font-size: 13px;
}

input::placeholder {
    font-size: 13px;
}

select {
    height: 35px !important;
    font-size: 13px;
}

select::placeholder {
    font-size: 13px;
}

.form-check {
    position: relative;
    display: block;
    margin-top: 25px;
    margin-bottom: 0.75rem;
    padding-left: 0px;
}

.form-check-toggle {
    position: relative;
    padding-left: 0;
    line-height: 30px;
}

.form-check-toggle input {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 0%;
    margin: 0;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
}

.form-check-toggle input+span {
    cursor: pointer;
    user-select: none;
    height: 30px;
    margin-left: 70px;
    display: block;
}

.form-check-toggle input+span:before {
    content: "";
    position: absolute;
    left: 0;
    display: inline-block;
    height: 30px;
    width: 50px;
    background: #F0F5FC;
    border: solid 1px #D4D3D3;
    transition: background 0.3s ease-in-out, border-color 0.3s ease-in-out;
    border-radius: 7px;
}

.form-check-toggle input+span:after {
    width: 28px;
    height: 28px;
    margin-top: 1px;
    margin-left: 1px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    background: #fff;
    transition: margin-left 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    text-align: center;
    font-weight: bold;
    content: "";
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2), 0 0 5px rgba(0, 0, 0, 0.05);
}

.form-check-toggle input:checked+span:after {
    content: "";
    margin-left: 21px;
    box-shadow: none;
}

.form-check-toggle input:checked+span:before {
    background-color: #CAB781;
    border-color: #CAB781;
    transition: background 0.3s ease-in-out, border-color 0.3s ease-in-out;
}
</style>