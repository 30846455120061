export default {
    methods: {
      validateMimeTypes(files: any[], allowedTypes: string[]) {
        const invalidFiles = files.filter(file => !allowedTypes.includes(file.type));
        if (invalidFiles.length > 0) {
          return { valid: false, invalidFiles };
        }
        return { valid: true, invalidFiles: [] };
      }
    }
  }