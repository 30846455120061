<template>
    <div>
        <vue-final-modal :drag="false" drag-selector="" v-model="showModal" content-class="modal-content"
            classes="modal-container" focus-trap>
            <button class="modal__close btn btn-light" @click="$emit('closeRegisterInvoiceModal')">
                <i class="pi pi-times"></i>
            </button>
            <span class="modal__title">Register Statement</span>
            <br>
            <div class="modal__content">
                <addMemberModal v-if="showAddMemberModal" :organizationId="organizationId"
                    @close-add-member-modal="showAddMemberModal = false" @refresh-members="getMembers()" />
                <JewishDatePicker v-if="currentlyShowingJewDatePicker == DATE_PICKER_INVOICE_ITEM_DUE_DATE"
                    @select-date="selectInvoiceItemDueDate" @close-date-picker-modal="closeModal()" />
                <div class="row">
                    <div class="col-12">
                        <p v-if="errorMessage" class="text-center text-danger auth-status-msg">{{ errorMessage }}</p>
                        <Form @submit="registerInvoice">
                            <div class="card">
                                <div class="card-body">
                                    <h5 class="title">General Info</h5>
                                    <div class="row">
                                        <!-- <div class="col-3">
                                            <div class="form-group">
                                                <label for="title">Title</label>
                                                <Field v-model="newInvoiceObj.title" rules="required" type="text"
                                                    name="title" class="form-control" id="title"
                                                    placeholder="Enter Invoice Title" />
                                                <ErrorMessage name="title" class="form-text text-danger" />
                                            </div>
                                        </div> -->
                                        <div v-if="memberId == null" class="col-3">
                                            <div class="form-group">
                                                <label for="currency">Member</label>
                                                <select v-model="newInvoiceObj.memb" class="form-control"
                                                    rules="required">
                                                    <option>---</option>
                                                    <template v-bind:key="index" v-for="(member, index) in members">
                                                        <option :value="member">{{ member.fname }} {{ member.lname }}
                                                        </option>
                                                    </template>
                                                </select>
                                                <ErrorMessage name="currency" class="form-text text-danger" />
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <div class="form-group">
                                                <label for="dueDate">Due Date</label>
                                                <Field v-model="newInvoiceObj.dueDate" rules="required" type="date"
                                                    name="dueDate" class="form-control" id="dueDate" placeholder="" />
                                                <ErrorMessage name="dueDate" class="form-text text-danger" />
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <div class="form-group">
                                                <label for="currency">Currency</label>
                                                <select v-model="newInvoiceObj.currency" class="form-control"
                                                    rules="required">
                                                    <option>---</option>
                                                    <template v-bind:key="index"
                                                        v-for="(currency, index) in CURRENCIES">
                                                        <option :value="currency">{{ currency }}</option>
                                                    </template>
                                                </select>
                                                <ErrorMessage name="currency" class="form-text text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br>
                            <div class="card">
                                <div class="card-body">
                                    <h5 class="title">Items</h5>
                                    <div class="row">
                                        <div class="col-10">
                                            <div class="row">
                                                <div class="col-3">
                                                    <div class="form-group">
                                                        <label for="name">Name</label>
                                                    </div>
                                                </div>
                                                <div class="col-3">
                                                    <div class="form-group">
                                                        <label for="price">Unit Price</label>
                                                    </div>
                                                </div>
                                                <div class="col-3">
                                                    <div class="form-group">
                                                        <label for="quatity">Quantity</label>
                                                    </div>
                                                </div>
                                                <div class="col-3">
                                                    <div class="form-group">
                                                        <label for="dueDate">Due Date</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-2">
                                            <div class="form-group">
                                                <label for="amount">Total</label>
                                            </div>
                                        </div>
                                    </div>
                                    <template v-bind:key="index" v-for="(item, index) in invoiceItems">
                                        <div class="row invoice-item">
                                            <div class="col-10">
                                                <div class="row">
                                                    <div class="col-3">
                                                        <div class="form-group">
                                                            <!-- <label for="name">Name</label> -->
                                                            <Field v-model="invoiceItems[index].name" rules="required"
                                                                type="text" :name="`invoiceItemName${index}`"
                                                                class="form-control" id="name"
                                                                placeholder="Item Name" />
                                                            <ErrorMessage :name="`invoiceItemName${index}`"
                                                                class="form-text text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="col-3">
                                                        <div class="form-group">
                                                            <!-- <label for="amount">Unit Price</label> -->
                                                            <Field v-model="invoiceItems[index].price" rules="required"
                                                                type="number" min="0.1" step="0.01"
                                                                :name="`invoiceItemPrice${index}`" class="form-control"
                                                                id="amount" placeholder="0.00" />
                                                            <ErrorMessage :name="`invoiceItemPrice${index}`"
                                                                class="form-text text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="col-3">
                                                        <div class="form-group">
                                                            <!-- <label for="amount">Quantity</label> -->
                                                            <Field v-model="invoiceItems[index].quantity"
                                                                rules="required" type="number" min="1" step="1"
                                                                :name="`invoiceItemQuantity${index}`"
                                                                class="form-control" id="amount" placeholder="0" />
                                                            <ErrorMessage :name="`invoiceItemQuantity${index}`"
                                                                class="form-text text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="col-3">
                                                        <div @click="showInvoiceItemDueDatePicker(index)"
                                                            class="form-group">
                                                            <!-- <label for="donationDisplayEndDate">Display End Date</label> -->
                                                            <div class="row date-picker-row">
                                                                <div class="col-10">
                                                                    <Field v-model="invoiceItems[index].dueDate"
                                                                        rules="required" disabled type="text"
                                                                        :name="`invoiceItemDueDate${index}`"
                                                                        class="form-control" id="itemDueDate"
                                                                        placeholder="Due Date" />
                                                                </div>
                                                                <div class="col-2">
                                                                    <i class="pi pi-calendar-plus text-primary"></i>
                                                                </div>
                                                            </div>
                                                            <ErrorMessage :name="`invoiceItemDueDate${index}`"
                                                                class="form-text text-danger" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-1">
                                                <small>{{ $filters.formatPrice(invoiceItems[index].price *
                                                    invoiceItems[index].quantity, newInvoiceObj.currency ?? 'USD')
                                                    }}</small>
                                            </div>
                                            <div v-if="invoiceItems.length > 1" class="col">
                                                <a v-on:click="deleteInvoiceItem(index)"
                                                    class="btn btn-link btn-delete-invoice-item"><i
                                                        class="pi pi-trash text-danger"></i></a>
                                            </div>
                                        </div>
                                    </template>
                                    <a v-on:click="addInvoiceItem()" class="btn btn-add-new-item">Add New Item</a>
                                </div>
                            </div>
                            <br>
                            <div class="row justify-content-end modal__actions">
                                <button v-on:click="closeInvoiceModal()" class="col-1 btn btn-close">
                                    <img src="../../../assets/icons/close-icon.svg" alt=""> Cancel
                                </button>
                                <button type="submit" class="col-1 btn btn-save"
                                    :disabled="invoiceIsLoading ? true : false">
                                    <span v-if="invoiceIsLoading" class="spinner-border spinner-border-sm" role="status"
                                        aria-hidden="true"></span>
                                    <span v-if="!invoiceIsLoading"><img src="../../../assets/icons/save-icon.svg"
                                            alt=""> Save</span>
                                </button>
                            </div>
                        </Form>
                    </div>
                    <!-- <div class="col-6">
                        <br>
                        <div class="card">
                            <div class="card-body">
                                <span v-html="renderContent(newInvoiceObj.title, newInvoiceObj)"></span>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </vue-final-modal>
    </div>
</template>
<script>
import addMemberModal from '@/components/modals/Member/Add_Member_Modal.vue'
import JewishDatePicker from '@/components/JewishDatePicker.vue';

import { GET_MEMBERS, GET_SCREENS, GET_TEMPLATES, REGISTER_DONATION, REGISTER_INVOICE } from '@/store/action_types'
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'

import { Field, Form, ErrorMessage } from 'vee-validate';
import { defineRule } from 'vee-validate';
import { required, email, min } from '@vee-validate/rules';
import { DONTATION_TYPE, DONATION_TITLES, DONATION_TYPE_YEARLY, DONATION_TYPE_MONTHLY, DONATION_TYPE_WEEKLY, DONATION_TYPE_DAILY, DATE_PICKER_DONATION_DATE, DATE_PICKER_DONATION_DISPLAY_START_DATE, DATE_PICKER_INVOICE_ITEM_DUE_DATE, USER_ROLE_ADMIN } from '@/utils/const';
import { CURRENCIES } from '@/utils/const';

defineRule('required', required);
defineRule('email', email);
defineRule('min', min);

export default {
    props: {
        organizationId: String,
        memberId: String
    },
    components: {
        addMemberModal,
        JewishDatePicker,
        VueFinalModal,
        ModalsContainer,
        Form,
        Field,
        ErrorMessage
    },
    data() {
        return {
            DONTATION_TYPE,
            DONATION_TITLES,
            DONATION_TYPE_YEARLY,
            DONATION_TYPE_MONTHLY,
            DONATION_TYPE_WEEKLY,
            DONATION_TYPE_DAILY,
            DATE_PICKER_DONATION_DATE,
            DATE_PICKER_DONATION_DISPLAY_START_DATE,
            DATE_PICKER_INVOICE_ITEM_DUE_DATE,
            CURRENCIES,
            selectedInvoiceItemIndex: 0,
            currentlyShowingJewDatePicker: null,
            showModal: true,
            showAddMemberModal: false,
            newInvoiceObj: {
                title: null,
                currency: null,
                totalAmount: null,
                dueDate: null,
                invoiceItems: null,
                memb: null,
                org: null
            },
            invoiceItems: [{
                name: null,
                quantity: 1,
                price: null,
                dueDate: null
            }],
            donationDate: null
        }
    },
    computed: {
        errorMessage() {
            return this.$store.getters.errorMsg
        },
        account() {
            return this.$store.getters.account.data
        },
        invoice() {
            return this.$store.getters.invoice.data
        },
        invoiceIsLoading() {
            return this.$store.getters.invoice.isLoading
        },
        members() {
            return this.$store.getters.members.data
        },
        membersIsLoading() {
            return this.$store.getters.members.isLoading
        },
        account() {
            return this.$store.getters.account.data
        },
    },
    methods: {
        // selectDonationDate(selectedDate) {
        //     this.donationDate = selectedDate
        //     this.newInvoiceObj.donationDate = this.$filters.convertHDateToGregorian(selectedDate)
        //     this.closeModal()
        // },
        showInvoiceItemDueDatePicker(index) {
            this.selectedInvoiceItemIndex = index
            this.currentlyShowingJewDatePicker = DATE_PICKER_INVOICE_ITEM_DUE_DATE
        },
        selectInvoiceItemDueDate(selectedDate) {
            // this.invoiceItems[selectedInvoiceItemIndex].dueDate = this.$filters.convertHDateToGregorian(selectedDate)
            this.invoiceItems[this.selectedInvoiceItemIndex].dueDate = selectedDate
        },
        addInvoiceItem() {
            this.invoiceItems.push({
                name: null,
                quantity: 1,
                price: null,
                dueDate: null
            })
        },
        deleteInvoiceItem(index) {
            if (this.invoiceItems.length > 1) {
                this.invoiceItems.splice(index, 1)
            }
        },
        setValues() {
            this.newInvoiceObj.org = this.organizationId ?? this.account.org
            this.newInvoiceObj.memb = this.memberId
        },
        closeModal() {
            this.currentlyShowingJewDatePicker = null;
        },
        closeInvoiceModal() {
            this.$emit('closeRegisterInvoiceModal')
        },
        getMembers() {
            if (this.account.role == USER_ROLE_ADMIN) {
                this.$store.dispatch(GET_MEMBERS, { org: this.organizationId })
            } else {
                this.$store.dispatch(GET_MEMBERS, { org: this.account.org })
            }
        },
        caluclateTotalAmount() {
            return this.invoiceItems.reduce((total, item) => {
                return total + (item.price * item.quantity);
            }, 0);
        },
        registerInvoice() {
            this.newInvoiceObj.invoiceItems = this.invoiceItems.map((e) => {
                const updatedItem = {
                    ...e,
                    dueDate: this.$filters.convertHDateToGregorian(e.dueDate)
                };
                return updatedItem;
            })

            if (this.memberId == null) {
                this.newInvoiceObj.memb = this.newInvoiceObj.memb.id
            }
            this.newInvoiceObj.totalAmount = this.caluclateTotalAmount()

            this.$store.dispatch(REGISTER_INVOICE, this.newInvoiceObj ).then((result) => {
                if (result) {
                    this.$emit('refreshInvoices')
                    this.$emit('closeRegisterInvoiceModal')
                }
            })
        },

    },
    created() {
        this.getMembers()
        this.setValues()
    }
}
</script>
<style scoped>
::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    position: relative;
    left: 5%;
    margin-top: 50px;
}

::v-deep .modal-content {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.2rem;
    background: #fff;
}

.modal__content .nav-tabs .nav-link {
    font-size: 15px !important;
}

.btn-add-new-item {
    border-radius: 8px;
    font-size: 13px;
    margin-top: 10px;
    color: #14243D !important;
    background-color: transparent;
    border: 1px solid #14243D;
}

.invoice-item {
    height: 50px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.add-new-badge {
    font-size: 10px;
}

.btn-delete-invoice-item {
    font-size: 15px;
}
</style>