import axios, { AxiosResponse } from 'axios'
import { SET_ALIASES, SET_ALIAS, SET_ERRORMSG, SET_GROUPED_ALIASES } from '../mutation_types'
import {
    CLEAR_ALIASES_ISLOADING,
    DELETE_ALIAS,
    EDIT_ALIAS,
    GET_ALIASES,
    ADD_ALIAS,
    GET_GROUPED_ALIASES
} from '../action_types'
import { StateObject } from '@/dtos/state.dto'
import Alias from '@/services/alias'
import { Module } from 'vuex'
import { EditAlias, GetAlias, RegisterAlias } from '@/dtos/alias.dto'

const alias: Module<any, {}> = {
    state: {
        alias: {
            data: null,
            isLoading: false
        },
        aliases: {
            data: null,
            isLoading: false
        },
        groupedAliases: {
            data: null,
            isLoading: false
        }
    },
    getters: {
        alias: (state: { alias: any }) => state.alias,
        aliases: (state: { aliases: any }) => state.aliases,
        groupedAliases: (state: { groupedAliases: any }) => state.groupedAliases
    },
    mutations: {
        [SET_ALIAS]: (state: { alias: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.alias.data = payload.data : null, state.alias.isLoading = payload.isLoading },
        [SET_ALIASES]: (state: { aliases: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.aliases.data = payload.data : null, state.aliases.isLoading = payload.isLoading },
        [SET_GROUPED_ALIASES]: (state: { groupedAliases: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.groupedAliases.data = payload.data : null, state.groupedAliases.isLoading = payload.isLoading }
    },
    actions: {
        [ADD_ALIAS]: async({ commit }: any, payload: any) => {
            commit(SET_ALIAS, { data: null, isLoading: true })

            let result: AxiosResponse = await Alias.registerAlias(payload as RegisterAlias)
          
            if(result) {
                if (result.data.status) {
                    commit(SET_ALIAS, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_ALIAS, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_ALIAS, { isLoading: false })
                return false;
            }
        },
        [GET_ALIASES]: async({ commit }: any, payload: any) => {
            commit(SET_ALIASES, { data: null, isLoading: true })

            let result: AxiosResponse = await Alias.getAlias(payload as GetAlias)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_ALIASES, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_ALIASES, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_ALIASES, { isLoading: false })
                return false;
            }
        },
        [GET_GROUPED_ALIASES]: async({ commit }: any, payload: any) => {
            commit(SET_GROUPED_ALIASES, { data: null, isLoading: true })

            let result: AxiosResponse = await Alias.getGroupedAlias(payload as GetAlias)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_GROUPED_ALIASES, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_GROUPED_ALIASES, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_GROUPED_ALIASES, { isLoading: false })
                return false;
            }
        },
        [EDIT_ALIAS]: async({ commit }: any, payload: any) => {
            commit(SET_ALIAS, { data: null, isLoading: true })

            let result: AxiosResponse = await Alias.editAlias(payload as EditAlias)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_ALIAS, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_ALIAS, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_ALIAS, { isLoading: false })
                return false;
            }
        },
        [DELETE_ALIAS]: async({ commit }: any, payload: string) => {
            commit(SET_ALIAS, { data: null, isLoading: true })

            let result: AxiosResponse = await Alias.deleteAlias(payload)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_ALIAS, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_ALIAS, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_ALIAS, { isLoading: false })
                return false;
            }
        },
        [CLEAR_ALIASES_ISLOADING]: ({ commit }) => {
            commit(SET_ALIAS, { isLoading: false })
            commit(SET_ALIASES, { isLoading: false })
        }
    }
}

export default alias