<template>
    <div class="dashboard-body">
        <div class="row">
            <sidenav class="custom-col-width-2" />
            <div style="padding-left: 0px!important" class="custom-col-width-10 content-section dashboard-content">
                <navbar />
                <registerInvoiceModal v-if="showRegisterInvoiceModal" :organizationId="organizationId"
                    @close-register-invoice-modal="showRegisterInvoiceModal = false"
                    @refresh-invoices="getInvoices()" />
                <deleteInvoiceModal v-if="showDeleteInvoiceModal" :selectedInvoiceId="selectedInvoiceId"
                    @close-delete-invoice-modal="showDeleteInvoiceModal = false" @refresh-invoices="getInvoices()" />
                <invoiceItemsModal v-if="showInvoiceItemsModal" :selectedInvoice="selectedInvoice"
                    @close-invoice-items-modal="showInvoiceItemsModal = false" />
                <memberPurposeModal v-if="showMemberPurposeModal" :selectedMember="selectedMember"
                    @close-member-purpose-modal="showMemberPurposeModal = false" />
                <div class="content">
                    <br>
                    <div class="row button-grp justify-content-between">
                        <div class="col">
                            <h4>Organization</h4>
                        </div>
                        <div class="col">
                            <button v-on:click="showRegisterInvoiceModal = true" class="btn btn-add"><i
                                    class="pi pi-plus"></i> Create Statement</button>
                        </div>
                    </div>
                    <div class="row justify-content-between">
                        <organizationNavbar class="" />
                    </div>
                    <div class="row filter-grp">
                        <div class="col-lg-9 col-md-9">
                            <i class="pi pi-search" aria-hidden="true"></i>
                            <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
                        </div>
                        <div class="col-lg-2 col-md-2">
                            <div class="row">
                                <div class="btn col-lg-7 col-md-10" style="display: block"><img
                                        src="../../assets/icons/filter-icon.svg" alt=""> Filters</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card">
                                <div class="card-body">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Invoice No.</th>
                                                <th scope="col">Total Amount</th>
                                                <th scope="col">Member</th>
                                                <th scope="col">Due Date</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">File</th>
                                                <th scope="col">Created By</th>
                                                <th scope="col">Created At</th>
                                                <th scope="col">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-bind:key="index" v-for="(invoice, index) in invoices">
                                                <tr :class="[isOdd(index + 1) ? 'striped' : null]">
                                                    <th scope="row">{{ index + 1 }}</th>
                                                    <td>#{{ invoice.invoiceNumber }}</td>
                                                    <td>{{ $filters.formatPrice(invoice.totalAmount, invoice.currency)
                                                        }}</td>
                                                    <td><span v-if="invoice.member">{{ invoice.member.fname }} {{
                                                            invoice.member.lname }}</span></td>
                                                    <td>{{ $filters.getDateOnly(invoice.dueDate) }}</td>
                                                    <td>{{ $filters.formatPrice(invoice.totalAmountPaid,
                                                        invoice.currency) }} <span class="text-info"> ({{
                                                            $filters.round((invoice.totalAmountPaid /
                                                            invoice.totalAmount) * 100 )}}%)</span></td>
                                                    <td><i v-on:click="openFile(invoice.file)"
                                                            class="pi pi-eye text-primary text-center"></i></td>
                                                    <td>{{ invoice.user.fname }}</td>
                                                    <td>{{ $filters.getDateOnly(invoice.createdAt) }}</td>
                                                    <td>
                                                        <i v-on:click="selectInvoice(invoice)"
                                                            class="pi pi-external-link text-primary"></i>
                                                        <i v-on:click="selectInvoiceToDelete(invoice.id)"
                                                            class="pi pi-trash text-danger"></i>
                                                    </td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <br>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import sidenav from '@/components/Sidenav.vue'
import navbar from '@/components/Navbar.vue'
import organizationNavbar from '@/components/Organization_Navbar.vue'
import registerInvoiceModal from '@/components/modals/Invoice/Register_Invoice_Modal.vue'
import invoiceItemsModal from '@/components/modals/Invoice/Invoice_Items_Modal.vue'
import deleteInvoiceModal from '@/components/modals/Invoice/Delete_Invoice_Modal.vue'
import memberPurposeModal from '@/components/modals/Member/Member_Purpose_Modal.vue'
import { GET_INVOICES, GET_MEMBERS } from '@/store/action_types'
import { STORAGE_URL } from '@/utils/const'

export default {
    title: 'Members',
    components: {
        sidenav,
        navbar,
        registerInvoiceModal,
        invoiceItemsModal,
        deleteInvoiceModal,
        memberPurposeModal,
        organizationNavbar
    },
    data() {
        return {
            showRegisterInvoiceModal: false,
            showInvoiceItemsModal: false,
            showDeleteInvoiceModal: false,
            showMemberPurposeModal: false,
            selectedMember: null,
            selectedInvoice: null,
            selectedInvoiceId: null,
            organizationId: null
        }
    },
    computed: {
        route() {
            return this.$route.name
        },
        isLoading() {
            return this.$store.getters.organizations.isLoading
        },
        organizations() {
            return this.$store.getters.organizations.data
        },
        organization() {
            return this.$store.getters.organization.data
        },
        actingMember() {
            return this.$store.getters.member.data
        },
        actingMemberIsLoading() {
            return this.$store.getters.member.isLoading
        },
        invoices() {
            return this.$store.getters.invoices.data
        },
        invoicesIsLoading() {
            return this.$store.getters.invoices.isLoading
        },
        account() {
            return this.$store.getters.account.data
        }
    },
    methods: {
        isOdd(number) {
            return number % 2 !== 0;
        },
        async openFile(file) {
            window.open(`${STORAGE_URL}/${file}`, '_blank');
        },
        selectInvoice(invoice) {
            this.showInvoiceItemsModal = true
            this.selectedInvoice = invoice
        },
        selectInvoiceToDelete(id) {
            this.selectedInvoiceId = id
            this.showDeleteInvoiceModal = true
        },
        selectInvoicePurpose(member) {
            this.selectedMember = member
            this.showMemberPurposeModal = true
        },
        toggleMemberIsActive(id) {
            this.$store.dispatch(TOGGLE_MEMBER_IS_ACTIVE, { id })
        },
        setOrganization() {
            this.organizationId = this.$route.params.id
        },
        getInvoices() {
            this.$store.dispatch(GET_INVOICES, { org: this.$route.params.id })
        }
    },
    mounted() {
        this.getInvoices()
        this.setOrganization()
    }
}
</script>
<style scoped>
.content .card,
.content .card-body {
    padding: 0px;
}

.custom-col-width-2 {
    width: 16.66%;
}

.custom-col-width-10 {
    width: 83.3%;
}

@media (max-width: 1000px) {
    .custom-col-width-2 {
        width: 12%;
    }

    .custom-col-width-10 {
        width: 88%;
    }
}
</style>