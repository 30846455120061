<template>
    <div class="dashboard-body">
        <div class="row">
            <sidenav class="custom-col-width-2" />
            <div style="padding-left: 0px!important" class="custom-col-width-10 content-section dashboard-content">
                <navbar />
                <viewNotificationMemberModal v-if="showViewNotificationMemberModal"
                    :selectedMemberToView="selectedMemberToView"
                    @close-view-notification-member-modal="showViewNotificationMemberModal = false" />
                <div class="content">
                    <br>
                    <div class="row button-grp justify-content-between">
                        <div class="col">
                            <h4>Notifications</h4>
                        </div>
                    </div>
                    <div class="row justify-content-between">
                        <organizationNavbar class="" />
                    </div>
                    <!-- <div class="row filter-grp">
                        <div class="col-lg-9 col-md-7">
                            <i class="pi pi-search" aria-hidden="true"></i>
                            <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
                        </div>
                        <div class="col-lg-3 col-md-5">
                            <div class="row">
                                <div class="btn col"><img src="../../assets/icons/calendar-icon.svg" alt=""> Select Date
                                </div>
                                <div class="btn col-6"><img src="../../assets/icons/filter-icon.svg" alt="">
                                    Filters</div>
                            </div>
                        </div>
                    </div> -->
                    <div v-if="allNotificationsIsLoading && allNotifications == null">
                        <div class="row">
                            <div class="col-10 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#DFDFFAFF'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-10 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-10 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-10 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-10 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-10 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-10 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-10 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-10 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                        </div>
                    </div>
                    <div v-else class="row">
                        <div class="col-lg-10 offset-lg-0">
                            <div class="card">
                                <div class="card-body">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Purpose</th>
                                                <th scope="col">Member</th>
                                                <th scope="col">Event Date</th>
                                                <!-- <th scope="col">Status</th> -->
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-bind:key="index"
                                                v-for="(notification, index) in allNotifications">
                                                <tr :class="[isOdd(index + 1) ? 'striped' : null]">
                                                    <th scope="row">{{ index + 1 }}</th>
                                                    <td>{{ notification.purpose.name }}</td>
                                                    <td>{{ notification.member.fname }} {{ notification.member.lname }}
                                                        <i v-on:click="selectMember(notification.member)"
                                                            class="pi pi-info-circle text-primary"></i>
                                                    </td>
                                                    <td><span class="frankRuhl-font">{{
                                                            $filters.convertToHebrewDate(notification.purpose.eventDate)
                                                            }}</span></td>
                                                    <!-- <td>{{ notification.isSeen }}</td> -->
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <br>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { ContentLoader } from 'vue-content-loader';

import sidenav from '@/components/Sidenav.vue'
import navbar from '@/components/Navbar.vue'
import organizationNavbar from '@/components/Organization_Navbar.vue'
import viewNotificationMemberModal from '@/components/modals/Notification/View_Notification_Member_Modal.vue'
import { GET_ALL_NOTIFICATIONS } from '@/store/action_types'
import { USER_ROLE_ADMIN } from '@/utils/const';

export default {
    title: 'Templates',
    components: {
        sidenav,
        navbar,
        organizationNavbar,
        viewNotificationMemberModal,
        ContentLoader
    },
    data() {
        return {
            USER_ROLE_ADMIN,
            showViewNotificationMemberModal: false,
            selectedMemberToView: null,
            organizationId: null
        }
    },
    computed: {
        allNotificationsIsLoading() {
            return this.$store.getters.allNotifications.isLoading
        },
        allNotifications() {
            return this.$store.getters.allNotifications.data
        },
        account() {
            return this.$store.getters.account.data
        }
    },
    methods: {
        isOdd(number) {
            return number % 2 !== 0;
        },
        setValues() {
            this.organizationId = this.$route.params.id
        },
        selectMember(member) {
            this.selectedMemberToView = member
            this.showViewNotificationMemberModal = true
        },
        getNotifications() {
            this.$store.dispatch(GET_ALL_NOTIFICATIONS, { org: this.$route.params.id })
        }
    },
    mounted() {
        this.setValues()
        this.getNotifications()
    }
}
</script>
<style scoped>
.content .card,
.content .card-body {
    padding: 0px;
}

.content .card table small {
    margin-left: 10px;
    margin-right: 10px;
}

.content .card table .pi-eye,
.content .card table .pi-trash,
.content .card table .pi-pencil {
    cursor: pointer;
}

.custom-col-width-2 {
    width: 16.66%;
}

.custom-col-width-10 {
    width: 83.3%;
}

.pi-info-circle {
    margin-left: 10px;
    cursor: pointer;
}

@media (max-width: 1000px) {
    .custom-col-width-2 {
        width: 12%;
    }

    .custom-col-width-10 {
        width: 88%;
    }
}
</style>