import axios, { AxiosResponse } from 'axios'
import { SET_SYNAGOGUE_SEAT_PRICINGS, SET_SYNAGOGUE_SEAT_PRICING, SET_ERRORMSG } from '../mutation_types'
import {
    CLEAR_SYNAGOGUE_SEAT_PRICINGS_ISLOADING,
    DELETE_SYNAGOGUE_SEAT_PRICING,
    EDIT_SYNAGOGUE_SEAT_PRICING,
    GET_SYNAGOGUE_SEAT_PRICINGS,
    ADD_SYNAGOGUE_SEAT_PRICING,
} from '../action_types'
import { StateObject } from '@/dtos/state.dto'
import SynagogueSeatPricing from '@/services/synagogue_seat_pricing'
import { Module } from 'vuex'
import { EditSynagogueSeatPricing, AddSynagogueSeatPricing, GetSynagogueSeatPricing } from '@/dtos/synagogue_seat_pricing.dto'

const synagogueSeatPricing: Module<any, {}> = {
    state: {
        synagogueSeatPricing: {
            data: null,
            isLoading: false
        },
        synagogueSeatPricings: {
            data: null,
            isLoading: false
        }
    },
    getters: {
        synagogueSeatPricing: (state: { synagogueSeatPricing: any }) => state.synagogueSeatPricing,
        synagogueSeatPricings: (state: { synagogueSeatPricings: any }) => state.synagogueSeatPricings
    },
    mutations: {
        [SET_SYNAGOGUE_SEAT_PRICING]: (state: { synagogueSeatPricing: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.synagogueSeatPricing.data = payload.data : null, state.synagogueSeatPricing.isLoading = payload.isLoading },
        [SET_SYNAGOGUE_SEAT_PRICINGS]: (state: { synagogueSeatPricings: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.synagogueSeatPricings.data = payload.data : null, state.synagogueSeatPricings.isLoading = payload.isLoading }
    },
    actions: {
        [ADD_SYNAGOGUE_SEAT_PRICING]: async({ commit }: any, payload: any) => {
            commit(SET_SYNAGOGUE_SEAT_PRICING, { data: null, isLoading: true })

            let result: AxiosResponse = await SynagogueSeatPricing.registerSynagogueSeatPricing(payload as AddSynagogueSeatPricing)
          
            if(result) {
                if (result.data.status) {
                    commit(SET_SYNAGOGUE_SEAT_PRICING, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_SYNAGOGUE_SEAT_PRICING, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_SYNAGOGUE_SEAT_PRICING, { isLoading: false })
                return false;
            }
        },
        [GET_SYNAGOGUE_SEAT_PRICINGS]: async({ commit }: any, payload: any) => {
            commit(SET_SYNAGOGUE_SEAT_PRICINGS, { data: null, isLoading: true })

            let result: AxiosResponse = await SynagogueSeatPricing.getSynagogueSeatPricings(payload as GetSynagogueSeatPricing)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_SYNAGOGUE_SEAT_PRICINGS, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_SYNAGOGUE_SEAT_PRICINGS, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_SYNAGOGUE_SEAT_PRICINGS, { isLoading: false })
                return false;
            }
        },
        [EDIT_SYNAGOGUE_SEAT_PRICING]: async({ commit }: any, payload: any) => {
            commit(SET_SYNAGOGUE_SEAT_PRICING, { data: null, isLoading: true })

            let result: AxiosResponse = await SynagogueSeatPricing.editSynagogueSeatPricing(payload as EditSynagogueSeatPricing)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_SYNAGOGUE_SEAT_PRICING, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_SYNAGOGUE_SEAT_PRICING, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_SYNAGOGUE_SEAT_PRICING, { isLoading: false })
                return false;
            }
        },
        [DELETE_SYNAGOGUE_SEAT_PRICING]: async({ commit }: any, payload: string) => {
            commit(SET_SYNAGOGUE_SEAT_PRICING, { data: null, isLoading: true })

            let result: AxiosResponse = await SynagogueSeatPricing.deleteSynagogueSeatPricing(payload)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_SYNAGOGUE_SEAT_PRICING, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_SYNAGOGUE_SEAT_PRICING, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_SYNAGOGUE_SEAT_PRICING, { isLoading: false })
                return false;
            }
        },
        [CLEAR_SYNAGOGUE_SEAT_PRICINGS_ISLOADING]: ({ commit }) => {
            commit(SET_SYNAGOGUE_SEAT_PRICING, { isLoading: false })
            commit(SET_SYNAGOGUE_SEAT_PRICINGS, { isLoading: false })
        }
    }
}

export default synagogueSeatPricing