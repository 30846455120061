<template>
    <div class="dashboard-body">
        <div class="row">
            <sidenav class="custom-col-width-2" />
            <div style="padding-left: 0px!important" class="custom-col-width-10 content-section dashboard-content">
                <navbar />
                <registerOrganizationModal v-if="showRegisterOrganizationModal"
                    @close-register-organization-modal="showRegisterOrganizationModal = false"
                    @refresh-organizations="getOrganizations()" />
                <editOrganizationModal v-if="showEditOrganizationModal" :selectedOrganization="selectedOrganization"
                    @close-edit-template-modal="showEditOrganizationModal = false"
                    @refresh-organizations="getOrganizations()" />
                <deleteOrganizationModal v-if="showDeleteOrganizationModal"
                    :selectedOrganizationId="selectedOrganizationId"
                    @close-delete-template-modal="showDeleteOrganizationModal = false"
                    @refresh-organizations="getOrganizations()" />
                <div class="content">
                    <br>
                    <!-- <div class="row">
                        <div class="col-lg-2 offset-lg-10">
                            
                        </div>
                    </div> -->
                    <div class="row button-grp justify-content-between">
                        <div class="col">
                            <h4>Organizations</h4>
                        </div>
                        <div class="col">
                            <button v-on:click="showRegisterOrganizationModal = true" class="btn btn-add"><i
                                    class="pi pi-plus"></i> Register Organization</button>
                            <button v-on:click="showRegisterOrganizationModal = true" class="btn btn-export"><i
                                    class="pi pi-download"></i> Export</button>
                        </div>
                    </div>
                    <div class="row filter-grp">
                        <div class="col-lg-9 col-md-7">
                            <i class="pi pi-search" aria-hidden="true"></i>
                            <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
                        </div>
                        <div class="col-lg-3 col-md-5">
                            <div class="row">
                                <div class="btn col"><img src="../../assets/icons/calendar-icon.svg" alt=""> Select Date
                                </div>
                                <div class="btn col"><img
                                        src="../../assets/icons/filter-icon.svg" alt="">
                                    Filters</div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-lg-12 offset-lg-0">
                            <div class="card">
                                <div class="card-body">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Phone</th>
                                                <th scope="col">City/State</th>
                                                <th v-if="account.role == USER_ROLE_ADMIN" scope="col">Status</th>
                                                <th scope="col">Created At</th>
                                                <th scope="col">Created By</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-bind:key="index" v-for="(organization, index) in organizations">
                                                <tr :class="[ isOdd(index + 1) ? 'striped' : null ]">
                                                    <th scope="row">{{ index + 1 }}</th>
                                                    <td>{{ organization.name }}</td>
                                                    <td>{{ organization.email }}</td>
                                                    <td>{{ organization.phoneNumber }}</td>
                                                    <td>{{ organization.city }}, {{ organization.state }}</td>
                                                    <td v-if="account.role == USER_ROLE_ADMIN">
                                                        <label
                                                            v-if="organization.id != account.org && account.role == USER_ROLE_ADMIN"
                                                            v-on:click="toggleOrganizationIsActive(organization.id)"
                                                            class="form-switch">
                                                            <input
                                                                v-if="!(actingOrganization != null && actingOrganizationIsLoading && actingOrganization.id == organization.id)"
                                                                type="checkbox" :checked="organization.isActive">
                                                            <span v-else class="spinner-border spinner-border-sm"
                                                                role="status" aria-hidden="true"></span>
                                                            <i></i>
                                                        </label>
                                                        <h2 v-else class="text-secondary"> - </h2>
                                                    </td>
                                                    <td>{{ $filters.getDateOnly(organization.createdAt) }}</td>
                                                    <td>{{ organization.user.fname }}</td>
                                                    <td><a v-on:click="routeToOrganizationDetails(organization)"
                                                            class="btn btn-link"><i class="pi pi-eye"></i></a></td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <br>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import sidenav from '@/components/Sidenav.vue'
import navbar from '@/components/Navbar.vue'
import registerOrganizationModal from '@/components/modals/Organization/Register_Organization_Modal.vue'
// import editOrganizationModal from '@/components/modals/Edit_Organization_Modal.vue'
// import deleteOrganizationModal from '@/components/modals/Delete_Organization_Modal.vue'
import { GET_ORGANIZATIONS, TOGGLE_ORGANIZATION_IS_ACTIVE } from '@/store/action_types'
import { ORGANIZATION_DETAILS_ROUTE } from '@/router/routes'
import { USER_ROLE_ADMIN } from '@/utils/const';

export default {
    title: 'Organizations',
    components: {
        sidenav,
        navbar,
        registerOrganizationModal
        // deleteOrganizationModal,
        // addOrganizationModal,
    },
    data() {
        return {
            USER_ROLE_ADMIN,
            showRegisterOrganizationModal: false,
            showEditOrganizationModal: false,
            showDeleteOrganizationModal: false,
            selectedOrganization: null,
            selectedOrganizationId: null
        }
    },
    computed: {
        isLoading() {
            return this.$store.getters.organizations.isLoading
        },
        organizations() {
            return this.$store.getters.organizations.data
        },
        actingOrganization() {
            return this.$store.getters.organization.data
        },
        actingOrganizationIsLoading() {
            return this.$store.getters.organization.isLoading
        },
        account() {
            return this.$store.getters.account.data
        }
    },
    methods: {
        isOdd(number) {
            return number % 2 !== 0;
        },
        selectOrganizationToEdit(template) {
            this.selectedOrganization = template
            this.showEditOrganizationModal = true
        },
        selectOrganizationToDelete(templateId) {
            this.selectedOrganizationId = templateId
            this.showDeleteOrganizationModal = true
        },
        getOrganizations() {
            this.$store.dispatch(GET_ORGANIZATIONS)
        },
        switchOrganizationView(type) {
            this.selectedView = type
        },
        toggleOrganizationIsActive(id) {
            this.$store.dispatch(TOGGLE_ORGANIZATION_IS_ACTIVE, { id })
        },
        routeToOrganizationDetails(organization) {
            this.$router.push({ name: ORGANIZATION_DETAILS_ROUTE, params: { id: organization.id } })
        }
    },
    mounted() {
        this.getOrganizations()
    }
}
</script>
<style scoped>
.content .card,
.content .card-body {
    padding: 0px;
    border-radius: 12px;
    border: 0px solid transparent;
}

.content .filter-grp {
    margin-top: 10px;
    margin-right: 0px;
}

.content .filter-grp input {
    height: 40px;
    font-size: 14px;
    border-radius: 8px;
    padding-left: 38px;
    color: #858D9D;
    border-color: #E0E2E7;
}

.content .filter-grp .pi-search {
    position: absolute;
    left: 30px;
    top: 14px;
    font-size: 14px;
    display: flex;
    align-items: center;
    color: #858D9D !important
}

.content .filter-grp .btn {
    height: 40px;
    font-size: 14px;
    padding-top: 9px;
    border-radius: 8px;
    color: #858D9D;
    border-color: #E0E2E7;
    background-color: #FFFFFF;
}

.content .filter-grp .btn:first-child {
    margin-right: 15px;
}

.content .filter-grp .btn img {
    height: 15px;
    margin-right: 8px;
    margin-bottom: 2px;
}

.content .filter-grp input::placeholder {
    font-size: 14px;
    color: #858D9D;
}

.content .btn-add {
    float: right;
    font-size: 14px;
    border-radius: 8px;
    color: #FFFFFF!important;
    padding: 10px, 14px, 10px, 14px;
    background-color: #14243D;
    border-color: #14243D!important;
}

.content .btn-add .pi-plus,
.content .btn-export .pi-download {
    font-size: 13px;
    margin-right: 8px;
}

.content .btn-export {
    float: right;
    font-size: 14px;
    border-radius: 8px;
    margin-right: 15px;
    color: #14243D !important;
    padding: 10px, 14px, 10px, 14px;
    background-color: #EEEEF5;
    border-color: #EEEEF5 !important;
}

.content .card .table thead {
    border-radius: 12px!important;
    border-color: transparent;
    padding: 0px;
    background-color: #FFFFFF;
}

.content .card .table thead th {
    border-color: transparent;
}

.content .card .table thead tr th {
    font-weight: 500;
    color: #1D1F2C;
    font-size: 16px;
}

.content .card .table thead tr th:first-child {
    border-top-left-radius: 12px !important;
}

.content .card .table thead tr th:last-child {
    border-top-right-radius: 12px !important;
}

.content .card table thead {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: #F4ECE2;
}

.content .card table .btn-link {
    color: #A3A9B6;
    padding-top: 0px;
    padding-bottom: 0px;
}

.content .card table tr td {
    padding-top: 13px;
    padding-bottom: 0px;
    font-size: 14px;
}

.content .btn-group .btn {
    border-color: #8b7433!important;
    color: #000000!important;
}

.content .btn-group .btn-selected {
    border-color: #8b7433!important;
    color: #000000!important;
    background-color: #CAB781!important;
}

.content .card table .striped {
    background-color: #F9F9FC!important;
}

.content .card table tbody tr:hover {
    background-color: #F9F9FC!important;
}

.content .card table small {
    margin-left: 10px;
    margin-right: 10px;
}

.content .card table .pi-eye,
.content .card table .pi-trash,
.content .card table .pi-pencil {
    cursor: pointer;
}

.custom-col-width-2 {
    width: 16.66%;
}

.custom-col-width-10 {
    width: 83.3%;
}

@media (max-width: 1000px) {
    .custom-col-width-2 {
        width: 12%;
    }

    .custom-col-width-10 {
        width: 88%;
    }
}
</style>