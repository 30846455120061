import axios, { AxiosResponse } from 'axios'
import { SET_DASHBOARD_STATS, SET_ERRORMSG } from '../mutation_types'
import {
    GET_DASHBOARD_STATS,
    CLEAR_DONATION_ISLOADING
} from '../action_types'
import { StateObject } from '@/dtos/state.dto'
import Stats from '@/services/stats'
import { Module } from 'vuex'
import { DashboardStatsQuery } from '@/dtos/stats.dto'

const stats: Module<any, {}> = {
    state: {
        dashboardStats: {
            data: null,
            isLoading: false
        }
    },
    getters: {
        dashboardStats: (state: { dashboardStats: any }) => state.dashboardStats
    },
    mutations: {
        [SET_DASHBOARD_STATS]: (state: { dashboardStats: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.dashboardStats.data = payload.data : null, state.dashboardStats.isLoading = payload.isLoading }
    },
    actions: {
        [GET_DASHBOARD_STATS]: async({ commit }: any, payload: any) => {
            commit(SET_DASHBOARD_STATS, { data: null, isLoading: true })

            let result: AxiosResponse = await Stats.getDashboardStats(payload as DashboardStatsQuery)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_DASHBOARD_STATS, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_DASHBOARD_STATS, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_DASHBOARD_STATS, { isLoading: false })
                return false;
            }
        },
        [CLEAR_DONATION_ISLOADING]: ({ commit }) => {
            commit(SET_DASHBOARD_STATS, { isLoading: false })
        }
    }
}

export default stats