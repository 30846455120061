import axios, { AxiosResponse } from 'axios'
import { SET_MEMBER_TYPES, SET_MEMBER_TYPE, SET_ERRORMSG } from '../mutation_types'
import {
    CLEAR_MEMBER_TYPES_ISLOADING,
    DELETE_MEMBER_TYPE,
    EDIT_MEMBER_TYPE,
    GET_MEMBER_TYPES,
    ADD_MEMBER_TYPE,
} from '../action_types'
import { StateObject } from '@/dtos/state.dto'
import MemberType from '@/services/member_type'
import { Module } from 'vuex'
import { EditMemberType, AddMemberType, GetMemberType } from '@/dtos/member_type.dto'

const memberType: Module<any, {}> = {
    state: {
        memberType: {
            data: null,
            isLoading: false
        },
        memberTypes: {
            data: null,
            isLoading: false
        }
    },
    getters: {
        memberType: (state: { memberType: any }) => state.memberType,
        memberTypes: (state: { memberTypes: any }) => state.memberTypes
    },
    mutations: {
        [SET_MEMBER_TYPE]: (state: { memberType: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.memberType.data = payload.data : null, state.memberType.isLoading = payload.isLoading },
        [SET_MEMBER_TYPES]: (state: { memberTypes: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.memberTypes.data = payload.data : null, state.memberTypes.isLoading = payload.isLoading }
    },
    actions: {
        [ADD_MEMBER_TYPE]: async({ commit }: any, payload: any) => {
            commit(SET_MEMBER_TYPE, { data: null, isLoading: true })

            let result: AxiosResponse = await MemberType.registerMemberType(payload as AddMemberType)
          
            if(result) {
                if (result.data.status) {
                    commit(SET_MEMBER_TYPE, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_MEMBER_TYPE, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_MEMBER_TYPE, { isLoading: false })
                return false;
            }
        },
        [GET_MEMBER_TYPES]: async({ commit }: any, payload: any) => {
            commit(SET_MEMBER_TYPES, { data: null, isLoading: true })

            let result: AxiosResponse = await MemberType.getMemberTypes(payload as GetMemberType)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_MEMBER_TYPES, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_MEMBER_TYPES, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_MEMBER_TYPES, { isLoading: false })
                return false;
            }
        },
        [EDIT_MEMBER_TYPE]: async({ commit }: any, payload: any) => {
            commit(SET_MEMBER_TYPE, { data: null, isLoading: true })

            let result: AxiosResponse = await MemberType.editMemberType(payload as EditMemberType)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_MEMBER_TYPE, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_MEMBER_TYPE, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_MEMBER_TYPE, { isLoading: false })
                return false;
            }
        },
        [DELETE_MEMBER_TYPE]: async({ commit }: any, payload: string) => {
            commit(SET_MEMBER_TYPE, { data: null, isLoading: true })

            let result: AxiosResponse = await MemberType.deleteMemberType(payload)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_MEMBER_TYPE, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_MEMBER_TYPE, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_MEMBER_TYPE, { isLoading: false })
                return false;
            }
        },
        [CLEAR_MEMBER_TYPES_ISLOADING]: ({ commit }) => {
            commit(SET_MEMBER_TYPE, { isLoading: false })
            commit(SET_MEMBER_TYPES, { isLoading: false })
        }
    }
}

export default memberType