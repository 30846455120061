<template>
    <div class="dashboard-body">
        <div class="row">
            <sidenav class="custom-col-width-2" />
            <div style="padding-left: 0px!important" class="custom-col-width-10 content-section dashboard-content">
                <navbar />
                <viewDonationTemplateModal v-if="showViewDonationModal" :selectedDonationToView="selectedDonationToView"
                    @close-view-donation-modal="showViewDonationModal = false" />
                <registerDonationModal v-if="showRegisterDonationModal" :organizationId="organizationId"
                    :memberId="memberId" @close-register-donation-modal="showRegisterDonationModal = false"
                    @refresh-donations="getMemberDonations()" />
                <editDonationModal v-if="showEditDonationModal" :selectedDonation="selectedDonation"
                    @close-edit-donation-modal="showEditDonationModal = false"
                    @refresh-donations="getMemberDonations()" />
                <deleteDonationModal v-if="showDeleteDonationModal" :selectedDonationId="selectedDonationId"
                    @close-delete-donation-modal="showDeleteDonationModal = false"
                    @refresh-donations="getMemberDonations()" />
                <div class="content">
                    <br>
                    <div class="row button-grp justify-content-between">
                        <div class="col">
                            <h4>Member <small v-if="route.params.name">({{ route.params.name }})</small></h4>
                        </div>
                        <div class="col">
                            <button v-on:click="showRegisterDonationModal = true" class="btn btn-add"> <i
                                    class="pi pi-plus"></i> Register Donation</button>
                        </div>
                    </div>
                    <div class="row justify-content-between">
                        <memberNavbar class="" />
                        <h5 v-if="memberDonations" class="total-donations">Total: {{
                            $filters.formatPrice(memberDonations.total, 'USD') }}</h5>
                    </div>
                    <br>
                    <div v-if="memberDonationsIsLoading && memberDonations == null">
                        <div class="row">
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#DFDFFAFF'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                        </div>
                    </div>
                    <div v-else class="row">
                        <div class="col-lg-12">
                            <div class="card">
                                <div class="card-body">
                                    <table v-if="memberDonations" class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <!-- <th scope="col">Title</th> -->
                                                <th scope="col">Donor</th>
                                                <th scope="col">Amount</th>
                                                <th scope="col">Purpose</th>
                                                <th scope="col">Screen</th>
                                                <th scope="col">Template</th>
                                                <th scope="col">Donation Date</th>
                                                <th scope="col">Display Date</th>
                                                <th scope="col">Display</th>
                                                <th scope="col">Registered By</th>
                                                <th scope="col">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-bind:key="index" v-for="(donation, index) in memberDonations.donations">
                                                <tr :class="[isOdd(index + 1) ? 'striped' : null]">
                                                    <th scope="row">{{ index + 1 }}</th>
                                                    <!-- <td><span v-html="renderContent(donation.template.content, donation)"></span></td> -->
                                                    <td>{{ donation.member.fname }} {{ donation.member.lname }}</td>
                                                    <td>{{ $filters.formatPrice(donation.amount, 'USD') }}</td>
                                                    <td>{{ donation.purpose }}</td>
                                                    <td>{{ donation.organizationScreen != null ?
                                                        donation.organizationScreen.name : donation.screen.name }}</td>
                                                    <td>{{ donation.template.name }}</td>
                                                    <td><span class="frankRuhl-font">{{
                                                            $filters.convertToHebrewDate(donation.donationDate) }}</span></td>
                                                    <td><span class="frankRuhl-font">{{
                                                            $filters.convertToHebrewDate(donation.donationDisplayStartDate)
                                                            }}</span>
                                                        <small> | </small>
                                                        <span class="frankRuhl-font">{{
                                                            $filters.convertToHebrewDate(donation.donationDisplayEndDate)
                                                            }}</span>
                                                    </td>
                                                    <td>
                                                        <label v-on:click="toggleDonationIsActive(donation.id)"
                                                            class="form-switch">
                                                            <input
                                                                v-if="!(actingDonation != null && actingDonationIsLoading && actingDonation.id == donation.id)"
                                                                type="checkbox" :checked="donation.isActive">
                                                            <span v-else class="spinner-border spinner-border-sm" role="status"
                                                                aria-hidden="true"></span>
                                                            <i></i>
                                                        </label>
                                                    </td>
                                                    <td>{{ donation.user.fname }}</td>
                                                    <td>
                                                        <i v-on:click="selectDontationToView(donation)"
                                                            class="pi pi-eye text-secondary"></i>
                                                        <i v-on:click="selectDontationToEdit(donation)"
                                                            class="pi pi-pencil text-primary"></i>
                                                        <i v-on:click="selectDontationToDelete(donation.id)"
                                                            class="pi pi-trash text-danger"></i>
                                                    </td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <br>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { ContentLoader } from 'vue-content-loader';

import sidenav from '@/components/Sidenav.vue'
import navbar from '@/components/Navbar.vue'
import memberNavbar from '@/components/Member_Navbar.vue'
import viewDonationTemplateModal from '@/components/modals/Donation/View_Donation_Template_Modal.vue'
import registerDonationModal from '@/components/modals/Donation/Register_Donation_Modal.vue'
import editDonationModal from '@/components/modals/Donation/Edit_Donation_Modal.vue'
import deleteDonationModal from '@/components/modals/Donation/Delete_Donation_Modal.vue'
import { GET_MEMBER_DONATIONS } from '@/store/action_types'
import { USER_ROLE_ADMIN } from '@/utils/const';

export default {
    title: 'Details',
    components: {
        sidenav,
        navbar,
        memberNavbar,
        editDonationModal,
        deleteDonationModal,
        registerDonationModal,
        viewDonationTemplateModal,
        ContentLoader
    },
    data() {
        return {
            USER_ROLE_ADMIN,
            showRegisterDonationModal: false,
            showEditDonationModal: false,
            showDeleteDonationModal: false,
            showViewDonationModal: false,
            selectedDonation: null,
            selectedDonationId: null,
            selectedDonationToView: null,
            selectedMember: null,
            selectedMemberId: null,
            organizationId: null,
            memberId: null
        }
    },
    computed: {
        route() {
            return this.$route
        },
        actingDonation() {
            return this.$store.getters.donation.data
        },
        actingDonationIsLoading() {
            return this.$store.getters.donation.isLoading
        },
        memberDonationsIsLoading() {
            return this.$store.getters.memberDonations.isLoading
        },
        memberDonations() {
            return this.$store.getters.memberDonations.data
        },
        account() {
            return this.$store.getters.account.data
        }
    },
    methods: {
        isOdd(number) {
            return number % 2 !== 0;
        },
        selectDontationToEdit(donation) {
            this.selectedDonation = donation
            this.showEditDonationModal = true
        },
        selectDontationToDelete(donationId) {
            this.selectedDonationId = donationId
            this.showDeleteDonationModal = true
        },
        selectDontationToView(donation) {
            this.selectedDonationToView = donation
            this.showViewDonationModal = true
        },
        renderContent(template, donation) {
            let replacements = []

            Object.keys(donation).forEach(key => {
                const regex = new RegExp(`{${key}}`, 'g');
                if (template.includes(`{${key}}`)) {
                    replacements.push({
                        replace: regex,
                        replaceBy: donation[key]
                    })
                }

            });

            replacements.forEach((e) => {
                template = template.replace(e.replace, e.replaceBy)
            })
            return template;
        },
        setValues() {
            this.organizationId = this.$route.params.orgId
            this.memberId = this.$route.params.membId
        },
        toggleDonationIsActive(id) {
            this.$store.dispatch(TOGGLE_DONATION_IS_ACTIVE, { id })
        },
        getMemberDonations() {
            this.$store.dispatch(GET_MEMBER_DONATIONS, { memb: this.$route.params.membId })
        }
    },
    mounted() {
        this.setValues()
        this.getMemberDonations()
    }
}
</script>
<style scoped>
.content .card,
.content .card-body {
    padding: 0px;
}

.content .info-card {
    margin-bottom: 20px;
}

.content .card .card-header {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: #F4ECE2;
}

.content .card .header {
    font-weight: 700;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #1D1F2C;
}

.content .card .card-content {
    padding: 15px 35px 15px 35px;
}

.content .card .card-content>.row {
    margin-bottom: 7px
}

.content .card .card-content .title {
    font-weight: 600;
    color: #1D1F2C;
}

.content .card .card-content .title img {
    height: 40px !important;
    margin-right: 6px;
}

.content .card .card-content .value {
    font-weight: 600;
    color: #1D1F2C;
    margin-top: 6px
}

.content .card table small {
    margin-left: 10px;
    margin-right: 10px;
}

.content .card table .pi-trash,
.content .card table .pi-pencil {
    cursor: pointer;
}

/*.content .organization-nav .btn {
    color: #5A5A57;
    padding: 0px;
    border-radius: 0px;
    text-decoration: unset;
    margin-left: 10px;
    font-size: 18px;
}

.content .organization-nav .btn:hover,
.content .organization-nav .btn-selected {
    padding-bottom: 5px;
    color: #cf9904;
    border-bottom: 2px solid #cf9904;
}
*/

.content .total-donations {
    margin-top: 20px;   
    margin-right: 18px;
}

.content .organization-nav .card-body {
    padding: 15px !important;
}

.custom-col-width-2 {
    width: 16.66%;
}

.custom-col-width-10 {
    width: 83.3%;
}

@media (max-width: 1000px) {
    .custom-col-width-2 {
        width: 12%;
    }

    .custom-col-width-10 {
        width: 88%;
    }
}
</style>