import baseAPI from '@/api/base';
import { EditOrganizationScreen, RegisterOrganizationScreen, GetOrganizationScreen } from '../dtos/organization_screen.dto';
import { DELETE_ORGANIZATION_SCREEN_URL, ORGANIZATION_SCREEN_URL, EDIT_ORGANIZATION_SCREEN_URL, ADD_ORGANIZATION_SCREEN_URL } from '@/utils/urls';
import { AxiosResponse } from 'axios';

class OrganizationScreen {
    static getOrganizationScreens = async(getOrganizationScreen: GetOrganizationScreen) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(ORGANIZATION_SCREEN_URL, {
                    params: getOrganizationScreen
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static registerOrganizationScreen = async(registerOrganizationScreen: RegisterOrganizationScreen) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(ADD_ORGANIZATION_SCREEN_URL, {
                    ...registerOrganizationScreen
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static editOrganizationScreen = async(editOrganizationScreen: EditOrganizationScreen) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.put(EDIT_ORGANIZATION_SCREEN_URL, {
                    ...editOrganizationScreen
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static deleteOrganizationScreen = async(donationId: string) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.delete(`${DELETE_ORGANIZATION_SCREEN_URL}/${donationId}`)

                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }
}

export default OrganizationScreen