<template>
    <div class="dashboard-body">
        <div class="row">
            <sidenav class="custom-col-width-2" />
            <div style="padding-left: 0px!important" class="custom-col-width-10 content-section dashboard-content">
                <navbar />
                <donationReportFilesModal v-if="showDonationReportFilesModal"
                    @close-donation-report-files-modal="showDonationReportFilesModal = false" />
                <memberDetailsModal v-if="showMemberDetailsModal" :selectedMember="selectedMember"
                    @close-show-member-details-modal="showMemberDetailsModal = false" />
                <div class="content">
                    <br>
                    <div class="row button-grp justify-content-between">
                        <div class="col">
                            <h4>Reports</h4>
                        </div>
                        <!-- <div class="col">
                            <button v-on:click="selectOrganizationToEdit()" class="btn btn-add">Edit</button>
                        </div> -->
                    </div>
                    <div class="row justify-content-between">
                        <div class="">
                            <reportsNavbar class="" />
                        </div>
                        <div class="col">
                            <router-link :to="{ name: DONATION_REPORT_STATS_ROUTE }" class="btn btn-view-stats">View
                                Stats</router-link>
                        </div>
                    </div>
                    <div class="row filter-grp">
                        <div class="col-lg-7 col-md-7">
                            <i class="pi pi-search" aria-hidden="true"></i>
                            <input class="form-control mr-sm-2" type="search" placeholder="Search member"
                                aria-label="Search">
                        </div>
                        <div class="col-lg-3 col-md-5">
                            <div class="row">
                                <div class="btn col"><img src="../../../assets/icons/calendar-icon.svg" alt=""> Select
                                    Date
                                </div>
                                <div class="btn col"><img src="../../../assets/icons/filter-icon.svg" alt="">
                                    Filters</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="card table-card">
                                <div class="card-body">
                                    <button v-on:click="generateDonationReportFile()" type="submit"
                                        class="btn btn-print" :disabled="donationReportFileIsLoading ? true : false">
                                        <span v-if="donationReportFileIsLoading"
                                            class="spinner-border spinner-border-sm" role="status"
                                            aria-hidden="true"></span>
                                        <span v-if="!donationReportFileIsLoading"><i class="pi pi-print"></i>
                                            Print</span>
                                    </button>
                                    <button v-on:click="showGeneratedFiles()"
                                        class="btn btn-view-generated-files">Generated Reports</button>
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <!-- <th scope="col">#</th> -->
                                                <!-- <th scope="col">Title</th> -->
                                                <th scope="col">Donor</th>
                                                <th scope="col">Phone</th>
                                                <th scope="col">Amount</th>
                                                <th scope="col">Donation Date</th>
                                                <th scope="col" class="d-none d-lg-block">Payment Date</th>
                                                <!-- <th scope="col">Action</th> -->
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-bind:key="index" v-for="(donation, index) in donationReport">
                                                <tr>
                                                    <!-- <th scope="row">{{ index + 1 }}</th> -->
                                                    <!-- <td><span v-html="renderContent(donation.template.content, donation)"></span></td> -->
                                                    <!-- <td><span v-if="donation.member">{{ donation.member.displayName
                                                            }}</span></td> -->
                                                    <td><span v-if="donation.member">{{ donation.member.fname }} {{ donation.member.lname }} <i v-on:click="showMemberDetail(donation.member)"
                                                        class="pi pi-info-circle"></i></span></td>
                                                    <td><span v-if="donation.member">{{ donation.member.phoneNumber }}</span></td>
                                                    <td>{{ $filters.formatPrice(donation.amount, 'USD') }}</td>
                                                    <td><span class="frankRuhl-font">{{
                                                            $filters.convertToHebrewDate(donation.donationDate)
                                                            }}</span> </td>
                                                    <td class="d-none d-lg-block">-</td>
                                                    <!-- <td><i v-on:click="selectDontationToView(donation)"
                                                            class="pi pi-eye text-secondary"></i></td> -->
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <br>
                        </div>
                    </div>
                    <br>
                    <br>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { ContentLoader } from 'vue-content-loader';

import sidenav from '@/components/Sidenav.vue'
import navbar from '@/components/Navbar.vue'
import reportsNavbar from '@/components/Reports_Navbar.vue'
import donationReportFilesModal from '@/components/modals/Report/Donation_Report_Files_Modal.vue'
import memberDetailsModal from '@/components/modals/Report/Member_Detail_Modal.vue'
import { GENERATE_DONATION_REPORT_FILE, GET_DONATION_REPORT } from '@/store/action_types'
import { DONATION_REPORT_STATS_ROUTE } from '@/router/routes'
import { STORAGE_URL } from '@/utils/const';

export default {
    title: 'Details',
    components: {
        sidenav,
        navbar,
        reportsNavbar,
        donationReportFilesModal,
        memberDetailsModal,
        ContentLoader
    },
    data() {
        return {
            DONATION_REPORT_STATS_ROUTE,
            showDonationReportFilesModal: false,
            showMemberDetailsModal: false,
            selectedMember: null
        }
    },
    computed: {
        route() {
            return this.$route.name
        },
        donationReportIsLoading() {
            return this.$store.getters.donationReport.isLoading
        },
        donationReport() {
            return this.$store.getters.donationReport.data
        },
        donationReportFileIsLoading() {
            return this.$store.getters.donationReportFile.isLoading
        },
        donationReportFile() {
            return this.$store.getters.donationReportFile.data
        },
        account() {
            return this.$store.getters.account.data
        },
    },
    methods: {
        isOdd(number) {
            return number % 2 !== 0;
        },
        showGeneratedFiles() {
            this.showDonationReportFilesModal = true
        },
        showMemberDetail(member) {
            this.selectedMember = member
            this.showMemberDetailsModal = true
        },
        generateDonationReportFile() {
            this.$store.dispatch(GENERATE_DONATION_REPORT_FILE, { org: this.account.org }).then((result) => {
                if (result) {
                    window.open(`${STORAGE_URL}/${result.file}`, '_blank');
                }
            })
        },
        getDonationReport() {
            this.$store.dispatch(GET_DONATION_REPORT, { org: this.account.org })
        }
    },
    mounted() {
        this.getDonationReport()
    }
}
</script>
<style scoped>
.btn-view-stats {
    font-weight: 600;
    color: #5D84C2;
    float: right;
    margin-top: 8px;
}

.content .donations .card,
.content .donations .card-body {
    padding: 0px;
}

.content .table i {
    font-size: 12px;
    margin-left: 3px;
}

.content .donations {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: #F4ECE2;
}

.content .timer {
    font-size: 18px;
    font-weight: 400 !important;
    color: #606060;
    padding-bottom: 10px;
}

.content .welcome {
    font-size: 26px;
}

.content .timer span {
    margin-left: 7px;
    margin-right: 7px;
}

.content .org-dropdown {
    position: relative;
    top: 23px;
    float: right;
}

.content .org-dropdown .btn {
    border-radius: 8px;
    font-size: 14px;
    color: #6084C2;
    border-radius: 8px;
    border: 1px solid transparent;
    background-color: #D9E2F1;
}

.content .org-dropdown .dropdown-menu {
    border-radius: 8px;
    border: 1px solid #E0E2E7;
}

.content .org-dropdown .dropdown-item {
    font-size: 14px;
    cursor: pointer;
}

.content .org-dropdown .dropdown-item:hover {
    background-color: #F5F5FB;
}

.content .org-dropdown .dropdown-item:active {
    color: #212529;
    background-color: #edf4ff;
}

.btn-view-generated-files {
    font-weight: 600;
    color: #5D84C2;
    float: right;
    margin-top: 8px;
    background-color: transparent!important;
    position: relative;
    bottom: 7px;
}

.btn-print {
    margin-bottom: 10px;
}

.pi-print {
    margin-right: 4px;
}

.stats-col {
    padding-left: 0px;
    padding-right: 0px;
}

.stats-card-1,
.stats-card-1 .card-body,
.stats-card-2,
.stats-card-2 .card-body {
    padding: 0px;
    background-color: transparent;
}

.stats-card-1 {
    background-image: url('../../../assets/images/dashboard-img-1.png');
    background-repeat: no-repeat;
    background-position: top;
    background-size: contain;
    height: 50%;
}

.stats-card-2 {
    background-image: url('../../../assets/images/dashboard-img-2.png');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
    height: 50%;
}

.stats-card-1 .card-body .stats-content .title,
.stats-card-2 .card-body .stats-content .title {
    color: #D7E0F0;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.6px;
    margin-bottom: 11px;
}

.stats-card-1 .card-body .stats-content .value,
.stats-card-2 .card-body .stats-content .value {
    color: #FFFFFF;
    font-size: 28px;
    font-weight: 500;
    letter-spacing: 0.6px;
}

.card .card-body hr {
    border: 1px solid transparent;
}

.table thead th {
    padding: 18px 30px 18px 30px;
}

table thead {
    background-color: #F9F9F9 !important;
    border-bottom: 1px solid #F0F1F3 !important;
}

.table tr td {}

.table tr td {
    padding: 15px 30px 15px 30px !important;
    border-bottom: 1px solid #F0F1F3 !important;
}

.table-title {
    padding: 8px 30px 8px 15px;
}

.table-card .card-body {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 15px;
}

.table-card .btn {
    float: right;
    font-size: 14px;
    font-weight: 600;
    color: #6084C2;
    border-radius: 8px;
    background-color: #D9E2F1;
    margin-right: 12px;
}

.table-card .badge-active {
    font-size: 14px !important;
}

.content .donations .card table .striped {
    background-color: #FAF7F4 !important;
}

.content .donations .card table tbody tr:hover {
    background-color: #FAF7F4 !important;
}

.content .card .card-body small {
    font-size: 17px;
}

.content .card table .pi-eye {
    font-size: 20px;
}

.content .card table .pi-eye {
    cursor: pointer;
}

.chart-sub-title {
    color: #777980;
}

.custom-col-width-2 {
    width: 16.66%;
}

.custom-col-width-10 {
    width: 83.3%;
}

.d-none,
.d-lg-block {
    border-top: 0px solid transparent !important;
    border-bottom: 0px solid transparent !important;
}

@media (max-width: 1000px) {
    .custom-col-width-2 {
        width: 12%;
    }

    .custom-col-width-10 {
        width: 88%;
    }
}

@media (max-width: 1500px) {
    .stats-card-1 .card-body .stats-content {
        position: absolute;
        bottom: 30px;
        left: 28px;
    }

    .stats-card-2 .card-body .stats-content {
        position: absolute;
        bottom: 26px;
        left: 28px;
    }
}

@media (min-width: 1500px) {
    .stats-card-1 {
        height: 49%;
        margin-bottom: 10px;
    }

    .stats-card-2 {
        height: 49%;
    }

    .stats-card-1 .card-body .stats-content {
        position: absolute;
        bottom: 35px;
        left: 38px;
    }

    .stats-card-2 .card-body .stats-content {
        position: absolute;
        bottom: 31px;
        left: 38px;
    }

    .stats-card-1 .card-body .stats-content .title,
    .stats-card-2 .card-body .stats-content .title {
        font-size: 18px;
    }

    .stats-card-1 .card-body .stats-content .value,
    .stats-card-2 .card-body .stats-content .value {
        font-size: 32px;
    }

    .stats-card-1 {}

    .stats-col {
        padding-left: 10px;
        padding-right: 10px;
    }
}

/* typically considered medium screens */
@media (max-width: 1199px) and (min-width: 768px) {
    .mid-screen-stats {
        margin-bottom: 0px;
    }

    .stats-card-1 {
        background-image: url('../../../assets/images/dashboard-img-1.png');
        background-repeat: no-repeat;
        background-position: top;
        background-size: contain;
        height: 250px;
    }

    .stats-card-2 {
        background-image: url('../../../assets/images/dashboard-img-2.png');
        background-repeat: no-repeat;
        background-position: top;
        background-size: contain;
        height: 250px;
    }

    .stats-card-1 .card-body .stats-content {
        position: absolute;
        bottom: 50px;
        left: 30px;
    }

    .stats-card-2 .card-body .stats-content {
        position: absolute;
        bottom: 50px;
        left: 30px;
    }

    .stats-card-1 .card-body .stats-content .title,
    .stats-card-2 .card-body .stats-content .title {
        font-size: 18px;
    }

    .stats-card-1 .card-body .stats-content .value,
    .stats-card-2 .card-body .stats-content .value {
        font-size: 32px;
    }
}
</style>